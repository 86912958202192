<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <div class="content-box">
      <div class="name-box">
        <div class="name">
          <p>{{serveReportInfo.companyName}}</p>
          <p>专项法律服务报告</p>
          <span>Special legal service report</span>
        </div>
        <div class="name2">
          <div class="right">
            <div class="row">
              <span>报告人：</span>
              <span class="green-span">{{ serveReport.name }}</span>
            </div>
            <div class="row">
              <span>服务日期：</span>
              <input v-if="myInfo['服务日期'].status" :id="`ipt${myInfo['服务日期'].id}`" v-model="myInfo['服务日期'].val" @blur="inputonblur(myInfo['服务日期'])" />
              <span class="green-span" v-else @click="clickText(myInfo['服务日期'])" >{{ myInfo['服务日期'].val }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-box">
      <div class="left">
        <img v-if="serveReportInfo._info.team.lawOfficeLogo" :src="`https://file-public.ilaas.cn/${serveReportInfo._info.team.lawOfficeLogo}`" alt="">
        <p v-else>{{serveReportInfo._info.team.lawOfficeName}}</p>
      </div>
      <div class="right">
        <p>© 版权声明</p>
        本报告样式由法法智办（上海）科技有限公司提供，报告内容属于{{serveReport.name}}（个人）和法法智办（上海）科技有限公司所有。未经{{serveReport.name}}（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
import defaultBgImg from "@/assets/serve-report/pages/page-16.png";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
   components: {
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      myInfo: {
        '服务日期':{id:''},
      },
      myArr: [], // 客户数据
      serveReport: {}, // 律师通用数据
      reportDate: this.$getDate(6),
      yearVal: new Date().getFullYear(),
      dateStatus: false, // 日期输入框状态
      createDate: this.$getDate(6),
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    // 点击日期
    clickText(info) {
      info.status = true;
      this.$nextTick(() => {
        document.getElementById(`ipt${info.id}`).focus();
      })
    },
    // 日期输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.serveReport = JSON.parse(JSON.stringify(this.serveReportInfo));
      // console.log(this.serveReport);return
        let id = 1;
        this.myInfo['服务日期'] = {
          id: id++,
          val: this.serveReport._info.createTime.substring(0, 10),
          status: false,
        }
        // console.log(this.myInfo);
    });
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  padding: 0;
  padding-bottom: 80px;
  .content-box {
    width: 100%;
    margin-top: 320px;
  .name-box {
    text-align: center;
    .name {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-size: 48px;
        font-weight: bold;
        color: #131415;
      }
      span {
        font-size: 24px;
        font-weight: bold;
        color: #F7F8FA;
      }
    }
    .name2 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 100px;
      .right {
        .row {
          display: flex;
          align-items: center;
          justify-content: center;
          input {
            font-size: 24px;
            font-weight: bold;
            color: #ccc;
          }
          span {
            font-size: 24px;
            font-weight: bold;
            color: #F7F8FA;
          }
        }
      }
    }
  }
  }
  .foot-box {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 80px;
    box-sizing: border-box;
    .left {
      display: flex;
      align-items: center;
      margin-bottom: 64px;
      img {
        height: 100px;
      }
    }
    .right {
      width: 100%;
      p {
        font-size: 12px;
        color: #F7F8FA;
      }
      .green-span {
        display: block;
        min-height: 34px;
        min-width: 100px;
      }
    }
  }
}
</style>