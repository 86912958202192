// 引入request
import requests from '../../Utils/request'

// 个人服务报告客户统计数据
export const getPersonalClient = () => requests({
  url: '/api/servicereport/personal/customer',
  method: 'get',
})
// 个人服务报告系统统计
export const getPersonalStatistics = () => requests({
  url: '/api/servicereport/personal/statistics',
  method: 'get',
})
// 个人服务报告代理报告
export const getAgent = () => requests({
  url: '/api/servicereport/personal/agentcase',
  method: 'get',
})
// 个人服务报告专项服务
export const getSpecialService = () => requests({
  url: '/api/servicereport/personal/SpecialService',
  method: 'get',
})
// 个人服务报告常年法律顾问
export const getYearAdvisor = () => requests({
  url: '/api/servicereport/personal/PerennialLegalAdvisor',
  method: 'get',
})
// 导出客户列表
export const exportClient = () =>
  requests({
    url: `/api/servicereport/personal/customer/export`,
    method: "get",
    responseType: 'blob',
  });
// 导出案件代理列表
export const exportAgent = () =>
  requests({
    url: `/api/servicereport/personal/agentcase/export`,
    method: "get",
    responseType: 'blob',
  });
// 导出专项案件列表
export const exportAdept = () =>
  requests({
    url: `/api/servicereport/personal/specialservice/export`,
    method: "get",
    responseType: 'blob',
  });
// 上传客户列表
export const upClient = (data) =>
  requests({
    url: "/api/servicereport/personal/customer/import",
    method: "post",
    data,
  });
// 上传案件代理列表
export const upAgent = (data) =>
  requests({
    url: "/api/servicereport/personal/agentcase/import",
    method: "post",
    data,
  });
// 上传专项案件列表
export const upAdept = (data) =>
  requests({
    url: "/api/servicereport/personal/specialservice/import",
    method: "post",
    data,
  });
// 保存生成报告
export const generateReport = (data) =>
  requests({
    url: "/api/servicereport/record/generate",
    method: "post",
    data,
  }); 
// 获取服务报告列表
export const getServicereport = (params) => requests({
  url: `/api/servicereport/record/paging/${params}`,
  method: 'get',
})
// 删除服务报告
export const delServicereport = (data) => requests({
  url: '/api/servicereport/record',
  method: 'delete',
  data,
})

// 获取代理案件任务列表
export const getAgency = (id) => requests({
  url: `/api/assignment/servicereport/list/${id}`,
  method: 'get',
})
// 获取代理案件任务详情
export const getAgencyDetail = (id) => requests({
  url: `/api/servicereport/agentcase/assignment/detail/${id}`,
  method: 'get',
})
// 获取年度服务报告客户列表
export const getYearCustomer = () => requests({
  url: `/api/servicereport/PerennialLegalAdvisor/customer/list`,
  method: 'get',
})
// 获取年度服务报告法律服务套餐
export const getYearCustomerItems = (id) => requests({
  url: `/api/servicereport/PerennialLegalAdvisor/customer/PerennialLegalAdvisorPackage/list/${id}`,
  method: 'get',
})
// 获取年度服务报告服务列表
export const getYearCustomerTask = (id) => requests({
  url: `/api/servicereport/PerennialLegalAdvisor/assignment/list/${id}`,
  method: 'get',
})
// 获取专项法律服务任务列表
export const getSpecialized = (id) => requests({
  url: `/api/assignment/list?businessType=${id}`,
  method: 'get',
})
