<template>
  <div
    class="content row-flex-start"
    style="width: 565px; height: 400px; ovorflow-x: auto"
  >
    <div class="left_map" id="left_map"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import china from "echarts/map/json/china.json";
import axios from "axios";
echarts.registerMap("china", china);
var provincesText = [
  "上海",
  "河北",
  "山西",
  "内蒙古",
  "辽宁",
  "吉林",
  "黑龙江",
  "江苏",
  "浙江",
  "安徽",
  "福建",
  "江西",
  "山东",
  "河南",
  "湖北",
  "湖南",
  "广东",
  "广西",
  "海南",
  "四川",
  "贵州",
  "云南",
  "西藏",
  "陕西",
  "甘肃",
  "青海",
  "宁夏",
  "新疆",
  "北京",
  "天津",
  "重庆",
  "香港",
  "澳门",
  "台湾",
];
import { mapGetters } from "vuex";
export default {
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  data() {
    return {
      map: {},
      initMapStatus: false,
      mapInfo: {},
    };
  },
  props: {},
  methods: {
    getMapOpt(place) {
      this.mapInfo = JSON.parse(
        JSON.stringify(this.serveReportInfo._info)
      )["服务客户"].provinceWithCount;
      let companyData = [];
      for (let key in this.mapInfo) {
        let _findIndex = provincesText.findIndex((item, index) => {
          return key.includes(item);
        });
        let newName = key;
        if (_findIndex !== -1) {
          newName = provincesText[_findIndex];
        }
        companyData.push({
          name: newName,
          value: this.mapInfo[key],
        });
      }
      let option = {
        backgroundColor: "#fff",
        grid: {
          x: 150,
          y: 0,
          x2: 0,
          y2: 10,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}<br/>{c} (数量/位)",
        },
        toolbox: {},
        visualMap: {
          min: 0,
          max: 100,
          text: ["客户数量"],
          realtime: false,
          calculable: true,
          inRange: {
            color: ["#dbf0ff", "#75c7fd", "#0097fa"],
          },
        },
        series: [
          {
            name: "客户分布",
            type: "map",
            map: "china",
            label: {},
            data: companyData,
            itemStyle: {
              normal: {
                borderWidth: 1, //边际线大小
                borderColor: "#def1fe", //边界线颜色
                areaColor: "#fff", //默认区域颜色
              },
              emphasis: {
                show: true,
                areaColor: "#3066ba", //鼠标滑过区域颜色
                label: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
          },
        ],
        geo: {
          map: place ? place : "china",
          show: true,
          roam: false,
          label: {
            emphasis: {
              show: false,
            },
          },
        },
      };
      return option;
    },
    initMap() {
      var dom = document.getElementById("left_map");
      this.map = echarts.init(dom);
      let option = this.getMapOpt();
      if (option && typeof option === "object") {
        this.map.setOption(option, true);
      }
    },
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style>
.left_map {
  width: 565px;
  height: 400px;
}
</style>