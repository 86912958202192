<template>
  <div class="page-wrapper">
    <myHeader page="06"></myHeader>
    <div class="content-box">
      <p class="title-p">
        7. 高频法条
      </p>
      <el-row class="head-row">
        <el-col v-for="(item,index) in headRow" :key="index" :span="item.span">
          <p>{{item.name}}</p>
        </el-col>
      </el-row>
      <div class="content-row-box" @mouseover="mouseover" @mouseleave="mouseleave">
        <el-row v-for="(item2,index2) in myArr" :key="index2" type="flex">
          <el-col :span="4">{{index2+1}}</el-col>
          <el-col :span="8">
            <el-input
              v-if="item2['法规名称'].status"
               :ref="`ipt${item2['法规名称'].id}`"
              @blur="inputonblur2(item2,'法规名称',index2)"
              class="textarea-box"
              :id="`ipt${item2['法规名称'].id}`"
              type="textarea"
              placeholder=""
              :autosize="{ minRows: 1 }"
              suffix-icon="el-icon-date"
              v-model="item2['法规名称'].val"
            >
            </el-input>
            <span v-else @click="clickSpan2(item2,'法规名称',index2)" style="text-indent: 0 !important; color: #333333;">{{item2['法规名称'].val}}</span>
          </el-col>
          <el-col :span="8">
            <el-input
              v-if="item2['条目数'].status"
               :ref="`ipt${item2['条目数'].id}`"
              @blur="inputonblur2(item2,'条目数',index2)"
              class="textarea-box"
              :id="`ipt${item2['条目数'].id}`"
              type="textarea"
              placeholder=""
              :autosize="{ minRows: 1 }"
              suffix-icon="el-icon-date"
              v-model="item2['条目数'].val"
            >
            </el-input>
            <span v-else @click="clickSpan2(item2,'条目数',index2)" style="text-indent: 0 !important; color: #333333;">{{item2['条目数'].val}}</span>
          </el-col>
          <el-col :span="4">
            <div class="del-box" @mouseover="mouseover2(item2)" @mouseleave="mouseleave2(item2)">
            <el-input
              v-if="item2['引用频次'].status"
               :ref="`ipt${item2['引用频次'].id}`"
              @blur="inputonblur2(item2,'引用频次',index2)"
              class="textarea-box"
              :id="`ipt${item2['引用频次'].id}`"
              type="textarea"
              placeholder=""
              :autosize="{ minRows: 1 }"
              suffix-icon="el-icon-date"
              v-model="item2['引用频次'].val"
            >
            </el-input>
            <span v-else @click="clickSpan2(item2,'引用频次',index2)" style="text-indent: 0 !important; color: #333333;">{{item2['引用频次'].val}}</span>
            <i class="el-icon-delete" @click="delRow(item2)" v-if="delId == item2['法规名称'].id"></i>
            </div>
          </el-col>
        </el-row>
        <div v-show="showAdd" class="add-btn">
          <el-button @click="add">新 增</el-button>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      headRow: [
        {name: "序号", span: 4},
        {name: "法规名称", span: 8},
        {name: "条目数", span: 8},
        {name: "引用频次", span: 4},
      ],
      myArr: [
        {
          '法规名称': {
            id: 1,
            val: '',
            status: false,
          },
          '条目数': {
            id: 2,
            val: '',
            status: false,
          },
          '引用频次': {
            id: 3,
            val: '',
            status: false,
          },
        },
      ],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
      showAdd: false, // 新增显示
      maxId: 6, // 最大id
      delId: 0, // 删除的id
    };
  },
  methods: {
    // 鼠标移入显示新增按钮
    mouseover() {
      this.showAdd = true;
    },
    mouseleave() {
      this.showAdd = false;
    },
    // 新增
    add() {
      let info = {
        '法规名称': {
          id: this.maxId++,
          val: '',
          status: false,
        },
          '条目数': {
            id: this.maxId++,
            val: '',
            status: false,
          },
          '引用频次': {
            id: this.maxId++,
            val: '',
            status: false,
          },
      }
      this.myArr.push(info);
    },
    // 鼠标移入行
    mouseover2(row) {
      this.delId = row['法规名称'].id;
    },
    mouseleave2() {
      this.delId = 0;
    },
    // 删除行
    delRow(row) {
      let idx = 0;
      for (let i = 0; i < this.myArr.length; i++) {
        let item = this.myArr[i];
        if (item['法规名称'].id === row['法规名称'].id) {
          idx = i;
          break
        }
      }
      this.myArr.splice(idx,1);
    },
    // 输入框失焦
    inputonblur2(row,str,idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row)
    },
    // 
    clickSpan2(row,str,idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row)
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  .content-box {
    margin-top: 100px;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #195AAB;
      margin-bottom: 10px !important;
    }
  }
  
    .head-row {
      background: #EEEEEE;
      .el-col {
        p {
          margin: 16px !important;
        }
      }
    }
      .content-row-box {
        .el-row {
          background: #EBF4FF;
          ::v-deep .el-col {
            display: flex;
            align-items: center;
            color: #333;
            padding: 16px;
            box-sizing: border-box;
            span {
              display: block;
              min-width: 100px;
              min-height: 32px;
              width: 100%;
              color: #333 !important;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              word-break: break-all;
            }
            .textarea-box {
              .el-textarea__inner {
                background-color: transparent;
              }
            }
          }
        }
        & .el-row:nth-child(odd) {
          background: #fff;
        }
        
    .del-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      ::v-deep .textarea-box {
        .el-textarea__inner {
          width: 90%;
          padding: 0;
        }
      }
      i {
        font-size: 28px;
        color: red;
        cursor:pointer;
      }
    }
    .add-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
      .el-button {
        width: 120px;
        height: 60px;
        font-size: 24px;
        color: #7cbbfc;
        border-radius: 30px;
        border: 2px solid #7cbbfc;
      }
    }
      }
}
</style>