<template>
  <div class="page-wrapper">
    <myHeader :title="`${page}.案件代理报告`"></myHeader>
    <div class="row-echarts-box">
      <div class="left-echarts">
        <p class="title">仲裁裁判结果</p>
        <div class="myCharts7" style="width: 515px; height: 600px"></div>
      </div>
      <div class="right-echarts">
        <p class="title">一审裁判结果</p>
        <div class="myCharts8" style="width: 515px; height: 600px"></div>
      </div>
    </div>
    <div class="row-echarts-box">
      <div class="left-echarts" style="background: #F7F8FA;">
        <p class="title">二审裁判结果</p>
        <div class="myCharts9" style="width: 515px; height: 600px"></div>
      </div>
      <div class="right-echarts" style="background: #fff;">
        <p class="title">再审裁判结果</p>
        <div class="myCharts10" style="width: 515px; height: 600px"></div>
      </div>
    </div>
    <myFooter pageNum="07"></myFooter>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
// 引入echarts
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    myFooter,
    VueDragResize,
  },
  data() {
    return {
      page: 0,
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    initEchart() {
      console.log(this.serveReportInfo._info);
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))["代理案件"].mediationResultCount;
      console.log(data,'---------------');
      let echartData = [];
      // for (let key in data) {
      //   echartData.push({
      //     value: data[key],
      //     name: this.$getCaseResultList(key),
      //   });
      // }
      let newObj = {};
      for (let key in data) {
        newObj[`${this.$getCaseResultList(key)}`] = data[key]
      }
      for (let k in newObj) {
        echartData.push({
          value: newObj[k],
          name: k,
        });
      }
      console.log(echartData);
      let myCharts = echarts.init(document.querySelector(".myCharts7"));
      let options = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        tooltip: {
          show: false,
          trigger: "item",
        },
        legend: {
          orient: 'vertical',
          x: "center",
          y: "bottom",
          padding: [0,0,0,90],
          textStyle: {
            fontSize: 20,
          },
          formatter: (val) => {
            return `${val}（${newObj[val]}件）`
          }
        },
        series: [
          {
            type: "pie",
            radius: ["35%", "45%"],
            data: echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    fontSize: 22,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 40,
                length2: 60,
                lineStyle: {
                  // color: '#333'
                },
              },
            },
            label: {
              normal: {
                formatter: " {b}：{c} \n\n",
                borderWidth: 10,
                borderRadius: 4,
                padding: [0, -70],
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 12,
                    lineHeight: 20,
                  },
                  b: {
                    fontSize: 12,
                    lineHeight: 20,
                    color: "#333",
                  },
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    initEchart2() {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))["代理案件"].firstInstanceResultCount;
      let echartData = [];
      // for (let key in data) {
      //   echartData.push({
      //     value: data[key],
      //     name: this.$getCaseResultList(key),
      //   });
      // }
      let newObj = {};
      for (let key in data) {
        newObj[`${this.$getCaseResultList(key)}`] = data[key]
      }
      for (let k in newObj) {
        echartData.push({
          value: newObj[k],
          name: k,
        });
      }
      let myCharts = echarts.init(document.querySelector(".myCharts8"));
      let options = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        tooltip: {
          show: false,
          trigger: "item",
        },
        legend: {
          orient: 'vertical',
          x: "center",
          y: "bottom",
          padding: [0,0,0,90],
          textStyle: {
            fontSize: 20,
          },
          formatter: (val) => {
            return `${val}（${newObj[val]}件）`
          }
        },
        series: [
          {
            type: "pie",
            radius: ["35%", "45%"],
            data: echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    fontSize: 22,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 40,
                length2: 60,
                lineStyle: {
                  // color: '#333'
                },
              },
            },
            label: {
              normal: {
                formatter: " {b}：{c} \n\n",
                borderWidth: 10,
                borderRadius: 4,
                padding: [0, -70],
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 12,
                    lineHeight: 20,
                  },
                  b: {
                    fontSize: 12,
                    lineHeight: 20,
                    color: "#333",
                  },
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    initEchart3() {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))["代理案件"].secondTrialResultCount;
      let echartData = [];
      let newObj = {};
      for (let key in data) {
        newObj[`${this.$getCaseResultList(key)}`] = data[key]
      }
      for (let k in newObj) {
        echartData.push({
          value: newObj[k],
          name: k,
        });
      }
      console.log(echartData);
      let myCharts = echarts.init(document.querySelector(".myCharts9"));
      let options = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        tooltip: {
          show: false,
          trigger: "item",
        },
        legend: {
          orient: 'vertical',
          x: "center",
          y: "bottom",
          padding: [0,0,0,90],
          textStyle: {
            fontSize: 20,
          },
          formatter: (val) => {
            return `${val}（${newObj[val]}件）`
          }
        },
        series: [
          {
            type: "pie",
            radius: ["35%", "45%"],
            data: echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    fontSize: 22,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 40,
                length2: 60,
                lineStyle: {
                  // color: '#333'
                },
              },
            },
            label: {
              normal: {
                formatter: " {b}：{c} \n\n",
                borderWidth: 10,
                borderRadius: 4,
                padding: [0, -70],
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 12,
                    lineHeight: 20,
                  },
                  b: {
                    fontSize: 12,
                    lineHeight: 20,
                    color: "#333",
                  },
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    initEchart4() {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))["代理案件"].retrialResultCount;
      let echartData = [];
      let newObj = {};
      for (let key in data) {
        newObj[`${this.$getCaseResultList(key)}`] = data[key]
      }
      for (let k in newObj) {
        echartData.push({
          value: data[k],
          name: k,
        });
      }
      // for (let key in data) {
      //   echartData.push({
      //     value: data[key],
      //     name: this.$getCaseResultList(key),
      //   });
      // }
      let myCharts = echarts.init(document.querySelector(".myCharts10"));
      let options = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        tooltip: {
          show: false,
          trigger: "item",
        },
        legend: {
          orient: 'vertical',
          x: "center",
          y: "bottom",
          padding: [0,0,0,90],
          textStyle: {
            fontSize: 20,
          },
          formatter: (val) => {
            return `${val}（${newObj[val]}件）`
          }
        },
        series: [
          {
            type: "pie",
            radius: ["35%", "45%"],
            data: echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    fontSize: 22,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 40,
                length2: 60,
                lineStyle: {
                  // color: '#333'
                },
              },
            },
            label: {
              normal: {
                formatter: " {b}：{c} \n\n",
                borderWidth: 10,
                borderRadius: 4,
                padding: [0, -70],
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 12,
                    lineHeight: 20,
                  },
                  b: {
                    fontSize: 12,
                    lineHeight: 20,
                    color: "#333",
                  },
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url,idx);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    // console.log(this.serveReportInfo,'this.serveReportInfo');return
    this.initEchart();
    this.initEchart2();
    this.initEchart3();
    this.initEchart4();
    this.page = this.serveReportInfo._info['目录']['代理案件'].page;
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  padding: 0;
  padding-bottom: 80px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .row-echarts-box {
    display: flex;
    // padding: 20px 80px;
    // box-sizing: border-box;
    .left-echarts {
      width: 50%;
      padding: 20px 40px;
      box-sizing: border-box;
    }
    .right-echarts {
      width: 50%;
      padding: 20px 40px;
      box-sizing: border-box;
      background: #F7F8FA;
      // border-left: 5px solid #F7F8FA;
    }
  }
}
</style>