<template>
  <div class="page-wrapper">
    <myHeader :title="`${page}.专项服务`"></myHeader>
    <div class="content-box">
      <p class="title">{{page}}.1 专项服务</p>
      <div class="form-box">
        <el-row class="head-row" type="flex">
          <el-col
            v-for="(item, index) in headRow"
            :key="index"
            :span="item.span"
          >
            <p>{{ item.name }}</p>
          </el-col>
        </el-row>
        <div class="content-row-box">
          <el-row v-for="(item2, index2) in myArr" :key="index2">
            <el-col :span="4">
              <el-input
                v-if="item2['客户名称'].status"
                 :ref="`ipt${item2['客户名称'].id}`"
                @blur="inputonblur(item2,'客户名称',index2)"
                class="textarea-box"
                :id="`ipt${item2['客户名称'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['客户名称'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'客户名称',index2)">{{item2['客户名称'].val}}</span>
            </el-col>
            <el-col :span="4">
              <el-input
                v-if="item2['客户性质'].status"
                 :ref="`ipt${item2['客户性质'].id}`"
                @blur="inputonblur(item2,'客户性质',index2)"
                class="textarea-box"
                :id="`ipt${item2['客户性质'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['客户性质'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'客户性质',index2)">{{item2['客户性质'].val}}</span>
            </el-col>
            <el-col :span="6">
              <el-input
                v-if="item2['客户所在地区'].status"
                 :ref="`ipt${item2['客户所在地区'].id}`"
                @blur="inputonblur(item2,'客户所在地区',index2)"
                class="textarea-box"
                :id="`ipt${item2['客户所在地区'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['客户所在地区'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'客户所在地区',index2)">{{item2['客户所在地区'].val}}</span>
            </el-col>
            <el-col :span="5">
              <el-input
                v-if="item2['专项事由'].status"
                 :ref="`ipt${item2['专项事由'].id}`"
                @blur="inputonblur(item2,'专项事由',index2)"
                class="textarea-box"
                :id="`ipt${item2['专项事由'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['专项事由'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'专项事由',index2)">{{item2['专项事由'].val}}</span>
            </el-col>
            <el-col :span="5">
              <el-input
                v-if="item2['专项结果'].status"
                 :ref="`ipt${item2['专项结果'].id}`"
                @blur="inputonblur(item2,'专项结果',index2)"
                class="textarea-box"
                :id="`ipt${item2['专项结果'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['专项结果'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'专项结果',index2)">{{item2['专项结果'].val}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="echarts-box">
      <p class="title">{{page}}.2 专项服务事件 TOP10</p>
      <div class="myCharts12" style="width: 1030px; height: 600px"></div>
    </div>
    <myFooter pageNum="10"></myFooter>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
// 引入echarts
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    myFooter,
    VueDragResize,
  },
  data() {
    return {
      page: 0,
      headRow: [
        { name: "客户名称", span: 4 },
        { name: "客户性质", span: 4 },
        { name: "客户所在地区", span: 6 },
        { name: "专项事由", span: 5 },
        { name: "专项结果", span: 5 },
      ],
      myArr: [], // 客户数据
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      let list = JSON.parse(JSON.stringify(this.serveReportInfo._info))['专项服务'].specialList
      list.forEach((item,index) => {
          let i = 0
          for (let k in item) {
            item['客户名称'] = {
              id: `${index}${i++}`,
              val: item.name || '客户名称',
              status: false,
            }
            item['客户性质'] = {
              id: `${index}${i++}`,
              val: item.nature || '客户性质',
              status: false,
            }
            item['客户所在地区'] = {
              id: `${index}${i++}`,
              val: item.area || '客户所在地区',
              status: false,
            }
            item['专项事由'] = {
              id: `${index}${i++}`,
              val: item.content || '专项事由',
              status: false,
            }
            item['专项结果'] = {
              id: `${index}${i++}`,
              val: item.result || '专项结果',
              status: false,
            }
          }
        })
        this.myArr = list;
        console.log(this.myArr);
    },
    initEchart() {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))[
        "专项服务"
      ].typeCount;
      let dataY = [];
      let dataX = [];
      // console.log(data, "专项服务");
      for (let key in data) {
        dataY.push(data[key]);
        dataX.push(key);
      }
      // return;
      let myCharts = echarts.init(document.querySelector(".myCharts12"));
      let options = {
        grid: {
          x: 50,
          y: 40,
          x2: 0,
          y2: 150,
        },
        xAxis: {
          type: "category",
          data: dataX,
          axisLabel: {
            rotate: 35,
            textStyle: {
              fontSize: 16,
              color: "#9e9e9e",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              fontSize: 16,
              color: "#9e9e9e",
            },
          },
        },
        series: [
          {
            data: dataY,
            type: "bar",
            barWidth: "6%",
            showBackground: true,
            itemStyle: {
              normal: {
                color: "#469cda",
              },
            },
            label: {
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  fontSize: 20,
                  color: "#ccc",
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    // 输入框失焦
    inputonblur(row,str,idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row)
    },
    // 
    clickSpan(row,str,idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row)
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    // console.log(this.serveReportInfo._info,'专项服务');
    this.init();
    this.initEchart();
    this.page = this.serveReportInfo._info['目录']['专项服务'].page;
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  padding: 0;
  padding-bottom: 80px;
  .title {
    font-size: 24px;
    font-weight: bold;
    font-family: HarmonyOS_Sans_SC_Bold;
    color: #333333;
  }
  .content-box {
    padding: 20px 80px;
    box-sizing: border-box;
    margin-bottom: 50px;
    .form-box {
      margin-top: 20px;
      .content-row-box {
        .el-row {
          ::v-deep .el-col {
            .textarea-box {
              .el-textarea__inner {
                background-color: transparent;
              }
            }
            span {
              display: block;
              min-height: 24px;
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .echarts-box {
    padding: 20px 80px;
  }
}
</style>