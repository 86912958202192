<template>
  <div class="page-wrapper">
    <myHeader page="02"></myHeader>
    <div class="content-box">
      <p class="title-p">1.案件基本信息</p>
      <div class="row-box" v-for="(val,key,i) in myInfo" :key="i">
        <span class="name-span">{{val.title}}</span>
        <div class="val-box">
          <!-- {{val.val}} -->
          <el-input
          v-if="val.status"
          :ref="`ipt${val.id}`"
          @blur="inputonblur(val)"
          class="textarea-box"
          :id="`ipt${val.id}`"
          type="textarea"
          placeholder=""
          :autosize="{ minRows: 1 }"
          suffix-icon="el-icon-date"
          v-model="val.val"
        >
        </el-input>
        <span v-else @click="clickSpan(val)">{{val.val}}</span>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
import myHeader from "./components/myHeader";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      myInfo: {},
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo))._info;
      console.log(data,'任务数据');
      let list = data.results;
      this.myInfo = {
        '任务名称': {
          id: 1,
          title: "客户基本信息",
          val: data.name,
          status: false,
        },
        '客户名称': {
          id: 2,
          title: "客户名称",
          val: data.customerName,
          status: false,
        },
        '原告': {
          id: 3,
          title: "原告当事人",
          val: "",
          status: false,
        },
        '被告': {
          id: 4,
          title: "被告当事人",
          val: "",
          status: false,
        },
        '标的额': {
          id: 5,
          title: "标的额",
          val: "",
          status: false,
        },
        '案件程序': {
          id: 6,
          title: "案件程序",
          val: list[list.length -1].name,
          status: false,
        },
        '结案方式': {
          id: 7,
          title: "结案方式",
          val: data.closedWay,
          status: false,
        },
        '立案日期': {
          id: 8,
          title: "立案日期",
          val: data.createTime.slice(0,10),
          status: false,
        },
        '结案日期': {
          id: 9,
          title: "结案日期",
          val: data.finishedTime.slice(0,10),
          status: false,
        },
      }
    },
    // 输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 
    clickSpan(info) {
      info.status = true;
      // console.log(info);return
      this.$nextTick(() => {
        this.$refs[`ipt${info.id}`][0].focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    margin-top: 140px;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #195AAB;
      margin-bottom: 40px !important;
    }
    .row-box {
      display: flex;
      align-items: center;
      background: #EBF4FF;
      padding: 24px 60px;
      box-sizing: border-box;
      span {
        display: block;
        min-height: 20px;
        min-width: 100px;
      }
      .name-span {
        width: 30%;
      }
      .val-box {}
    }
    & .row-box:nth-child(odd) {
      background: #fff;
    }
  }
}
</style>