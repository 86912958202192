<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <myHeader page="02"></myHeader>
    <div class="content-box">
      <p class="title-p">前言</p>
      <el-input
        v-if="introduction.status"
        :ref="`ipt${introduction.id}`"
        @blur="inputonblur(introduction)"
        class="textarea-box"
        :id="`ipt${introduction.id}`"
        type="textarea"
        placeholder=""
        :autosize="{ minRows: 20 }"
        suffix-icon="el-icon-date"
        v-model="introduction.val"
      >
      </el-input>
      <span v-else @click="clickSpan(introduction)">{{
        introduction.val
      }}</span>
      <!-- <div class="row-box" v-for="(val,key,i) in myInfo" :key="i">
        <span class="name-span">{{val.title}}</span>
        <div class="val-box">
          <el-input
          v-if="val.status"
          :ref="`ipt${val.id}`"
          @blur="inputonblur(val)"
          class="textarea-box"
          :id="`ipt${val.id}`"
          type="textarea"
          placeholder=""
          :autosize="{ minRows: 1 }"
          suffix-icon="el-icon-date"
          v-model="val.val"
        >
        </el-input>
        <span v-else @click="clickSpan(val)">{{val.val}}</span>
        </div>
      </div> -->
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
import defaultBgImg from "@/assets/serve-report/pages/page-11.png";
import myHeader from "./components/myHeader";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      introduction: {
        id: 1,
        val: "前言输入",
        status: false,
      },
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {},
    // 输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 
    clickSpan(info) {
      info.status = true;
      this.$nextTick(() => {
        let re = `ipt${info.id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    margin-top: 280px;
    .title-p {
      font-size: 56px;
      font-weight: 600;
      color: #196A73;
      margin-bottom: 60px !important;
    }
    span {
      display: block;
      min-height: 500px;
      font-size: 24px;
      color: #111111;
      text-indent: 2em !important;
    }
    ::v-deep .textarea-box {
      .el-textarea__inner {
        font-size: 24px;
        color: #111111;
      }
    }
  }
}
</style>