<template>
  <div class="info-page1">
    <!-- <p>专项任务：</p> -->
    <el-row class="head-row">
      <el-col
        :span="item.span"
        v-for="(item, index) in headRowList"
        :key="index"
        >{{ item.name }}</el-col
      >
    </el-row>
    <el-row class="row-box" v-for="(item2, index2) in myList" :key="index2"  type="flex">
      <el-col :span="8">
        <p>{{item2.name}}</p>
      </el-col>
      <el-col :span="6">
        <p>{{item2.customerName}}</p>
      </el-col>
      <el-col :span="6">
        <p>{{item2.createTime.slice(0,10),}}</p>
      </el-col>
      <el-col :span="4">
        <div class="dot-box" @click="selectId(item2)">
          <div :class="`dot ${item2.id == isInfo.id ? 'is-dot' : ''}`"></div>
        </div>
      </el-col>
    </el-row>
    <!-- <p>团队成员：</p> -->
  </div>
</template>

<script>
import {
  getAssignmentDetail,
} from "@/FackApi/api/project.js";
import { getSpecialized } from "@/FackApi/api/serveReport.js";
import { getTeam } from "@/FackApi/api/team";
import { Loading } from "element-ui";
export default {
  name: "infoPage1",
  components: {},
  data() {
    return {
      headRowList: [
        { name: "项目任务", span: 8 },
        { name: "客户", span: 6 },
        { name: "开始时间", span: 6 },
        { name: "选择", span: 4 },
      ],
      statisticsInfo: {}, // 统计数据
      myList: [{
        createTime: ''
      }], // 客户数据集
      isInfo: {id: ''}, // 选择的任务
      teamInfo: {id: ''}, // 团队数据
    };
  },
  created() {
    this.getTaskListInfo();
    this.getTeamInfo();
  },
  mounted() {},
  methods: {
    // 获取任务列表
    async getTaskListInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let res = await getSpecialized(23);
      console.log(res,'专项');
      loadingInstance.close();
      if (res.code == 0) {
        this.myList = res.items;
      }
    },
    // 选择任务
    async selectId(row) {
      let res = await getAssignmentDetail(row.id);
      console.log(res);
      if (res.code == 0) {
        let data = res.item;
        data.id = row.id;
        this.isInfo = data;
      }
    },
    // 获取团队成员
    async getTeamInfo() {
      let res = await getTeam();
      if (res.code == 0 && res.item.id) {
        this.teamInfo = res.item;
      }
    },
    // 处理统计数据
    statisticalData() {
      let newTeamInfo = {
        lawOfficeLogo: "",
        lawOfficeName: "",
        name: "",
        profile: "",
        members: [],
      }
      this.isInfo.team = this.teamInfo.id ? this.teamInfo:newTeamInfo;
      return this.isInfo;
    },
  },
};
</script>

<style lang="less" scoped>
.info-page1 {
  // width: 100%;
  .head-row {
    border: 1px solid #d4d4d4;
    border-bottom: 0;
    .el-col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      background: #f5f5f5;
    }
  }
  .row-box {
    border: 1px solid #d4d4d4;
    border-top: 0;
    .el-col {
      min-height: 50px;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      .dot-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .dot {
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background: #ffffff;
          border: 1px solid #c0c4cc;
          margin-right: 10px;
          box-sizing: border-box;
        }
        .is-dot {
          // border: 5px solid #3299d3;
          background: #3299d3;
        }
      }
    }
  }
}
</style>

