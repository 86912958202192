<template>
  <div class="footer">
    <div class="left">
        <span>{{serveReportInfo.name}}律师 | 个人服务报告</span>
      </div>
      <!-- <span>{{pageNum}}</span> -->
  </div>
</template>
<script>
import { string } from "clipboard";
import { mapGetters } from 'vuex'
export default {
  props: {
    pageNum: {
      type: string,
      default: "页码",
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    .left {
      display: flex;
      justify-content: flex-end;
      width: 90%;
      height: 100%;
      background: linear-gradient(to right, #e7feee,#2c95d3);
      border-radius: 0 40px 0 0;
      span {
        font-size: 16px;
        color: #FFFFFF;
        margin: 20px 30px 0 0;
      }
    }
    &>span {
      font-size: 20px;
      font-weight: bold;
      color: #0069A6;
      margin-left: 40px;
    }
}
</style>