<template>
  <div class="select-wrapper">
    <div class="upload">
      <!-- <el-upload
        class="upload-wrapper"
        action=""
        accept=".png, .jpg"
        :on-remove="clientRemove"
        :http-request="uploadClient"
        :limit="1"
        :file-list="clientFileList"
      > -->
      <el-upload
        class="upload-wrapper"
        action=""
        :show-file-list="false"
        :accept="'image/*'"
        :auto-upload="false"
        :on-change="imgSaveToUrl"
      >
        <el-button type="primary">上传素材</el-button>
      </el-upload>
    </div>
    <div class="photo-wrapper">
      <span class="photo-wrapper-title">图片</span>
      <span class="photo-wrapper-num">共{{ serveReportInfo.upImgList.length }}张</span>
      <ul class="photo-wrapper-list">
        <li
          class="photo-wrapper-item"
          v-for="(item, index) in serveReportInfo.upImgList"
          :key="index"
          @click="clickBgimg(item)"
        >
          <el-image style="width: 80%;vertical-align: middle;" :src="item">
            <!--  :preview-src-list="srcList" -->
          </el-image>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      clientFileList: [],
    };
  },
  methods: {
    clickBgimg() {
      console.log("我点击了图片");
    },
    // 上传客户列表
    async uploadClient(param) {
      let formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("file", param.file); //添加文件对象
      // let res = await upClient(formData);
      // if (res.code == 0) {
      //   let data = res.item;
      //   data.customerList.map(item => {
      //     return item.status = true;
      //   })
      //   this.clientInfo = data;
      // }
    },
    clientRemove(file, fileList) {
      this.clientInfo = {};
      this.$message.warning("文件已移除");
    },
    
    imgSaveToUrl(event) {
      console.log(this.serveReportInfo);
      let stoInfo = JSON.parse(JSON.stringify(this.serveReportInfo));
      console.log(stoInfo);
      let newList = stoInfo.upImgList;
      console.log(newList);
      // 获取上传图片的本地URL，用于上传前的本地预览
      var URL = null;
      if (window.createObjectURL != undefined) {
        // basic
        URL = window.createObjectURL(event.raw);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        URL = window.URL.createObjectURL(event.raw);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        URL = window.webkitURL.createObjectURL(event.raw);
      }
      newList.push(URL);
      stoInfo.upImgList = newList;
      this.$store.dispatch("index/setServeReportInfo", stoInfo);
      // 转换后的地址为 blob:http://xxx/7bf54338-74bb-47b9-9a7f-7a7093c716b5
      // this.localUrl = URL;
      // this.isShowImgUpload = true;//呈现本地预览组件
      // this.isShowUpload = false;//隐藏上传组件
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  .upload {
    width: 100%;
    margin-top: 40px;
    &-wrapper {
      width: 100%;
      ::v-deep .el-upload {
        width: 100%;
        .el-button {
          width: 100%;
        }
      }
    }
    span {
      color: #c0c4cc;
      font-size: 12px;
      display: block;
      margin-top: 20px;
    }
  }
  .photo-wrapper {
    margin-top: 40px;
    &-title {
      font-size: 12px;
      display: block;
      padding: 20px 0;
      border-bottom: 1px solid #dee2e6;
    }
    &-num {
      display: block;
      font-size: 12px;
      color: #909399;
      margin-top: 10px;
    }
    &-list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .photo-wrapper-item {
        margin-right: 10px;
        &:nth-child(3n) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>