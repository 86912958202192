<template>
  <div class="page-wrapper">
    <myHeader page="04"></myHeader>
    <div class="content-box">
      <p class="title-p">
        全年咨询服务总结
        <span>/  1. 年度法律顾问服务记录</span>
      </p>
      <div class="form-box">
        <el-row class="head-row" type="flex">
          <el-col v-for="(item,index) in headRow" :key="index" :span="item.span">
            <p>{{item.name}}</p>
          </el-col>
        </el-row>
        <div class="content-row-box" ref="rowBoxs">
          <el-row v-for="(item2,index2) in myArr" :key="index2">
            <el-col :span="8">
              <el-input
                v-if="item2['名称'].status"
                 :ref="`ipt${item2['名称'].id}`"
                @blur="inputonblur(item2,'名称',index2)"
                class="textarea-box"
                :id="`ipt${item2['名称'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['名称'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'名称',index2)">{{item2['名称'].val}}</span>
            </el-col>
            <el-col :span="6">
              <el-input
                v-if="item2['开始时间'].status"
                 :ref="`ipt${item2['开始时间'].id}`"
                @blur="inputonblur(item2,'开始时间',index2)"
                class="textarea-box"
                :id="`ipt${item2['开始时间'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['开始时间'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'开始时间',index2)">{{item2['开始时间'].val}}</span>
              <el-input
                v-if="item2['结束时间'].status"
                 :ref="`ipt${item2['结束时间'].id}`"
                @blur="inputonblur(item2,'结束时间',index2)"
                class="textarea-box"
                :id="`ipt${item2['结束时间'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['结束时间'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'结束时间',index2)">{{item2['结束时间'].val}}</span>
            </el-col>
            <el-col :span="6">
              <el-input
                v-if="item2['服务类型'].status"
                 :ref="`ipt${item2['服务类型'].id}`"
                @blur="inputonblur(item2,'服务类型',index2)"
                class="textarea-box"
                :id="`ipt${item2['服务类型'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['服务类型'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'服务类型',index2)">{{item2['服务类型'].val}}</span>
            </el-col>
            <el-col :span="4">
              <el-input
                v-if="item2['服务时长'].status"
                 :ref="`ipt${item2['服务时长'].id}`"
                @blur="inputonblur(item2,'服务时长',index2)"
                class="textarea-box"
                :id="`ipt${item2['服务时长'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['服务时长'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'服务时长',index2)">{{item2['服务时长'].val}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  props: {
    pageTitle: String,
  },
  data() {
    return {
      headRow: [
        {name: "任务", span: 8},
        {name: "起止时间", span: 6},
        {name: "服务类型", span: 6},
        {name: "服务总时长", span: 4},
      ],
      myArr: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      // let list = JSON.parse(JSON.stringify(this.serveReportInfo))._info['任务'];
      // let id = 0;
      // list.map(item => {
      //   item['名称'] = {
      //     id: id++,
      //     val: item.name,
      //     status: false,
      //   }
      //   item['开始时间'] = {
      //     id: id++,
      //     val: "起 " + item.createTime || '',
      //     status: false,
      //   }
      //   item['结束时间'] = {
      //     id: id++,
      //     val: "止 " + (JSON.stringify(item.finishedTime) == 'null' ? '未结束' : item.finishedTime),
      //     status: false,
      //   }
      //   item['服务类型'] = {
      //     id: id++,
      //     val: item.assignmentName,
      //     status: false,
      //   }
      //   let theTime = item.totalDuration;
      //     let middle = 0;
      //     let hour = 0;
      //     if (theTime > 59) {
      //       middle = parseInt(theTime / 60);
      //       theTime = parseInt(theTime % 60);
      //       if(middle > 59) {
      //         hour = parseInt(middle / 60);
      //         middle = parseInt(middle % 60);
      //       }
      //     }
      //     hour = hour < 10 ? "0"+hour : hour;
      //     middle = middle < 10 ? "0"+middle : middle;
      //     theTime = theTime < 10 ? "0"+theTime : theTime;
      //     item['服务时长'] = {
      //       id: id++,
      //       val: `${hour}:${middle}:${theTime}`,
      //       status: false,
      //     }
      //     return item
      // })
      // this.myArr = list;
      if (sessionStorage.getItem("taskSurplusInfo")) {
        this.myArr = JSON.parse(sessionStorage.getItem("taskSurplusInfo")).list;
      } else if (sessionStorage.getItem("taskPagingInfo") && !sessionStorage.getItem("taskSurplusInfo")) {
        let stoIsTaskPagingInfo = JSON.parse(sessionStorage.getItem("taskPagingInfo")).list;
        let titleIdx = this.pageTitle.split("）")[0];
        titleIdx = titleIdx.split("（");
        titleIdx = titleIdx[titleIdx.length - 1];
        if (titleIdx === "全年咨询服务情况") {
          this.myArr = stoIsTaskPagingInfo[0];
        } 
        else {
          this.myArr = stoIsTaskPagingInfo[Number(titleIdx - 1)];
        }
        return
      } else {
        let list = JSON.parse(JSON.stringify(this.serveReportInfo))._info['任务'];
      let id = 0;
      list.map(item => {
        item['名称'] = {
          id: id++,
          val: item.name,
          status: false,
        }
        item['开始时间'] = {
          id: id++,
          val: "起 " + item.createTime || '',
          status: false,
        }
        item['结束时间'] = {
          id: id++,
          val: "止 " + (JSON.stringify(item.finishedTime) == 'null' ? '未结束' : item.finishedTime),
          status: false,
        }
        item['服务类型'] = {
          id: id++,
          val: item.assignmentName,
          status: false,
        }
        let theTime = item.totalDuration;
          let middle = 0;
          let hour = 0;
          if (theTime > 59) {
            middle = parseInt(theTime / 60);
            theTime = parseInt(theTime % 60);
            if(middle > 59) {
              hour = parseInt(middle / 60);
              middle = parseInt(middle % 60);
            }
          }
          hour = hour < 10 ? "0"+hour : hour;
          middle = middle < 10 ? "0"+middle : middle;
          theTime = theTime < 10 ? "0"+theTime : theTime;
          item['服务时长'] = {
            id: id++,
            val: `${hour}:${middle}:${theTime}`,
            status: false,
          }
          return item
      })
      this.myArr = list;
      }
      let totalHeight = 0; // 最大高度为1030
      let newList = this.myArr.concat([]);
        this.$nextTick(() => {
          let domList = this.$refs['rowBoxs'].children;
          let taskPagingInfo = {
              list: [],
            }
          if (this.pageTitle != "全年咨询服务情况") {
            taskPagingInfo = JSON.parse(sessionStorage.getItem("taskPagingInfo"));
          }
          let _isList = [];
          let _i = '';
          for (let i = 0; i < domList.length; i++) {
            let dom = domList[i];
            totalHeight += dom.offsetHeight;
            if (totalHeight > 1030) {
              _i = i;
              this.myArr.splice(i,this.myArr.length-i+1);
              _isList = this.myArr.concat([]);
              taskPagingInfo.list.push(_isList);
              sessionStorage.setItem("taskPagingInfo",JSON.stringify(taskPagingInfo));
              newList.splice(0,i);
              if (newList.length !== 0) {
                let taskSurplusInfo = {
                  list: newList,
                }
                sessionStorage.setItem("taskSurplusInfo",JSON.stringify(taskSurplusInfo));
                this.$emit("addPage");
              } 
              break
            } 
          }
          if (_i === '') {
            taskPagingInfo.list.push(this.myArr);
            sessionStorage.setItem("taskPagingInfo",JSON.stringify(taskPagingInfo));
            sessionStorage.removeItem("taskSurplusInfo");
            this.$emit("toOnePage")
          }
        })
    },
    // 输入框失焦
    inputonblur(row,str,idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row)
    },
    // 
    clickSpan(row,str,idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row)
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    margin-top: 240px;
    .title-p {
      font-size: 56px;
      font-weight: 600;
      color: #196A73;
      margin-bottom: 40px !important;
      span {
        font-size: 20px;
        font-weight: 400;
        color: #111111;
      }
    }
    .form-box {
      .head-row {
        height: 56px;
        background: #196A73;
        .el-col {
          display: flex;
          align-items: center;
          min-height: 56px;
          padding: 10px 16px;
          box-sizing: border-box;
          p {
            font-size: 16px;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }
      .content-row-box {
        .el-row {
          ::v-deep .el-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 72px;
            height: 100%;
            padding: 10px 16px;
            box-sizing: border-box;
            span {
              display: block;
              min-height: 34px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              word-break: break-all;
              color: #333333;
              font-size: 16px;
            }
            .textarea-box {
              .el-textarea__inner {
                background-color: transparent;
                color: #333333;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .card-box {
      display: flex;
      justify-content: space-between;
      p {
        width: 160px;
        height: 40px;
        line-break: 40px;
        font-size: 20px;
        color: #195AAB;
        background: #EBF4FF;
        letter-spacing: 2px;
        padding-left: 18px;
      }
      .card {
        width: 830px;
        margin-bottom: 80px;
        ::v-deep .textarea-box {
          width: 100%;
      .el-textarea__inner {
        font-size: 24px;
        color: #111111;
      }
    }
    span {
      display: block;
      min-height: 400px;
      font-size: 24px;
      color: #111111;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
    }
      }
    }
  }
}
</style>