<template>
  <div class="table-page">
    <div class="head-box">智能工具 > 服务报告</div>
    <div class="table-container">
      <div class="btns-box">
        <div class="left">
          <el-button @click="add">新增报告</el-button>
        </div>
        <!-- <div class="right">
          <span>搜索</span>
          <el-input
            v-model="keyVal"
            prefix-icon="el-icon-search"
            @keyup.enter.native="chekEnter"
            placeholder="请输入关键词"
          >
          </el-input>
        </div> -->
      </div>
      <el-table
        :height="tableHeight"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in allHeadData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '序号'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            width="'100px'"
          >
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span style="color: #00995f; cursor: pointer;" @click="lookPdf(scope.row)">
                查看
              </span>
              <span style="color: #00995f; cursor: pointer; margin: 0 20px;" @click="download(scope.row)">
                下载
              </span>
              <span style="color: red; cursor: pointer" @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <pdfDetailsDialog v-if="showPdfDialog" :pdfsite="pdfUrl"></pdfDetailsDialog>
  </div>
</template>

<script>
import pdfDetailsDialog from '@/views/Dialog/pdfDetailsDialog.vue'
import { getServicereport, delServicereport }  from "@/FackApi/api/serveReport.js";
import { getFileUrl } from '@/FackApi/api/index.js'
export default {
  name: "tablePage",
  components: {
    pdfDetailsDialog,
  },
  data() {
    return {
      keyVal: "",
      tableHeight: 550,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      tableData: [],
      allHeadData: [
        { align: "center", prop: "编号", label: "序号" },
        { align: "center", prop: "typeName", label: "报告类型" },
        { align: "center", prop: "createTime", label: "创建时间" },
        { align: "center", prop: "操作", label: "操作" },
      ],
      arrTableLength: {},
      showPdfDialog: false,
      pdfUrl: "", // 查看报告详情路径
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {},
  methods: {
    async getInfo() {
      let params = `${this.currentPage}/${this.interPage}`;
      let res = await getServicereport(params);
      // console.log(res,'服务报告');
      if (res.code == 0) {
        this.dataSize = res.totalCount;
        this.tableData = res.items;
      }
    },
    // 查看报告
    async lookPdf(row) {
      let params = {
        expireTime: 3600,
        objectName: row.filePath,
      };
      this.pdfUrl = await getFileUrl(params);
      this.showPdfDialog = true;
    },
    // 删除报告
    del(row) {
      // console.log(row);
      this.$confirm(`请确认是否删除报告【 ${row.typeName} 】`)
        .then((_) => {
          this.delRow(row);
        })
        .catch((_) => {});
    },
    async delRow(row) {
      let res = await delServicereport({ids: [row.id]});
      if (res.code == 0) {
        this.getInfo();
      }
    },
    // 下载报告
    async download(row) {
      let params = {
        expireTime: 3600,
        objectName: row.filePath,
        // BucketName: 'ilaas-files',
        // EndPoint: 'https://oss-cn-shanghai.aliyuncs.com',
      };
      let ress = await getFileUrl(params);
      fetch(ress).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        document.body.appendChild(a)
        a.style.display = 'none'
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // 指定下载的文件名
        a.download = `律师个人服务报告.pdf`;
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    },
    // 新增报告
    add() {
      this.$parent.addPeport();
    },
    // 关键字回车
    chekEnter() {},
    handleSizeChange(psize) {
      this.interPage = psize;
      this.getInfo();
    },
    handleCurrentChange(cpage) {
      this.currentPage = cpage;
      this.getInfo();
    },
  },
};
</script>

<style lang="less" scoped>
.table-page {
  .head-box {
    height: 60px;
    line-height: 60px;
    border-radius: 15px;
    font-size: 14px;
    color: #777;
    background-color: #fff;
    padding-left: 20px;
    margin-bottom: 30px;
  }
  .table-container {
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    .btns-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .left {
        .el-button {
          width: 120px;
          height: 40px;
          line-height: 0;
          background: #ffffff;
          font-size: 14px;
          color: #6474c7;
          border-radius: 8px;
          border: 1px solid #6474c7;
        }
      }
      .left .el-button:nth-child(1) {
        color: #fff;
        margin-right: 20px;
        background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      }
      .right {
        display: flex;
        align-items: center;
        span {
          margin-right: 16px;
          white-space: nowrap;
        }
        /deep/ .el-input {
          .el-input__inner {
            width: 240px;
            height: 40px;
            font-size: 14px;
            background: #eaf5ff;
            padding-left: 40px;
            border-radius: 10px;
            border: none;
          }
          .el-input__prefix {
            .el-input__icon {
              font-size: 22px;
              line-height: 40px;
            }
          }
        }
      }
    }

    /deep/ .el-table {
      border-radius: 18px;
      .el-table__header-wrapper {
        .el-table__header {
          .has-gutter {
            th {
              font-size: 14px;
              color: #6474c7;
              padding: 12px 16px;
              background: #eaf5ff;
            }
            .in-column {
              color: #fff;
              background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            }
          }
        }
      }
      .el-table__body-wrapper {
        min-height: 380px;
        .el-table__body {
          td {
            font-size: 14px;
            color: #333;
            padding: 12px 16px;
            .cell {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .in-column {
            .cell {
              .btn-box {
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 14px;
                  color: #6474c7;
                  cursor: pointer;
                }
                .btn-p {
                  color: #fcc647;
                }
              }
            }
          }
        }
      }
      .el-table__fixed-right {
        thead {
          .is-center {
            color: #fff;
            background: linear-gradient(
              90deg,
              #6878c8 0%,
              #79baf2 100%
            ) !important;
          }
        }
        .in-column {
          .cell {
            .btn-box {
              display: flex;
              justify-content: space-between;
              p {
                font-size: 14px;
                color: #6474c7;
                cursor: pointer;
              }
              .btn-p {
                color: #fcc647;
              }
            }
          }
        }
      }
    }
    .el-pagination {
      align-items: initial;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
</style>

