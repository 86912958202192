<template>
  <div class="edit-wrapper">
    <div class="left">
      <!-- 展开收缩按钮开始 -->
      <div
        class="left-unfold"
        :class="unfoldStatus ? 'active' : 'common'"
        @click="handleUnfold"
      >
        <img
          src="@/assets/serve-report/unfold@2x.png"
          alt=""
          class="left-unfold-img"
        />
        <i
          class="left-unfold-icon"
          :class="[
            !unfoldStatus ? 'el-icon-arrow-right' : 'el-icon-arrow-left',
          ]"
        ></i>
      </div>
      <!-- 展开收缩按钮结束 -->
      <!-- 左侧menu列表开始 -->
      <ul class="menu" @click="handleMenu">
        <li
          class="menu-item"
          :class="[leftType == 1 ? 'active' : '']"
          :data-type="1"
        >
          <img src="@/assets/serve-report/image@2x.png" alt="" />
          <span>图片</span>
        </li>
        <li
          class="menu-item"
          :class="[leftType == 2 ? 'active' : '']"
          :data-type="2"
        >
          <img src="@/assets/serve-report/text@2x.png" alt="" />
          <span>文字</span>
        </li>
        <!-- <li
          class="menu-item"
          :class="[leftType == 3 ? 'active' : '']"
          :data-type="3"
        >
          <img src="@/assets/serve-report/chart@2x.png" alt="" />
          <span>图表</span>
        </li> -->
        <li
          class="menu-item"
          :class="[leftType == 4 ? 'active' : '']"
          :data-type="4"
        >
          <img src="@/assets/serve-report/bg@2x.png" alt="" />
          <span>背景</span>
        </li>
        <li
          class="menu-item"
          :class="[leftType == 5 ? 'active' : '']"
          :data-type="5"
        >
          <img src="@/assets/serve-report/update@2x.png" alt="" />
          <span>上传</span>
        </li>
      </ul>
      <!-- 左侧menu列表结束 -->
      <!-- 左侧展开收缩栏开始 -->
      <transition name="fade">
        <div class="select" v-if="unfoldStatus">
          <selectImg v-if="leftType == 1" @bindImg="bindImg"></selectImg>
          <selectText
            v-else-if="leftType == 2"
            @onAddTitle="onAddTitle"
            @onAddSubTitle="onAddSubTitle"
            @onAddTextContent="onAddTextContent"
          ></selectText>
          <selectChart v-else-if="leftType == 3"></selectChart>
          <selectBg
            v-else-if="leftType == 4"
            @bindChangeColor="bindChangeColor"
            @bindChangeBgimg="bindChangeBgimg"
          ></selectBg>
          <select-update v-else-if="5"></select-update>
        </div>
      </transition>
      <!-- 左侧展开收缩栏结束 -->
    </div>

    <!-- 中间页面开始 -->
    <div class="main">
      <div
        class="main-item"
        ref="contentmain"
        id="contentmain"
        :style="pageStyle"
        @mousedown="handleMouseDown"
      >
        <template v-for="(item, index) in comps">
          <!-- <keep-alive> -->
            <!-- <div :key="index" style="width: 100%; height: 100%;"> -->
          <component
            :is="allComps[item]"
            :key="index"
            v-show="currentIndex == index"
            :index="index"
            :ref="'pageComponent' + index"
            :class="'page-component' + index"
            :pageStatus="pageStatus"
            :pageTitle="listData[index].title"
            @bindTitleConfirm="bindTitleConfirm"
            @bindTitleDelete="bindTitleDelete"
            @bindSubTitleConfirm="bindSubTitleConfirm"
            @bindSubTitleDelete="bindSubTitleDelete"
            @bindTextContentConfirm="bindTextContentConfirm"
            @bindTextContentDelete="bindTextContentDelete"
            @addPage="addPage"
            @toOnePage="toOnePage"
          >
            <div class="slot-content">
                <myHeader
                  :title="pageStatus.titleValue"
                  @dblclick.native="dblTitle"
                ></myHeader>
              <template v-if="!pageStatus.titleStatus && pageStatus.titleValue">
                <div class="head-title" @dblclick="dblTitle">
                  {{ pageStatus.titleValue }}
                </div>
              </template>
              <template
                v-if="!pageStatus.subTitleStatus && pageStatus.subTitleValue"
              >
                <div class="content-title" @dblclick="dblSubTitle">
                  {{ pageStatus.subTitleValue }}
                </div>
              </template>
              <template
                v-if="!pageStatus.contentStatus && pageStatus.contentValue"
              >
                <div class="content-value" @dblclick="dblTextContent">
                  {{ pageStatus.contentValue }}
                </div>
              </template>
            </div>
          </component>
          <!-- </div> -->
          <!-- </keep-alive> -->
        </template>
      </div>
    </div>
    <!-- 中间页面结束 -->

    <!-- 右侧页面开始 -->
    <div class="right">
      <controlTool
        :controlType="2"
        :scalePercent="scalePercent"
        @handleHoming="handleHoming"
        @handleZoom="handleZoom"
        @handleUnfo="handleUnfo"
      ></controlTool>
      <!-- 右侧页面列表开始 -->
      <ul class="list" ref="list">
        <i
          class="line"
          :style="{ transform: 'translateY(' + lineTop + 'px)' }"
        ></i>
        <li
          class="list-item"
          :class="{ active: index == currentIndex }"
          v-for="(item, index) in listData"
          :key="item.id"
          @click="handleToggle(index)"
        >
          <img v-if="item.img" :src="item.img" alt="" class="list-item-img" />
          <div v-else class="list-item-img"></div>
          <div class="list-item-right">
            <span class="title" :class="{ active: index == currentIndex }">{{
              item.title
            }}</span>
            <span class="num" :class="{ active: index == currentIndex }">{{
              index + 1
            }}</span>
          </div>
          <!-- 列表选择中状态时的右侧按钮开始 -->
          <template v-if="index == currentIndex">
            <!-- 列表右侧删除按钮开始 -->
            <div class="delete-btn" @click="handleDelete(index)">
              <i class="el-icon-close"></i>
            </div>
            <!-- 列表右侧删除按钮结束 -->
            <!-- 列表右侧复制按钮开始 -->
            <el-tooltip
              class="copy-btn"
              effect="dark"
              content="复制"
              placement="left-start"
              offset="10"
              v-if="comps[currentIndex] == 'pageBlank'"
            >
              <i class="el-icon-document-copy"></i>
            </el-tooltip>
            <!-- 列表右侧复制按钮结束 -->
            <!-- 三个小点 更多按钮开始 -->
            <el-popover
              class="more-btn"
              placement="left"
              trigger="click"
              v-model="visible"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-button
                  size="mini"
                  style="color: black; width: 100%; flex: 1"
                  type="text"
                  @click="handleAddPage('before', index)"
                  >向前插入一页</el-button
                >
                <el-button
                  size="mini"
                  style="color: black; width: 100%; flex: 1; margin: 0"
                  type="text"
                  @click="handleAddPage('after', index)"
                  >向后插入一页</el-button
                >
              </div>
              <i slot="reference" class="el-icon-more"></i>
            </el-popover>
            <!-- 三个小点更多按钮结束 -->
          </template>
          <!-- 列表选择中状态时的右侧按钮结束 -->
        </li>
        <!-- <li class="list-bottom" @click="handleAddPage('after', currentIndex)">
          <i class="el-icon-plus"></i>
          <span>添加页面</span>
        </li> -->
      </ul>
        <div class="list-bottom" @click="handleAddPage('after', currentIndex)">
          <i class="el-icon-plus"></i>
          <span>添加页面</span>
        </div>
      <!-- 右侧页面列表结束 -->
    </div>
    <!-- 右侧页面结束 -->
  </div>
</template>
<script>
// 引入页面组件集合
import allComps from "@/views/Forms/FormServereport/yearCounselor/pages/index";
// 引入头部组件
import myHeader from "@/views/Forms/FormServereport/yearCounselor/pages/components/myHeader";
// 引入右侧控制工具栏
import controlTool from "@/views/Forms/FormServereport/yearCounselor/components/controlTool";
// 引入选择图片组件
import selectImg from "@/views/Forms/FormServereport/yearCounselor/components/selectImg";
// 引入选择添加文本组件
import selectText from "@/views/Forms/FormServereport/yearCounselor/components/selectText";
// 引入选择图表组件
import selectChart from "@/views/Forms/FormServereport/yearCounselor/components/selectChart";
// 引入选择背景组件
import selectBg from "@/views/Forms/FormServereport/yearCounselor/components/selectBg";
// 引入上传组件
import selectUpdate from "@/views/Forms/FormServereport/yearCounselor/components/selectUpdate";
// 引入html2canvas
import html2canvas from "html2canvas";

import _lodash from "lodash";
import { mapGetters } from 'vuex'
export default {
  components: {
    myHeader,
    controlTool,
    selectImg,
    selectText,
    selectChart,
    selectBg,
    selectUpdate,
  },
  props: {
    list: Array,
  },
  data() {
    return {
      unfoldStatus: false, // 控制展开收缩的开关
      leftType: 0, // 记录当前选中的左侧menu
      currentIndex: 0, // 记录当前选中的文档页面
      lineTop: 0, // 蓝色下划线位置
      visible: false, // 更多按钮的弹出框控制开关
      listData: [], // 文档页面数据源
      // 控制中间放大缩小
      transform: {
        scale: 0.5,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false,
      },
      // 组件名称集合
      comps: [],
      // 所有组件
      allComps: allComps,
      pageStatus: {
        titleStatus: false,
        subTitleStatus: false,
        contentStatus: false,
        titleValue: "",
        subTitleValue: "",
        contentValue: "",
        colorBg: "",
        bgImg: "",
      },
      // 保存的图片集合
      htmlImgList: [],
      // 撤销集合
      undoList: [],
      loading: null, // 加载动画
    };
  },
  methods: {
    // 点击展开关闭工具栏
    handleUnfold() {
      this.unfoldStatus = !this.unfoldStatus;
    },
    // 点击左侧menu菜单
    handleMenu(event) {
      let type =
        event.target.dataset.type || event.target.parentElement.dataset.type;
      if (this.leftType == type && this.unfoldStatus) {
        this.unfoldStatus = false;
        return;
      }
      this.leftType = type;
      this.unfoldStatus = true;
    },
    // 点击放大镜缩小和放大
    handleZoom(type) {
      let { scale } = this.transform;
      // console.log(type);
      if (type == 1 && scale != 1.5) {
        scale = scale + 0.1;
      } else if (type == 2 && scale > 0.5) {
        scale = scale - 0.1;
      }
      this.transform.scale = scale;
    },
    // 点击文档恢复原位
    handleHoming() {
      this.transform.offsetX = 0;
      this.transform.offsetY = 0;
      this.transform.scale = 1;
    },
    // 点击右侧页面列表
    handleToggle(index) {
      // console.log(index);
      // console.log(this.listData,'------');
      this.currentIndex = index;
      this.lineTop = index * 130;
      this.getListPageStatus();
      // console.log(this.pageStatus);
      // this.unifyBlankPage();
      // console.log(this.listData[this.currentIndex].pageStatus);
    },
    // 点击右侧页面列表的删除按钮
    async handleDelete(index) {
      const confirmRes = await this.$confirm("您确定要删除该页面吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => console.log(err));
      // 判断confirmRes的值
      if (confirmRes === "confirm") {
        // console.log("点击了确定");
        // console.log("length和index:", this.listData.length, index);
        this.addUndoList();
        this.listData.splice(index, 1);
        this.comps.splice(index, 1);
        // console.log(this.comps);
        // console.log(this.listData);
        if (index == this.listData.length) {
          this.currentIndex = index - 1;
          this.lineTop = (index - 1) * 130;
        }
      }
    },
    //点击更多按钮添加页面 向前插入一页 向后插入一页
    handleAddPage(type, index) {
      this.addUndoList();
      this.visible = false;
      console.log(type, index);
      let pageStatus = {
        titleStatus: false,
        subTitleStatus: false,
        contentStatus: false,
        titleValue: "",
        subTitleValue: "",
        contentValue: "",
        colorBg: "",
        bgImg: "",
      };
      if (type === "before") {
        // 在之前插入一页
        this.listData.splice(index, 0, {
          img: "",
          title: "新增页",
          pageName: "pageBlank",
          pageStatus,
        });
        this.comps.splice(index, 0, "pageBlank");
        console.log(this.comps);
        console.log(this.listData);
        console.log(this.currentIndex);
        this.currentIndex = 0;
        this.currentIndex = index;
      } else {
        // 在之后插入一页
        this.listData.splice(index + 1, 0, {
          img: "",
          title: "新增页",
          pageName: "pageBlank",
          pageStatus,
        });
        this.comps.splice(index + 1, 0, "pageBlank");
        console.log(this.comps);
        console.log(this.listData);

        this.currentIndex = index + 1;
        this.lineTop = this.currentIndex * 130;
        if (this.listData.length - 1 - index == 2) {
          this.handleScrollBottom();
        }
      }
      // console.log(index, this.currentIndex, "查看");
      this.getListPageStatus();
    },
    // 点击复制一页
    handleCopyPage() {},
    // 让右侧文档页面列表滚动到底部
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollEl = this.$refs.list;
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight,
          behavior: "smooth",
        });
      });
    },
    // 监听文档鼠标按下移动
    handleMouseDown(e) {
      let { titleStatus, subTitleStatus, contentStatus } = this.pageStatus;
      if (titleStatus || subTitleStatus || contentStatus) return;
      const { offsetX, offsetY } = this.transform;
      const startX = e.pageX;
      const startY = e.pageY;
      this._dragHandler = this.rafThrottle((ev) => {
        // this.transform.offsetX = ev.pageX - startX;
        // this.transform.offsetY = ev.pageY - startY;
        this.transform.offsetX = offsetX + ev.pageX - startX;
        this.transform.offsetY = offsetY + ev.pageY - startY;
      });
      document.addEventListener("mousemove", this._dragHandler);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", this._dragHandler);
      });
      document.ondragstart = function (ev) {
        ev.preventDefault();
      };
      document.ondragend = function (ev) {
        ev.preventDefault();
      };
    },
    rafThrottle(fn) {
      let locked = false;
      return function (...args) {
        if (locked) return;
        locked = true;
        window.requestAnimationFrame((_) => {
          fn.apply(this, args);
          locked = false;
        });
      };
    },
    // 点击添加标题
    onAddTitle() {
      this.addUndoList(2);
      console.log("点击添加标题");
      let currentIndex = this.currentIndex;
      let listData = this.listData;
      let pageName = listData[currentIndex].pageName || "";
      if (pageName != "pageBlank") {
        this.$message("请在空白页使用该功能");
        return;
      }
      this.pageStatus.titleStatus = true;
      this.handleUnfold();
    },
    // 监听子组件标题输入框点击确定后传回的标题数据
    bindTitleConfirm(e) {
      this.addUndoList(2);
      console.log("我是标题内容", e);
      this.pageStatus.titleValue = e;
      this.pageStatus.titleStatus = false;
      this.setListPageStatus();
    },
    // 监听子组件标题输入框点击删除
    bindTitleDelete() {
      this.addUndoList(2);
      this.pageStatus.titleValue = "";
      this.pageStatus.titleStatus = false;
      this.setListPageStatus();
    },
    // 双击标题显示输入框
    dblTitle() {
      this.addUndoList(2);
      this.pageStatus.titleStatus = true;
    },

    // 点击添加副标题
    onAddSubTitle() {
      console.log("点击添加副标题");
      let currentIndex = this.currentIndex;
      let listData = this.listData;
      let pageName = listData[currentIndex].pageName || "";
      if (pageName != "pageBlank") {
        this.$message("请在空白页使用该功能");
        return;
      }
      this.addUndoList(2);
      this.pageStatus.subTitleStatus = true;
      this.handleUnfold();
    },
    // 监听子组件 副标题输入框点击确定
    bindSubTitleConfirm(e) {
      this.addUndoList(2);
      console.log(e);
      this.pageStatus.subTitleStatus = false;
      this.pageStatus.subTitleValue = e;
      this.setListPageStatus();
    },
    // 监听子组件 副标题输入框点击删除
    bindSubTitleDelete() {
      this.addUndoList(2);
      this.pageStatus.subTitleStatus = false;
      this.pageStatus.subTitleValue = "";
      this.setListPageStatus();
    },
    // 双击副标题显示输入框
    dblSubTitle() {
      this.addUndoList(2);
      this.pageStatus.subTitleStatus = true;
    },
    // 点击添加正文内容
    onAddTextContent() {
      console.log("点击添加正文内容");
      let currentIndex = this.currentIndex;
      let listData = this.listData;
      let pageName = listData[currentIndex].pageName || "";
      if (pageName != "pageBlank") {
        this.$message("请在空白页使用该功能");
        return;
      }
      this.addUndoList(2);
      this.pageStatus.contentStatus = true;
      this.handleUnfold();
    },
    // 监听子组件的正文输入框点击确定
    bindTextContentConfirm(e) {
      this.addUndoList(2);
      console.log(e);
      this.pageStatus.contentStatus = false;
      this.pageStatus.contentValue = e;
      this.setListPageStatus();
    },
    // 监听子组件的正文输入框点击删除
    bindTextContentDelete() {
      this.addUndoList(2);
      this.pageStatus.contentStatus = false;
      this.pageStatus.contentValue = "";
      this.setListPageStatus();
    },
    // 双击正文内容
    dblTextContent() {
      this.addUndoList(2);
      this.pageStatus.contentStatus = true;
    },

    // 监听子组件点击改变颜色
    bindChangeColor(e) {
      this.addUndoList(2);
      let color = e;
      this.$nextTick(() => {
        this.$refs[`pageComponent${this.currentIndex}`][0].bindChangeColor(color,this.currentIndex);
      })
    },
    // 监听子组件点击改变背景图片
    bindChangeBgimg(e) {
      this.addUndoList(2);
      console.log("我是背景图片", e);
      let bgImg = e;
      // this.pageStatus.bgImg = bgImg;
      this.$nextTick(() => {
        this.$refs[`pageComponent${this.currentIndex}`][0].bindChangeBgimg(bgImg,this.currentIndex);
      })
    },
    // 选择图片
    bindImg(e) {
      this.addUndoList(2);
      let img = e;
      this.$nextTick(() => {
        this.$refs[`pageComponent${this.currentIndex}`][0].bindImg(img);
      })
    },
    // 保存当前空白页的状态到列表
    setListPageStatus() {
      // this.listData[this.currentIndex].pageStatus = this.pageStatus;
      // let page = this.listData[this.currentIndex];
      // page.pageStatus = this.pageStatus;
      this.$set(
        this.listData[this.currentIndex],
        "pageStatus",
        this.pageStatus
      );
      console.log(this.pageStatus);
      console.log(this.listData);
    },

    // 将空白页面状态和表统一
    getListPageStatus() {
      if (this.listData[this.currentIndex].pageStatus) {
        this.pageStatus = this.listData[this.currentIndex].pageStatus;
      }
    },
    async handleEditSave() {
      // this.transform.scale = 1;
      // 需要保存的所有页面数据
      let listData = this.listData;
      let maxNum = listData.length;
      let that = this;
      async function handleSaveImage(i) {
        if (i >= that.comps.length) {
        // if (i >= 4) {
          return that.htmlImgList;
        } 
        let el = document.querySelector(".page-component" + i);
        let displayStatus = el.style.display;
        el.style.display = "block";
        if (listData[i].pageStatus) {
          that.pageStatus = listData[i].pageStatus;
        }
        let canvas = await html2canvas(el, {
          allowTaint: true,
          scale: 2,
          useCORS: true,
        })
        let imgUrl = canvas.toDataURL("image/png");
        el.style.display = displayStatus;
        that.htmlImgList.push(imgUrl);
       await handleSaveImage(i + 1);
      }
     await handleSaveImage(0)
      return that.htmlImgList;
    },

    // 添加撤销操作数据
    addUndoList(type = 1) {
      // type 默认为1 时,表示是页面添加操作,为2时,表示是空白页面添加标题等操作
      if (this.undoList.length == 10) {
        this.undoList.pop();
      }
      if (type == 1) {
        let listData = _lodash.cloneDeep(this.listData);
        let comps = _lodash.cloneDeep(this.comps);
        let undoIndex = this.currentIndex;
        let lineTop = this.lineTop;

        this.undoList.unshift({
          type: 1,
          undoData: {
            listData,
            comps,
            undoIndex,
            lineTop,
          },
        });
        console.log(this.undoList);
      } else {
        let {
          titleStatus,
          subTitleStatus,
          contentStatus,
          titleValue,
          subTitleValue,
          contentValue,
          colorBg,
          bgImg,
        } = this.pageStatus;

        this.undoList.unshift({
          type: 2,
          undoData: {
            titleStatus,
            subTitleStatus,
            contentStatus,
            titleValue,
            subTitleValue,
            contentValue,
            colorBg,
            bgImg,
          },
          undoIndex: this.currentIndex,
          lineTop: this.lineTop,
        });
      }
    },
    // 撤销操作
    handleUnfo() {
      if (this.undoList.length == 0) return;
      let type = this.undoList[0].type;

      if (type == 1) {
        let undoData = this.undoList[0].undoData;
        this.listData = undoData.listData;
        this.comps = undoData.comps;
        this.currentIndex = undoData.undoIndex;
        this.lineTop = undoData.lineTop;
      } else {
        let undoData = this.undoList[0].undoData;
        let undoIndex = this.undoList[0].undoIndex;
        let lineTop = this.undoList[0].lineTop;
        if(undoIndex != this.currentIndex){
          this.currentIndex = undoIndex;
          this.lineTop = lineTop;
        }
        this.pageStatus = undoData;
      }
      this.undoList.shift();
    },
    addPage() {
      let index = 0;
      let idx = 1;
      for (let i = 0; i < this.comps.length; i++) {
        let item = this.comps[i];
        if (item === "page05") {
          index = i;
          idx ++
        }
      }
      let pageStatus = {
        titleStatus: false,
        subTitleStatus: false,
        contentStatus: false,
        titleValue: "",
        subTitleValue: "",
        contentValue: "",
        colorBg: "",
        bgImg: "",
      };
      this.listData.splice(index + 1, 0, {
        img: "",
        title: `全年咨询服务情况（${idx}）`,
        pageName: "page05",
        img: require('@/assets/serve-report/pages/cover/cover28.png'),
        pageStatus,
      });
      this.comps.splice(index + 1, 0,"page05")
      this.currentIndex = index + 1;
    },
    // 回到第一页
    toOnePage() {
      this.$nextTick(() => {
        this.currentIndex = 0;
      })
    },
  },

  mounted() {
    this.listData = this.list.concat([]);
    this.comps = JSON.parse(JSON.stringify(this.serveReportInfo))._comps;
    // 判断是否存在服务客户模块，有就跳转过去处理分页
    // if (this.serveReportInfo._info['目录']['服务客户']) {
    //   this.currentIndex = this.comps.indexOf('page04');
    // } else if (this.serveReportInfo._info['目录']['常年法律顾问']) {
    //   this.currentIndex = this.comps.indexOf('page09');
    // }
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
    // 控制缩放和 移动尺寸
    pageStyle() {
      const { scale, offsetX, offsetY } = this.transform;
      const style = {
        transform: `scale(${scale})`,
        "margin-left": `${offsetX}px`,
        "margin-top": `${offsetY}px`,
      };
      return style;
    },
    // 根据当前选择的列表 返回显示的组件名称
    pagesName() {
      let currentIndex = Number(this.currentIndex);
      let list = this.listData;
      // console.log(list[currentIndex].pageName);
      let comp = list[currentIndex].pageName || "page01";

      return comp;
    },
    // 根据当前的数据判断有几个空白页
    listSlot() {
      let list = [];
      this.listData.forEach((element, index) => {
        if (element.pageName == "pageBlank") {
          list.push({
            listIndex: index,
            pageStatus: element.pageStatus,
          });
        }
      });
      return list;
    },
    // 基于缩放比例显示 百分比
    scalePercent() {
      return Math.round((this.transform.scale * 100).toFixed(2));
    },
  },
  watch: {
    // list: {
    //   handler(newVal, oldVal) {
    //     this.listData = newVal;
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss" scoped>
$color_blue: #217dea;
.edit-wrapper {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 300px);
  .left {
    display: flex;
    position: relative;
    height: 100%;
    &-unfold {
      width: 30px;
      height: 92px;
      position: absolute;
      z-index: 12;
      top: 50%;
      transform: translateY(-50%);
      &.active {
        right: -360px;
      }
      &.common {
        right: -30px;
      }
      &-img {
        width: 100%;
        height: 100%;
      }
      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .menu {
      width: 70px;
      height: calc(100vh - 300px);
      background-color: #f5f7f9;
      overflow-y: auto;
      padding-left: 0;
      &::-webkit-scrollbar {
        display: none;
      }
      &-item {
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        &.active {
          background-color: #fff;
        }
        img {
          width: 20px;
          height: 20px;
          margin-bottom: 8px;
        }
      }
    }
    .select {
      position: absolute;
      left: 70px;
      top: 0;
      z-index: 12;
      width: 330px;
      height: 100%;
      background-color: #fff;
      padding: 0 20px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .main {
    padding: 60px 0;
    flex: 1;
    background-color: #f2f2f2;
    height: calc(100vh - 300px);
    overflow-y: auto;
    display: flex;
    justify-content: center;
    &-item {
      // width: 595px;
      // height: 808px;
      flex-shrink: 0;
      .slot-content {
        padding-top: 150px;
      }
      .head-title {
        margin-top: 50px;
        font-size: 56px;
        font-weight: 600;
        // text-align: center;
        color: #196a73;
      }
      .content-title {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 10px;
        color: #196a73;
      }
      .content-value {
        margin-top: 30px;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
  .right {
    position: relative;
    width: 320px;
    height: calc(100vh - 300px);
    display: flex;
    .control {
      width: 60px;
      background-color: #fff;
    }
    .list {
      width: 260px;
      height: calc(100vh - 300px);
      padding-bottom: 30px;
      // height: calc(100vh + 60px);
      background-color: #fff;
      overflow-y: auto;
      padding-top: 30px;
      position: relative;
      border-left: 1px solid #dee2e6;
      &::-webkit-scrollbar {
        display: none;
      }
      &-item {
        padding-left: 30px;
        height: 100px;
        margin-bottom: 30px;
        display: flex;
        cursor: pointer;
        position: relative;
        &-img {
          width: 70px;
          height: 100%;
          margin-right: 10px;
        }
        &-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 14px;
            &.active {
              color: #217dea;
            }
          }
          .num {
            display: block;

            font-size: 12px;
            background-color: #f5f7f9;
            text-align: center;
            width: 26px;
            height: 20px;
            line-height: 20px;
            &.active {
              background-color: #217dea;
              color: #fff;
            }
          }
        }
        .delete-btn {
          position: absolute;
          top: 0;
          right: 20px;
        }
        .copy-btn {
          position: absolute;
          bottom: 0;
          right: 20px;
        }
        .more-btn {
          position: absolute;
          bottom: 0;
          right: 60px;
        }
      }
      .line {
        position: absolute;
        top: 30px;
        left: 0;
        width: 3px;
        height: 100px;
        transition: all 0.3s;
        background-color: #217dea;
      }
      &-bottom {
        position: fixed;
        z-index: 12;
        bottom: 8px;
        right: 20px;
        background-color: #fff;
        width: 260px;
        height: 43px;
        text-align: center;
        line-height: 43px;
        border-top: 1px solid #dee2e6;
        border-left: 1px solid #dee2e6;
        font-size: 14px;
      }
    }
    .list-bottom {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.15s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(-330px);
  }
}
</style>
