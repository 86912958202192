<template>
  <div class="page-wrapper">
    <myHeader :title="`${page}.常年法律顾问服务`"></myHeader>
    <div class="echarts-box">
      <p class="title">{{page}}.2常年法律顾问服务企业量结构</p>
      <div class="myCharts11" style="width: 1030px; height: 400px"></div>
    </div>
    <div class="content-box">
      <p class="title">{{page}}.3常年法律顾问客户权益情况</p>
      <div class="form-box">
        <el-row class="head-row" type="flex">
          <el-col v-for="(item,index) in headRow" :key="index" :span="item.span">
            <p>{{item.name}}</p>
          </el-col>
        </el-row>
        <div class="content-row-box">
          <el-row v-for="(item2,index2) in myArr" :key="index2">
            <el-col :span="16">
              <p>{{item2.name}}</p>
            </el-col>
            <el-col :span="8">
              <!-- <el-input
                class="textarea-box"
                type="textarea"
                :autosize="{ minRows: 1 }"
                placeholder="请输入内容"
                suffix-icon="el-icon-date"
                v-model="item2.value"
              >
              </el-input> -->
              <div class="input-box" v-if="item2['value'].status">
                <input v-model="item2['value'].val" :id="`ipt${item2['value'].id}`" :ref="`ipt${item2['value'].id}`" @blur="inputonblur(item2,'value',index2)" />
              </div>
              <div v-else class="name-span-box">
                <span @click="clickSpan(item2,'value',index2)" >{{ item2['value'].val }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <myFooter pageNum="09"></myFooter>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
// 引入echarts
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    myFooter,
    VueDragResize,
  },
  data() {
    return {
      page: 0,
      headRow: [
        {name: "客户权益类", span: 16},
        {name: "使用情况", span: 8},
      ],
      myArr: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    initEchart() {
      // console.log(JSON.parse(JSON.stringify(this.serveReportInfo._info))['常年法律顾问'],'企业结构');
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))['常年法律顾问'].natureWithCount;
      let echartData = [];
      let newObj = {};
      for (let key in data) {
        newObj[this.$getNature(Number(key))] = data[key]
      }
      // console.log(newObj,'newObj');
      for (let k in newObj) {
        echartData.push({
          value: newObj[k],
          name: k,
        });
      }
      let myCharts = echarts.init(document.querySelector(".myCharts11"));
      let options = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        tooltip: {
          show: false,
          trigger: "item",
        },
        legend: {
          // orient: 'vertical',
          x: "center",
          y: "bottom",
          textStyle: {
            fontSize: 20,
          },
          // formatter: (val) => {
          //   return `${val}（${data[val]}件）`
          // }
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: echartData,
            // label: {},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  labelLine: {
                    show: true,
                  },
                  formatter: (val) => {
                    return `${val}（${newObj[val]}）`
                  },
                  textStyle: {
                    fontSize: 22,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 40,
                length2: 70,
                lineStyle: {
                  // color: '#333'
                },
              },
            },
            label: {
              normal: {
                formatter: " {b}：{c} \n\n",
                borderWidth: 20,
                borderRadius: 4,
                padding: [0, -70],
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 12,
                    lineHeight: 20,
                  },
                  b: {
                    fontSize: 12,
                    lineHeight: 20,
                    color: "#333",
                  },
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    initForm() {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))['常年法律顾问'].assignmentTypeWithCount;
      this.myArr = [];
      let tol = 0;
      for (let key in data) {
        tol ++;
        this.myArr.push(
          {
            name: key,
            value: {
              id: tol,
              val: data[key],
              status: false,
            },
          }
        )
      }
      // console.log(this.myArr,'权益表格');
    },
    // 输入框失焦
    inputonblur(row,str,idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row)
    },
    // 
    clickSpan(row,str,idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row)
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.initEchart();
    this.initForm();
    this.page = this.serveReportInfo._info['目录']['常年法律顾问'].page;
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  padding: 0;
  padding-bottom: 80px;
    .title {
      font-size: 24px;
      font-weight: bold;
      font-family: HarmonyOS_Sans_SC_Bold;
      color: #333333;
    }
  .echarts-box {
    padding: 20px 80px;
  }
  .content-box {
    padding: 40px 80px 80px;
    box-sizing: border-box;
    .form-box {
      margin-top: 20px;
      .content-row-box {
        .el-row {
          ::v-deep .el-col {
            .textarea-box {
              .el-textarea__inner {
                background-color: transparent;
              }
            }
            span {
              display: block;
              min-height: 24px;
              min-width: 100px;
            }
          }
        }
      }
    }
  }
}
</style>