<template>
  <div class="page-wrapper">
    <myHeader page="08"></myHeader>
    <div class="bg-img">
      <img :src="imgSrc" alt="">
    </div>
    <div class="content-box">
      <p class="title-p">
        9. 企业意见反馈
      </p>
      <div class="explain-box">
        <div class="left">
          <p>说明：</p>
          <p> 1、感谢您选择我们为您提供法律服务。</p>
          <p>2、为了提高我们的服务品质，请您扫描右方二维码，填写反馈单并提交。</p>
          <p>3、如您有任何疑问，欢迎致电 021-51696196 询问。</p>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
import myHeader from "./components/myHeader";
import defaultBgImg from "@/assets/serve-report/pages/page-8.png";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
   components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      headRow: [
        {name: "服务类型", span: 16},
        {name: "服务客户数", span: 8},
      ],
      myArr: [], // 客户数据
      serveReport: {}, // 律师通用数据
      reportDate: this.$getDate(6),
      yearVal: new Date().getFullYear(),
      dateStatus: false, // 日期输入框状态
      createDate: this.$getDate(6),
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    // 点击日期
    clickDate() {
      this.dateStatus = true;
      this.$nextTick(() => {
        document.getElementById(`ipt`).focus();
      })
    },
    // 日期输入框失焦
    inputonblur2() {
      this.dateStatus = false;
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #195AAB;
      margin-bottom: 10px !important;
      margin-top: 80px;
    }
  .bg-img {
    position: absolute;
    bottom: 80px;
    right: 80px;
    width: 400px;
    img {
      width: 100%;
    }
  }
  .explain-box {
    display: flex;
    align-items: center;
    .left {
      p {
        font-size: 20px;
        line-height: 36px;
        color: #111111;
      }
    }
  }
}
</style>