<template>
  <div class="page-wrapper">
    <myHeader :title="`${page}.常年法律顾问服务`"></myHeader>
    <div class="content-box">
      <p class="title">{{page}}.1常年法律顾问服务总表</p>
      <div class="form-box">
        <el-row class="head-row" type="flex">
          <el-col v-for="(item,index) in headRow" :key="index" :span="item.span">
            <p>{{item.name}}</p>
          </el-col>
        </el-row>
        <div class="content-row-box" ref="rowBoxs">
          <el-row v-for="(item2,index2) in myArr" :key="index2">
            <el-col :span="4">
              <p>{{item2.no}}</p>
            </el-col>
            <el-col :span="6">
              <el-input
                v-if="item2['客户名称'].status"
                 :ref="`ipt${item2['客户名称'].id}`"
                @blur="inputonblur(item2,'客户名称',index2)"
                class="textarea-box"
                :id="`ipt${item2['客户名称'].id}`"
                type="textarea"
                placeholder="个人介绍"
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['客户名称'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'客户名称',index2)">{{item2['客户名称'].val}}</span>
            </el-col>
            <el-col :span="5">
              <el-input
                v-if="item2['客户性质'].status"
                 :ref="`ipt${item2['客户性质'].id}`"
                @blur="inputonblur(item2,'客户性质',index2)"
                class="textarea-box"
                :id="`ipt${item2['客户性质'].id}`"
                type="textarea"
                placeholder="个人介绍"
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['客户性质'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'客户性质',index2)">{{item2['客户性质'].val}}</span>
            </el-col>
            <el-col :span="5">
              <el-input
                v-if="item2['客户所在地区'].status"
                 :ref="`ipt${item2['客户所在地区'].id}`"
                @blur="inputonblur(item2,'客户所在地区',index2)"
                class="textarea-box"
                :id="`ipt${item2['客户所在地区'].id}`"
                type="textarea"
                placeholder="个人介绍"
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['客户所在地区'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2,'客户所在地区',index2)">{{item2['客户所在地区'].val}}</span>
            </el-col>
            <el-col :span="4">
              <div class="input-box" v-if="item2['年度顾问服务总年'].status">
                  <input v-model="item2['年度顾问服务总年'].val" :id="`ipt${item2['年度顾问服务总年'].id}`" :ref="`ipt${item2['年度顾问服务总年'].id}`" @blur="inputonblur(item2,'年度顾问服务总年',index2)" />
                </div>
                <div v-else class="name-span-box">
                  <span @click="clickSpan(item2,'年度顾问服务总年',index2)" >{{ item2['年度顾问服务总年'].val }}</span>
                </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <myFooter pageNum="08"></myFooter>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    myFooter,
    VueDragResize,
  },
  props: {
    pageTitle: String,
  },
  data() {
    return {
      page: 0,
      headRow: [
        {name: "序号", span: 4},
        {name: "客户名称", span: 6},
        {name: "客户性质", span: 5},
        {name: "客户所在地区", span: 5},
        {name: "年度顾问服务总年", span: 4},
      ],
      myArr: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      // console.log(this.serveReportInfo._info['常年法律顾问']['customerList'],'常年法律顾问');
      if (sessionStorage.getItem("permanentSurplusInfo")) {
        this.myArr = JSON.parse(sessionStorage.getItem("permanentSurplusInfo")).list;
      } else if (sessionStorage.getItem("permanentPagingInfo") && !sessionStorage.getItem("permanentSurplusInfo")) { // 不是第一次渲染直接拿处理好的数据显示
        let stoIsPermanentPagingInfo = JSON.parse(sessionStorage.getItem("permanentPagingInfo")).list;
        let titleIdx = this.pageTitle.split("）")[0];
        // console.log(titleIdx,'pageTitle');
        titleIdx = titleIdx.split("（");
        titleIdx = titleIdx[titleIdx.length - 1];
        if (titleIdx === "常年法律顾问服务") {
          this.myArr = stoIsPermanentPagingInfo[0];
        } 
        else {
          this.myArr = stoIsPermanentPagingInfo[Number(titleIdx - 1)];
        }
        return
      } else {
        this.myArr = JSON.parse(JSON.stringify(this.serveReportInfo._info))['常年法律顾问']['customerList'];
        this.myArr.forEach((item,index) => {
          item.no = index + 1;
          let i = 0
          for (let k in item) {
            item['客户名称'] = {
              id: `${index}${i++}`,
              val: item.name || '客户名称',
              status: false,
            }
            item['客户性质'] = {
              id: `${index}${i++}`,
              val: item.nature || '客户性质',
              status: false,
            }
            item['客户所在地区'] = {
              id: `${index}${i++}`,
              val: item.area || '客户所在地区',
              status: false,
            }
            item['年度顾问服务总年'] = {
              id: `${index}${i++}`,
              val: item.perennialLegalAdvisorYears || '年度顾问服务总年',
              status: false,
            }
          }
        })
      }
      let totalHeight = 0; // 最大高度为1200
      let newList = this.myArr.concat([]);
        this.$nextTick(() => {
          let permanentPagingInfo = {
              list: [],
            }
          //   console.log(this.pageTitle,'this.pageTitle');
          // console.log(this.$refs.rowBoxs,'this.$refs[rowBoxs]');
          let domList = this.$refs['rowBoxs'].children;
          if (this.pageTitle != "常年法律顾问服务") {
            permanentPagingInfo = JSON.parse(sessionStorage.getItem("permanentPagingInfo"));
          }
          console.log(permanentPagingInfo,'permanentPagingInfo');
          let _isList = [];
          let _i = '';
          // console.log(domList,'domList');
          for (let i = 0; i < domList.length; i++) {
            let dom = domList[i];
            totalHeight += dom.offsetHeight;
            if (totalHeight > 1200) {
              _i = i;
              this.myArr.splice(i,this.myArr.length-i+1);
              _isList = this.myArr.concat([]);
              permanentPagingInfo.list.push(_isList);
              sessionStorage.setItem("permanentPagingInfo",JSON.stringify(permanentPagingInfo));
              newList.splice(0,i);
              if (newList.length !== 0) {
                let permanentSurplusInfo = {
                  list: newList,
                }
                sessionStorage.setItem("permanentSurplusInfo",JSON.stringify(permanentSurplusInfo));
                this.$emit("addPage2");
              } 
              break
            } 
          }
          if (_i === '') {
            console.log(permanentPagingInfo,'------------');
            permanentPagingInfo.list.push(this.myArr);
            sessionStorage.setItem("permanentPagingInfo",JSON.stringify(permanentPagingInfo));
            sessionStorage.removeItem("permanentSurplusInfo");
            this.$emit("toOnePage")
          }
        })
    },
    // 输入框失焦
    inputonblur(row,str,idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row)
    },
    // 
    clickSpan(row,str,idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row)
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.$nextTick(()=> {
      this.init();
      this.page = this.serveReportInfo._info['目录']['常年法律顾问'].page;
    })
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  padding: 0;
  padding-bottom: 80px;
  .content-box {
    padding: 40px 80px 80px;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-weight: bold;
      font-family: HarmonyOS_Sans_SC_Bold;
      color: #333333;
    }
    .form-box {
      margin-top: 20px;
      .content-row-box {
        .el-row {
          ::v-deep .el-col {
            span {
              display: block;
              min-height: 24px;
              min-width: 100px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              word-break: break-all;
            }
            .textarea-box {
              .el-textarea__inner {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}
</style>