<template>
  <div class="select-wrapper">
    <span class="select-wrapper-title">图片库</span>
    <ul class="select-wrapper-list">
      <li class="item" v-for="(item, index) in serveReportInfo.upImgList" :key="index" @click="selectImg(item)">
        <i @click="romImg(index)" class="el-icon-circle-close"></i>
        <el-image :src="item" fit="cover"></el-image>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  methods: {
    selectImg(item) {
      this.$emit("bindImg",item);
    },
    romImg(idx) {
      let stoInfo = JSON.parse(JSON.stringify(this.serveReportInfo));
      let newList = stoInfo.upImgList;
      newList.splice(idx,1);
      stoInfo.upImgList = newList;
      this.$store.dispatch("index/setServeReportInfo", stoInfo);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  &-title {
    display: block;
    font-size: 12px;
    color: #909399;
    padding: 20px 0;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    .item {
      position: relative;
      width: 80%;
      // height: 90px;
      margin-top: 10px;
      margin-right: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
      margin-bottom: 10px;
      // background-color: #f8f8fb;
      // overflow: hidden;
      i {
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 20px;
        color: #0094ff;
        z-index: 2;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(-n + 3) {
        margin-top: 0;
      }
      .el-image {
        vertical-align: middle;
        border-radius: 10px;
      }
      img {
        width: 90px;
        border-radius: 10px;
      }
    }
  }
}
</style>