<template>
  <div class="pdfD-dtails-dialog">
    <el-dialog
      title="报告详情"
      center
      :show-close="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <iframe :src="pdfsite" frameborder="0" width='100%' height="700px" ref="mapFrame"  scrolling="auto" class="mapFrame" id="mapFrame"></iframe>
      <!-- <div class="btns-box">
        <button class="btn" @click="cancel">关 闭</button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "pdfDetailsDialog",
  components: {},
  props: {
    pdfsite: String,
  },
  data() {
    return {
      dialogVisible: true,
      myInfo: null,
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
          // done();
          this.$parent.showPdfDialog = false;
        // })
        // .catch((_) => {});
    },
    cancel() {
      this.$parent.showPdfDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.pdf-details-dialog {
  /deep/ .el-dialog__wrapper {
    // .el-dialog__header {
    //   padding: 0;
    // }
  }
}
</style>
