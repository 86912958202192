<template>
  <div class="page-wrapper">
    <myHeader :title="`${page}.案件代理报告`"></myHeader>
    <div class="echarts-box1">
      <p class="title">{{page}}.1代理案件案由分布</p>
      <div class="myCharts5" style="width: 1030px; height: 600px"></div>
      <p class="foot-p">
        从上面的案由分类情况可以看到：截止报告生成日期，本律师代理的案件总量为（{{total}}）件。各类案由的组成如上图所示。
      </p>
    </div>
    <div class="echarts-box2">
      <p class="title">{{page}}.2代理案件程序分布</p>
      <div class="myCharts6" style="width: 1030px; height: 600px"></div>
      <p class="foot-p">
        从上面的案由分类情况可以看到：<br />
        截止报告生成日期，代理案件中
        <span v-for="(val,key,i) in stepInfo" :key="i">
          {{key}}案件有（{{val}}）件&nbsp;&nbsp;
        </span>
      </p>
    </div>
    <myFooter pageNum="06"></myFooter>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
// 引入echarts
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    myFooter,
    VueDragResize,
  },
  data() {
    return {
      page: 0,
      stepInfo: {}, // 案件程序
      total: 0,
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    initEchart() {
      console.log(this.serveReportInfo._info);
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))["代理案件"].typeCount;
      let echartData = [];
      this.total = 0 // 总数
      for (let k in data) {
        this.total += data[k];
      }
      for (let key in data) {
        echartData.push({
          value: data[key],
          name: key,
        });
      }
      let myCharts = echarts.init(document.querySelector(".myCharts5"));
      let options = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        tooltip: {
          show: false,
          trigger: "item",
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          textStyle: {
            fontSize: 20,
          },
          formatter: (val) => {
            return `${val}（${data[val]}件）`
          }
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "65%"],
            data: echartData,
            // label: {},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    fontSize: 22,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 50,
                length2: 70,
                lineStyle: {
                  // color: '#333'
                },
              },
            },
            label: {
              normal: {
                formatter: " {b}：{c} \n\n",
                borderWidth: 20,
                borderRadius: 4,
                padding: [0, -70],
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 12,
                    lineHeight: 20,
                  },
                  b: {
                    fontSize: 12,
                    lineHeight: 20,
                    color: "#333",
                  },
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    initEchart2() {
      // console.log(this.serveReportInfo._info);
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))["代理案件"].caseNodeCount;
      let echartData = [];
      this.stepInfo = {};
      for (let k in data) {
        // data.name = this.$getCaseNodeList(k)
        this.stepInfo[this.$getCaseNodeList(k)] = data[k];
      }
      console.log(this.stepInfo);
      for (let key in this.stepInfo) {
        echartData.push({
          value: this.stepInfo[key],
          name: key,
        });
      }
      console.log(echartData);
      let myCharts = echarts.init(document.querySelector(".myCharts6"));
      let options = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        tooltip: {
          show: false,
          trigger: "item",
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          textStyle: {
            fontSize: 20,
          },
          formatter: (val) => {
            return `${val}（${this.stepInfo[val]}件）`
          }
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "65%"],
            data: echartData,
            // label: {},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    fontSize: 22,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 50,
                length2: 70,
                lineStyle: {
                  // color: '#333'
                },
              },
            },
            label: {
              normal: {
                formatter: " {b}：{c} \n\n",
                borderWidth: 20,
                borderRadius: 4,
                padding: [0, -70],
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 12,
                    lineHeight: 20,
                  },
                  b: {
                    fontSize: 12,
                    lineHeight: 20,
                    color: "#333",
                  },
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.initEchart();
    this.initEchart2();
    this.page = this.serveReportInfo._info['目录']['代理案件'].page;
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  padding: 0;
  padding-bottom: 80px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
    .foot-p {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #111111;
      font-size: 16px;
      white-space: nowrap;
    }
  .echarts-box1 {
    position: relative;
    padding: 20px 80px;
    background: #f7f8fa;
    margin-top: 20px;
  }
  .echarts-box2 {
    position: relative;
    padding: 20px 80px;
  }
}
</style>