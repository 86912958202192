<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <myHeader page="01"></myHeader>
    <div class="content-box">
      <!-- <p class="title-p">目录</p> -->
      <p v-for="(item,index) in catalogueList" :key="index">
        {{item.name}}<br/>
        <!-- <span>{{item.page}}</span> -->
        <span v-for="(item2,index2) in item.children" :key="index2">
          {{item2.name}}<br/>
        </span>
      </p>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入背景图片路径
import defaultBgImg from "@/assets/serve-report/pages/page-14.png";
import VueDragResize from "vue-drag-resize"
import { mapGetters } from "vuex";
export default {
  components: { 
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      catalogueList: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      // console.log(this.serveReportInfo);
      this.catalogueList = JSON.parse(JSON.stringify(this.serveReportInfo))._catalogue;
      console.log(this.catalogueList,'目录');
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }},
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin: 440px 230px 0 0;
    p {
      // display: flex;
      // justify-content: space-between;
      width: 30%;
      font-size: 24px;
      color: #111;
      margin-bottom: 50px !important;
      span {
        color: #909399;
        padding: 30px;
      }
    }
  }
}
</style>