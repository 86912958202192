<template>
  <div class="add-page">
      <div class="head-box">智能工具 > 服务报告</div>
      <div class="content-box">
        <div class="row-box" v-show="!showReportPage">
          <span>选择类型</span>
          <div class="type-box">
            <div
              :class="`card-box ${typeIdx === index ? 'is-card-box' : ''}`"
              v-for="(item, index) in typeList"
              :key="index"
            >
              <div class="img-box" @click="setType(index)">
                <img :src="item.url" alt="" />
                <img
                  class="icon-img"
                  :src="`https://file-public.ilaas.cn/iLaaSWorkbench/serveReport/icon-${
                    typeIdx === index ? '2' : '1'
                  }.png`"
                  alt=""
                />
              </div>
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <yearCounselor v-if="typeIdx === 1"></yearCounselor>
        <caseAgent v-else-if="typeIdx === 2"></caseAgent>
        <personalAbility v-else-if="typeIdx === 0"></personalAbility>
        <specially v-else-if="typeIdx === 3"></specially>
      </div>
  </div>
</template>

<script>
import personalAbility from './personalAbility/index.vue'
import caseAgent from './caseAgent/index.vue'
import specially from './specially/index.vue'
import yearCounselor from './yearCounselor/index.vue'
// import { mapGetters } from 'vuex'   
// import { keyboard } from '@amcharts/amcharts4/core';
// import { getAgent, getSpecialService, getYearAdvisor } from "@/FackApi/api/serveReport.js";
export default {
  name: "addPage",
  components: {
    personalAbility,
    caseAgent,
    specially,
    yearCounselor,
  },
  data() {
    return {
      typeList: [
        {
          name: "律师个人能力服务报告",
          url: require("@/assets/serve-report/type-3.png"),
        },
        { name: "年度法律顾问服务报告", url: "https://file-public.ilaas.cn/iLaaSWorkbench/serveReport/type-1.png" },
        { name: "案件代理能力服务报告", url: "https://file-public.ilaas.cn/iLaaSWorkbench/serveReport/type-2.png" },
        {
          name: "专项法律服务报告",
          url: "https://file-public.ilaas.cn/iLaaSWorkbench/serveReport/type-4.png",
        },
      ],
      typeIdx: 0, // 选择的报告类型
      showReportPage: false,
    };
  },
  created() {},
  mounted() {
    // this.$nextTick(()=> {
    //   this.serveReport = JSON.parse(JSON.stringify(this.serveReportInfo))
    // })
  },
  computed: {
    // ...mapGetters({
    //   serveReportInfo: 'index/serveReportInfoState',
    // }),
  },
  methods: {
    // 选择报告类型
    setType(idx) {
      this.typeIdx = idx;
    },
    // 返回列表
    back() {
      this.$parent.showPage = true;
    },
  },
};
</script>

<style lang="less" scoped>
.add-page {
  .head-box {
    height: 60px;
    line-height: 60px;
    border-radius: 15px;
    font-size: 14px;
    color: #777;
    background-color: #fff;
    padding-left: 20px;
    margin-bottom: 30px;
  }
  .content-box {
    min-height: 600px;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    .row-box {
      display: flex;
      span {
        white-space: nowrap;
        color: #909399;
      }
      .type-box {
        display: flex;
        margin-left: 20px;
        margin-bottom: 50px;
        .card-box {
          text-align: center;
          margin-right: 30px;
          .img-box {
            position: relative;
            padding: 14px;
            border-radius: 14px;
            border: 1px solid #d4d4d4;
            cursor: pointer;
            img {
              width: 172px;
            }
            .icon-img {
              position: absolute;
              top: 0;
              right: 0;
              width: 36px;
            }
          }
          p {
            font-size: 14px;
            color: #333333;
          }
        }
        .is-card-box {
          .img-box {
            border: 1px solid #6474c7;
          }
          p {
            color: #6474c7;
          }
        }
      }
      .right-box {
        width: 100%;
        margin-left: 20px;
        .row-btns {
          display: flex;
          width: 90%;
          margin-bottom: 20px;
          .btn1 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 140px;
            height: 40px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #d4d4d4;
            margin-right: 20px;
            cursor: pointer;
            .dot {
              width: 18px;
              height: 18px;
              border-radius: 9px;
              background: #ffffff;
              border: 1px solid #c0c4cc;
              margin-right: 10px;
              box-sizing: border-box;
            }
            p {
              font-size: 14px;
              color: #333;
            }
          }
          .is-btn1 {
            background: #f7fbff;
            border: 1px solid #6474c7;
            .dot {
              border: 5px solid #6474c7;
            }
            p {
              color: #6474c7;
            }
          }
        }
      }
    }
    .btns-box {
      margin: 200px 0 0 90px;
      .el-button {
        width: 140px;
        height: 50px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
        border-radius: 25px;
      }
      .btn1 {
        background: #f1f2f1;
        color: #bbbbbb;
      }
    }
  }
}
</style>

