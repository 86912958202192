<template>
  <div class="page-wrapper">
    <myHeader page="07"></myHeader>
    <div class="content-box">
      <p class="title-p">
        关于我们
      </p>
      <p class="title-p-2">
        律所简介
      </p>
      <el-input
          v-if="lawOffice.status"
          :ref="`ipt${lawOffice.id}`"
          @blur="inputonblur(lawOffice)"
          class="textarea-box"
          :id="`ipt${lawOffice.id}`"
          type="textarea"
          placeholder=""
          :autosize="{ minRows: 7 }"
          suffix-icon="el-icon-date"
          v-model="lawOffice.val"
        >
        </el-input>
        <span v-else class="intro-span" @click="clickSpan(lawOffice)">{{lawOffice.val}}</span>
      <p class="title-p-2">
        服务团队
      </p>
      <div class="member-box">
        <div class="card" v-for="(item,index) in memberList" :key="index">
          <div class="img-box">
            <img :src="item['头像']" alt="">
          </div>
          <div class="right-box">
            <div class="name">
              <input v-if="item['名称'].status" v-model="item['名称'].val" :ref="`nameIpt${item['名称'].id}`" @blur="inputonblur2(item['名称'])" />
              <span v-else @click="clickName(item['名称'])" >{{ item['名称'].val }}</span>
              <span class="post-span">{{item['职位'].val}}</span>
            </div>
            <el-input
              v-if="item['介绍'].status"
              :ref="`ipt${item['介绍'].id}`"
              @blur="inputonblur3(item['介绍'])"
              class="textarea-box"
              :id="`ipt${item['介绍'].id}`"
              type="textarea"
              placeholder=""
              :autosize="{ minRows: 4 }"
              suffix-icon="el-icon-date"
              v-model="item['介绍'].val"
            >
            </el-input>
            <span v-else class="intro-span" @click="clickSpan3(item['介绍'])">{{
              item['介绍'].val
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      lawOffice: {
        id: 1,
        val: '律所简介',
        status: false,
      },
      memberList: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    // 输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 
    clickSpan(info) {
      info.status = true;
      this.$nextTick(() => {
        this.$refs[`ipt${info.id}`].focus();
      })
    },
    inputonblur2(info) {
      console.log(info);
      info.status = false;
    },
    clickName(info) {
      info.status = true;
      this.$nextTick(() => {
        this.$refs[`nameIpt${info.id}`][0].focus();
      })
    },
    inputonblur3(info) {
      info.status = false;
    },
    clickSpan3(info) {
      info.status = true;
      this.$nextTick(() => {
        let re = `ipt${info.id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.$nextTick(()=> {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo));
      this.memberList = [];
      this.lawOffice.val = data._info.team.profile || '团队简介';
      let id = 0;
      let list = data._info.team.members;
      // console.log(list);return
      for (let i = 0; i < list.length; i++) {
        if (Number(i) == 6) break;
        let item = list[i];
        this.memberList.push(
          {
            '头像': item.avatar ? `https://file-public.ilaas.cn/${item.avatar}`:'',
            '名称': {
              id: id++,
              val: item.name,
              status: false,
            },
            '职位': {
              id: id++,
              val: item.position,
              status: false,
            },
            '介绍': {
              id: id++,
              val: item.profile,
              status: false,
            },
          }
        )
      }
    })
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  .content-box {
    margin-top: 160px;
    .title-p {
      font-size: 56px;
      font-weight: 600;
      color: #196A73;
      margin-bottom: 10px !important;
    }
    .title-p-2 {
      font-size: 24px;
      font-weight: bold;
      color: #196A73;
      margin-bottom: 16px !important;
    }
    ::v-deep .textarea-box {
      margin-bottom: 60px;
      .el-textarea__inner {
        font-size: 24px;
        color: #111111;
      }
    }
    .intro-span {
      display: block;
      min-height: 350px;
      min-width: 200px;
      font-size: 24px;
      color: #111111;
      text-indent: 2em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      margin-bottom: 40px;
    }
    .member-box {
      display: flex;
      flex-wrap: wrap;
      .card {
        display: flex;
        flex-direction: initial;
        width: 50%;
        margin-bottom: 20px;
        .img-box {
          width: 200px;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right-box {
          width: 300px;
          margin-left: 16px;
          .name {
            overflow: hidden;
            input {
              font-size: 20px;
              color: #196A73;
            }
            span {
              font-size: 20px;
              color: #196A73;
            }
            .post-span {
              color: #909399;
              margin-left: 20px;
            }
          }
          .textarea-box {
            padding-right: 20px;
          }
          .intro-span {
            display: block;
            min-height: 50px;
            min-width: 100px;
            height: 180px;
            font-size: 14px;
            margin-bottom: 0;
            text-indent: 0 !important;
            padding-right: 20px;
          }
        }
      }
    }
  }
}
</style>