<template>
  <div class="preview-wrapper">
    <!-- 左侧列表页面开始 -->
    <ul class="list">
      <i
        class="line"
        :style="{ transform: 'translateY(' + lineTop + 'px)' }"
      ></i>
      <li
        class="list-item"
        :class="{ active: index == currentIndex }"
        v-for="(item, index) in listData"
        :key="item.id"
        @click="handleToggle(index,item)"
      >
        <img :src="item.img" alt="" class="list-item-img" />
        <div class="list-item-right">
          <span class="title" :class="{ active: index == currentIndex }">{{
            item.title
          }}</span>
          <span class="num" :class="{ active: index == currentIndex }">{{
            index + 1
          }}</span>
        </div>
      </li>
    </ul>
    <!-- 左侧列表页面结束 -->

    <!-- 中间文档页面开始 -->
    <div class="main">
      <div
        class="main-item"
        ref="contentmain"
        :style="pageStyle"
        @mousedown="handleMouseDown"
      >
        <!-- <keep-alive>
          <component :key="currentIndex" :is="allComps[pageName]" @set="set"></component>
        </keep-alive> -->
        <template v-for="(item, index) in comps">
          <!-- <keep-alive> -->
            <component
              :key="index"
              v-if="currentIndex == index"
              :pageTitle="listData[index].title"
              :is="allComps[item]"
              @addPage="addPage"
              @addPage2="addPage2"
              @setPage="setPage"
              @toOnePage="toOnePage"
              ></component>
          <!-- </keep-alive> -->
        </template>
      </div>
    </div>
    <!-- 中间文档页面结束 -->

    <!-- 右侧按钮框开始 -->
    <controlTool
      :scalePercent="scalePercent"
      @handleHoming="handleHoming"
      @handleZoom="handleZoom"
    ></controlTool>
    <!-- 右侧按钮框结束 -->
  </div>
</template>
<script>
// 引入组件集合
import allComps from "@/views/Forms/FormServereport/caseAgent/pages/index";
// 引入右侧控制工具栏  generateReport
import controlTool from "@/views/Forms/FormServereport/caseAgent/components/controlTool";
// 引入echarts
const echarts = require("echarts");
import { mapGetters } from 'vuex'
export default {
  components: {
    controlTool,
  },
  props: {
    list: Array,
  },
  data() {
    return {
      currentIndex: 0, // 记录当前选中的index
      lineTop: 0, // 控制active蓝色下划线的位置
      scaleNum: 1, // 缩放倍数
      listData: [], // 数据源
      pageName: "page01", // 显示的组件名称
      transform: {
        scale: 0.5,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false,
      },
      // 组件名称集合
      comps: [],
      allComps: allComps,
    };
  },
  mounted() {
    // console.log(this.list,'this.listthis.list');
    this.listData = this.list.concat([]);
    this.init();
  },
  methods: {
    init() {
      this.comps = JSON.parse(JSON.stringify(this.serveReportInfo))._comps;
      // console.log(this.serveReportInfo);
      // 判断是否存在服务客户模块，有就跳转过去处理分页
      // if (this.serveReportInfo._info['目录']['服务客户']) {
      //   this.currentIndex = this.comps.indexOf('page04');
      // } else if (this.serveReportInfo._info['目录']['常年法律顾问']) {
      //   this.currentIndex = this.comps.indexOf('page09');
      // }
      // this.list.forEach(item => {
      //   this.comps.push(item.pageName)
      // })
    },
    // 预览模式 点击左侧页面导航栏
    handleToggle(index,item) {
      this.pageName = item.pageName;
      this.currentIndex = index;
      this.lineTop = index * 130;
    },
    // 点击放大镜缩小和放大
    handleZoom(type) {
      let { scale } = this.transform;
      if (type == 1 && scale != 1.5) {
        scale = scale + 0.1;
      } else if (type == 2 && scale > 0.5) {
        scale = scale - 0.1;
      }
      this.transform.scale = scale;
    },
    // 点击文档恢复原位
    handleHoming() {
      this.transform.offsetX = 0;
      this.transform.offsetY = 0;
      this.transform.scale = 1;
    },

    // 监听文档鼠标按下移动
    handleMouseDown(e) {
      const { offsetX, offsetY } = this.transform;
      const startX = e.pageX;
      const startY = e.pageY;
      this._dragHandler = (ev) => {
        this.transform.offsetX = ev.pageX - startX;
        this.transform.offsetY = ev.pageY - startY;
        this.transform.offsetX = offsetX + ev.pageX - startX;
        this.transform.offsetY = offsetY + ev.pageY - startY;
      };
      document.addEventListener("mousemove", this._dragHandler);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", this._dragHandler);
      });
      document.ondragstart = function (ev) {
        ev.preventDefault();
      };
      document.ondragend = function (ev) {
        ev.preventDefault();
      };
    },
    rafThrottle(fn) {
      let locked = false;
      return function (...args) {
        if (locked) return;
        locked = true;
        window.requestAnimationFrame((_) => {
          fn.apply(this, args);
          locked = false;
        });
      };
    },
    // 客户分页处理完再处理常年数据
    // 处理常年法律顾问服务表格
    setPage() {
      if (this.serveReportInfo._info['目录']['常年法律顾问']) {
        this.currentIndex = this.comps.indexOf('page09');
      } else {
        this.currentIndex = 0;
      }
    },
    // 客户数据分页处理
    addPage() {
      let index = 0;
      let idx = 1;
      for (let i = 0; i < this.comps.length; i++) {
        let item = this.comps[i];
        if (item === "page04") {
          index = i;
          idx ++
        }
      }
      let pageStatus = {
        titleStatus: false,
        subTitleStatus: false,
        contentStatus: false,
        titleValue: "",
        subTitleValue: "",
        contentValue: "",
        colorBg: "",
        bgImg: "",
      };
      this.listData.splice(index + 1, 0, {
        img: "",
        title: `服务客户信息（${idx}）`,
        pageName: "page04",
        img: require('@/assets/serve-report/pages/cover/cover4.png'),
        pageStatus,
      });
      this.comps.splice(index + 1, 0,"page04")
      this.currentIndex = index + 1;
    },
    // 常年法律顾问表格分页处理
    addPage2() {
      let index = 0;
      let idx = 1;
      for (let i = 0; i < this.comps.length; i++) {
        let item = this.comps[i];
        if (item === "page09") {
          index = i;
          idx ++
        }
      }
      let pageStatus = {
        titleStatus: false,
        subTitleStatus: false,
        contentStatus: false,
        titleValue: "",
        subTitleValue: "",
        contentValue: "",
        colorBg: "",
        bgImg: "",
      };
      this.listData.splice(index + 1, 0, {
        img: "",
        title: `常年法律顾问服务（${idx}）`,
        pageName: "page09",
        img: require('@/assets/serve-report/pages/cover/cover9.png'),
        pageStatus,
      });
      this.comps.splice(index + 1, 0,"page09")
      this.currentIndex = index + 1;
    },
    // 回到第一页
    toOnePage() {
      this.$nextTick(() => {
        this.currentIndex = 0;
      })
    },
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
    // 基于缩放比例显示 百分比
    scalePercent() {
      return Math.round((this.transform.scale * 100).toFixed(2));
    },
    // 控制缩放和 移动尺寸
    pageStyle() {
      const { scale, offsetX, offsetY } = this.transform;
      const style = {
        transform: `scale(${scale})`,
        "margin-left": `${offsetX}px`,
        "margin-top": `${offsetY}px`,
      };
      return style;
    },
    // 根据当前选择的列表 返回显示的组件名称
    pagesName() {
      let currentIndex = Number(this.currentIndex);
      let comp = "";
      switch (currentIndex) {
        case 0:
          comp = "page01";
          break;
        case 1:
          comp = "page02";
          break;
        case 2:
          comp = "page03";
          break;
        case 3:
          comp = "page04";
          break;
        case 4:
          comp = "page05";
          break;
        case 5:
          comp = "page06";
          break;
        case 6:
          comp = "page07";
          break;
        case 7:
          comp = "page08";
          break;
        case 8:
          comp = "page09";
          break;

        default:
          comp = "page01";
          break;
      }
      // console.log(comp,'comp');
      return comp;
    },
  },
  watch: {
    // list: {
    //   handler(newVal, oldVal) {
    //     this.listData = newVal;
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss" scoped>
.preview-wrapper {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 300px);

  .list {
    width: 236px;
    height: calc(100vh - 300px);
    // height: calc(100vh + 60px);
    background-color: #fff;
    overflow-y: auto;
    padding-top: 30px;
    position: relative;
    z-index: 11;
    &::-webkit-scrollbar {
      display: none;
    }
    &-item {
      padding-left: 30px;
      height: 100px;
      margin-bottom: 30px;
      display: flex;
      cursor: pointer;
      &-img {
        width: 70px;
        height: 100%;
        margin-right: 10px;
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          width: 84px;
          font-size: 14px;

          &.active {
            color: #217dea;
          }
        }
        .num {
          display: block;
          font-size: 12px;
          background-color: #f5f7f9;
          text-align: center;
          width: 26px;
          height: 20px;
          line-height: 20px;
          &.active {
            background-color: #217dea;
            color: #fff;
          }
        }
      }
    }
    .line {
      position: absolute;
      top: 30px;
      left: 0;
      width: 3px;
      height: 100px;
      transition: all 0.3s;
      background-color: #217dea;
    }
  }
  .main {
    padding: 60px 0;
    flex: 1;
    background-color: #f2f2f2;
    height: calc(100vh - 300px);
    overflow-y: auto;
    display: flex;
    justify-content: center;
    position: relative;
    &-item {
      background-color: #fff;
      // width: 595px;
      // height: 808px;
      flex-shrink: 0;
    }
  }
  .control {
    width: 60px;
    height: calc(100vh - 300px);
    background-color: #fff;
    position: relative;
    z-index: 11;
    .zoom {
      position: absolute;
      bottom: 150px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        width: 100%;
        text-align: center;
        i {
          color: #909399;
        }
        span {
          display: inline-block;
          margin: 25px 0;
        }
      }
    }
  }
}
</style>