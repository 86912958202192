<template>
  <div class="personal-ability">
    <div v-show="!showReportPage">
      <div class="row-box">
        <span>选择数据</span>
        <div class="right-box">
          <div class="row-btns">
            <div
              :class="`btn1 ${infoType === 1 ? 'is-btn1' : ''}`"
              @click="setInfoType(1)"
            >
              <div class="dot"></div>
              <p>系统统计</p>
            </div>
          </div>
          <infoPage1 ref="infoPage1" v-show="infoType === 1"></infoPage1>
        </div>
      </div>
      <div class="btns-box">
        <el-button @click="createReport">进入报告</el-button>
        <el-button class="btn1" @click="back">取 消</el-button>
      </div>
    </div>
    <reportPage v-if="showReportPage"></reportPage>
  </div>
</template>

<script>
import reportPage from "./reportPage.vue";
import infoPage1 from "./components/infoPage1.vue";
import { mapGetters } from "vuex";
import { FailedToNegotiateWithServerError } from "@microsoft/signalr/dist/esm/Errors";
export default {
  name: "personalAbility",
  components: {
    reportPage,
    infoPage1,
  },
  data() {
    return {
      infoType: 1, // 选择的数据方式
      showReportPage: false, // 是否显示生成报告页面
      serveReport: {}, // 报告数据集
    };
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
      userInfo: "index/userInfoState",
    }),
  },
  created() {
    this.serveReport = JSON.parse(JSON.stringify(this.serveReportInfo));
  },
  mounted() {},
  methods: {
    // 选择的数据方式
    setInfoType(num) {
      this.infoType = num;
    },
    // 生成报告
    async createReport() {
      let newObj = JSON.parse(JSON.stringify(this.serveReport));
      let data = this.$refs.infoPage1.statisticalData();
      if (!data.id) {
        this.$message.warning(`请选择任务!`);
        return;
      }
      newObj._info = data;
      let catalogue = [
        { id: 1, name: "1.项目基本信息", page: "02" },
        { id: 2, name: "2.项目背景", page: "02" },
        { id: 3, name: "3.项目目标", page: "02" },
        { id: 4, name: "4.项目团队成员", page: "02" },
        { id: 5, name: "5.项目任务时间表", page: "03" },
        { id: 6, name: "6.项目完成总结", page: "04" },
        { id: 7, name: "7.关于我们", page: "05" },
        { id: 7, name: "7.客户意见反馈", page: "06" },
      ];
      newObj.companyName = data.name;
      newObj._catalogue = catalogue;
      let id = 0;
      let pageList = [
        // 所有子页面
        {
          id: id++,
          img: require("@/assets/serve-report/pages/cover/cover36.png"),
          title: "首页",
          pageName: "page01",
        },
        {
          id: id++,
          img: require("@/assets/serve-report/pages/cover/cover37.png"),
          title: "目录",
          pageName: "page02",
        },
        {
          id: id++,
          img: require("@/assets/serve-report/pages/cover/cover38.png"),
          title: "项目基本信息",
          pageName: "page03",
        },
        {
          id: id++,
          img: require("@/assets/serve-report/pages/cover/cover39.png"),
          title: "项目任务时间表",
          pageName: "page04",
        },
        {
          id: id++,
          img: require("@/assets/serve-report/pages/cover/cover40.png"),
          title: "项目完成总结",
          pageName: "page05",
        },
        {
          id: id++,
          img: require("@/assets/serve-report/pages/cover/cover41.png"),
          title: "关于我们",
          pageName: "page08",
        },
        {
          id: id++,
          img: require("@/assets/serve-report/pages/cover/cover43.png"),
          title: "尾页",
          pageName: "page9",
        },
      ];
      newObj._pageList = pageList;
      let comps = [
        "page01",
        "page02",
        "page03",
        "page04",
        "page05",
        "page08",
        "page09",
      ];
      newObj._comps = comps;
      newObj.name = this.userInfo.nickName;
      this.initStorage();
      this.$store.dispatch("index/setServeReportInfo", newObj);
      this.showReport();
    },

    // 格式化内存数据
    initStorage() {
      sessionStorage.removeItem("serveSurplusInfo");
      sessionStorage.removeItem("servePagingInfo");
    },
    // 显示报告
    showReport() {
      this.showReportPage = true;
      this.$parent.showReportPage = true;
    },
    // 显示创建报告
    backAddPage() {
      this.showReportPage = false;
      this.$parent.showReportPage = false;
    },
    back() {
      this.$parent.back();
    },
  },
};
</script>

<style lang="less" scoped>
.personal-ability {
  width: 100%;
  // margin-left: 20px;
  .row-btns {
    display: flex;
    width: 90%;
    margin-bottom: 20px;
    .btn1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d4d4d4;
      margin-right: 20px;
      cursor: pointer;
      .dot {
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background: #ffffff;
        border: 1px solid #c0c4cc;
        margin-right: 10px;
        box-sizing: border-box;
      }
      p {
        font-size: 14px;
        color: #333;
      }
    }
    .is-btn1 {
      background: #f7fbff;
      border: 1px solid #6474c7;
      .dot {
        border: 5px solid #6474c7;
      }
      p {
        color: #6474c7;
      }
    }
  }
  .btns-box {
    margin: 60px 0 0 0;
    .el-button {
      width: 140px;
      height: 50px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border-radius: 25px;
    }
    .btn1 {
      background: #f1f2f1;
      color: #bbbbbb;
    }
  }
}
</style>

