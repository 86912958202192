<template>
  <div class="page-wrapper">
    <myHeader page="03"></myHeader>
    <div class="content-box">
      <p class="title-p">
        2. 案情简介
      </p>
      <el-input
        v-if="introduction.status"
        :ref="`ipt${introduction.id}`"
        @blur="inputonblur(introduction)"
        class="textarea-box"
        :id="`ipt${introduction.id}`"
        type="textarea"
        placeholder=""
        :autosize="{ minRows: 8 }"
        suffix-icon="el-icon-date"
        v-model="introduction.val"
      >
      </el-input>
      <span v-else @click="clickSpan(introduction)">{{
        introduction.val
      }}</span>
      <p class="title-p">
        3. 争议焦点
      </p>
      <el-input
        v-if="dispute.status"
        :ref="`ipt${dispute.id}`"
        @blur="inputonblur(dispute)"
        class="textarea-box"
        :id="`ipt${dispute.id}`"
        type="textarea"
        placeholder=""
        :autosize="{ minRows: 8 }"
        suffix-icon="el-icon-date"
        v-model="dispute.val"
      >
      </el-input>
      <span v-else @click="clickSpan(dispute)">{{
        dispute.val
      }}</span>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  props: {
    pageTitle: String,
  },
  data() {
    return {
      introduction: {
        id: 1,
        val: "案情简介",
        status: false,
      },
      dispute: {
        id: 2,
        val: "争议焦点输入",
        status: false,
      },
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo))._info;
      this.introduction.val = data.content;
      // console.log(data,'任务内容');
    },
    // 输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 
    clickSpan(info) {
      info.status = true;
      this.$nextTick(() => {
        let re = `ipt${info.id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
  },
  updated() {},
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  .content-box {
    margin-top: 120px;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #195AAB;
      margin-bottom: 10px !important;
    }
    ::v-deep .textarea-box {
      .el-textarea__inner {
        font-size: 24px;
        color: #111111;
      }
    }
    span {
      display: block;
      min-height: 400px;
      min-width: 300px;
      font-size: 24px;
      color: #111111;
      text-indent: 2em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
    }
  }
}
</style>