<template>
  <div class="select-wrapper">
    <span class="select-wrapper-title">推荐颜色</span>
    <ul class="list-bg">
      <li class="item picker">
        <img
          class="picker-img"
          src="@/assets/serve-report/colors@2x.png"
          alt=""
        />
        <div class="picker-color">
          <el-color-picker v-model="colorPicker" @change="colorChange"></el-color-picker>
        </div>
      </li>
      <li
        class="item"
        v-for="(item, index) in colorList"
        :key="index"
        :style="{ backgroundColor: item }"
        @click="clickColor(item)"
      ></li>
      <li class="item" @click="clickColor('')">
        <img src="@/assets/serve-report/transparency@2x.png" alt="" />
      </li>
    </ul>
    <div class="upload">
      <!-- <el-upload
        class="upload-wrapper"
        action="#"
        multiple
        :limit="3"
        :show-file-list="false"
        :auto-upload="false"
      > -->
      <el-upload
        class="upload-wrapper"
        action=""
        :show-file-list="false"
        :accept="'image/*'"
        :auto-upload="false"
        :on-change="imgSaveToUrl"
      >
        <el-button plain>上传背景图片</el-button>
      </el-upload>
      <span>
        点击色块或素材即可更改背景。若无发生变化，请检查是否被上层图片所遮盖。
      </span>
    </div>
    <div class="photo-wrapper">
      <span class="photo-wrapper-title">我的背景（建议背景图片尺寸：宽1190像素,高1616像素）</span>
      <ul class="photo-wrapper-list">
        <li
          class="photo-wrapper-item"
          v-for="(item, index) in serveReportInfo.bgImg"
          :key="index"
          @click="clickBgimg(item)"
        >
          <i @click="romImg(index)" class="el-icon-circle-close"></i>
          <el-image style="" :src="item">
            <!--  :preview-src-list="srcList" -->
          </el-image>
        </li>
      </ul>
    </div>
    <!-- <div class="color-picker" v-if="showPicker">
      <el-color-picker v-model="colorPicker"></el-color-picker>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      // 背景色数据源
      colorList: [
        "#FFFFFF",
        "#FFD627",
        "#FFA340",
        "#ED504F",
        "#E84385",
        "#C24AFF",
        "#924BFF",
        "#3B61FF",
        "#3A9CFF",
        "#32D1E7",
        "#44D89D",
        "#97DE6B",
        "#DDDDDD",
        "#AAAAAA",
        "#666666",
        "#000000",
      ],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      colorPicker: "#409EFF",
      showPicker: true,
    };
  },
  methods: {
    clickColor(color) {
      this.$emit("bindChangeColor", color);
    },
    clickBgimg(bgImg) {
      this.$emit("bindChangeBgimg", bgImg);
    },
    colorChange(color){
      console.log(color)
      this.$emit("bindChangeColor", color);
    },
    imgSaveToUrl(event) {
      let stoInfo = JSON.parse(JSON.stringify(this.serveReportInfo));
      let newList = stoInfo.bgImg;
      // 获取上传图片的本地URL，用于上传前的本地预览
      var URL = null;
      if (window.createObjectURL != undefined) {
        // basic
        URL = window.createObjectURL(event.raw);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        URL = window.URL.createObjectURL(event.raw);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        URL = window.webkitURL.createObjectURL(event.raw);
      }
      newList.push(URL);
      stoInfo.bgImg = newList;
      this.$store.dispatch("index/setServeReportInfo", stoInfo);
    },
    romImg(idx) {
      let stoInfo = JSON.parse(JSON.stringify(this.serveReportInfo));
      let newList = stoInfo.bgImg;
      newList.splice(idx,1);
      stoInfo.bgImg = newList;
      this.$store.dispatch("index/setServeReportInfo", stoInfo);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
$color_blue: #217dea;
.select-wrapper {
  &-title {
    display: block;
    font-size: 12px;
    color: #909399;
    padding: 20px 0;
  }
  .list-bg {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 25px;
      height: 25px;
      border: 1px solid #e5e5e5;
      margin-right: 7px;
      margin-bottom: 7px;
      &.picker {
        position: relative;
        .picker-img {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 9;
          pointer-events: none;
        }
        .picker-color {
          width: 25px;
          height: 25px;
          position: absolute;
          left: 0;
          top: 0;
           z-index: 8;
          ::v-deep .el-color-picker__trigger {
            width: 25px;
            height: 25px;
          }
        }
      }
      &:nth-child(9n) {
        margin-right: 0;
        margin-bottom: 0;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .upload {
    width: 100%;
    margin-top: 40px;
    &-wrapper {
      width: 100%;
      ::v-deep .el-upload {
        width: 100%;
        .el-button {
          width: 100%;
          border: 1px solid $color_blue;
          color: $color_blue;
        }
      }
    }
    span {
      color: #c0c4cc;
      font-size: 12px;
      display: block;
      margin-top: 20px;
    }
  }
  .photo-wrapper {
    margin-top: 40px;
    &-title {
      font-size: 12px;
      color: #909399;
      display: block;
      padding: 20px 0;
      border-bottom: 1px solid #dee2e6;
    }
    &-list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .photo-wrapper-item {
        width: 80%;
        position: relative;
        margin-right: 10px;
      i {
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 20px;
        color: #0094ff;
        z-index: 2;
      }
        &:nth-child(3n) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>