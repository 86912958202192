<template>
  <div class="page-wrapper">
    <myHeader :title="`${page}.服务客户数据信息`"></myHeader>
    <div class="trend-box">
      <p class="title">{{page}}.4服务客户性质结构</p>
      <div class="myCharts3" style="width: 1030px; height: 600px"></div>
      <p class="foot-p">
        <!-- 从上面的客户性质分类情况可以看到，律师服务给国企、民企、外企、事业单位、政府机构和其他团体的服务数量情况。 -->
        从上面的客户性质分类情况可以看到，律师服务给各个机构、团体的服务数量情况。
      </p>
    </div>
    <div class="four-sides-box">
      <p class="title">{{page}}.5律师业务类型数量可视化</p>
      <div class="myCharts4" style="width: 1030px; height: 500px"></div>
    </div>
    <myFooter pageNum="05"></myFooter>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    myFooter,
    VueDragResize,
  },
  data() {
    return {
      page: 0,
      natureList: [], // 客户性质
      natureText: "",
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    setNatureInfo() {
      // console.log(this.serveReportInfo._info,"客户性质");
      let natureInfo = JSON.parse(JSON.stringify(this.serveReportInfo._info))["服务客户"][
        "natureWithCount"
      ];
      let echartData = [];
      for (let key in natureInfo) {
        echartData.push({
          value: natureInfo[key],
          name: key,
        });
      }
      this.natureList = echartData;
      let myCharts = echarts.init(document.querySelector(".myCharts3"));
      let options = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        tooltip: {
          show: false,
          trigger: "item",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "65%"],
            data: echartData,
            // label: {},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    fontSize: 22,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 50,
                length2: 70,
                lineStyle: {
                  // color: '#333'
                },
              },
            },
            label: {
              normal: {
                formatter: " {b}：{c} \n\n",
                borderWidth: 20,
                borderRadius: 4,
                padding: [0, -70],
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 12,
                    lineHeight: 20,
                  },
                  b: {
                    fontSize: 12,
                    lineHeight: 20,
                    color: "#333",
                  },
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    setCharts2() {
      // console.log(this.serveReportInfo._info,'律师业务类型');
      // let natureInfo = JSON.parse(JSON.stringify(this.serveReportInfo._info))[
      //   "customerList"
      // ];
      // this.myArr = [];
      let info = {
        '常年法律顾问': {
          name: "常年法律顾问",
          num: 0,
        },
        '专项服务': {
          name: "专项服务",
          num: 0,
        },
        '案件代理': {
          name: "案件代理",
          num: 0,
        },
      };
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))["服务客户"][
        "customerList"
      ];
      data.forEach((item, index) => {
        if (!item.businessType) return;
        // if (info[item.businessType]) {
          // console.log(item.businessType);
          info[item.businessType].num++;
        // } else {
        //   info[item.businessType] = {
        //     name: item.businessType,
        //     num: 1,
        //   };
        // }
      });
      let echartData = [];
      let list2 = [];
      for (let key in info) {
        echartData.push({
          max: info[key].num,
          name: key,
          // name: `${this.$getNature(key)}（${natureInfo[key]}个）`,
        });
        list2.push(info[key].num)
      }
      // console.log(echartData);
      this.natureList = echartData;
      let myCharts = echarts.init(document.querySelector(".myCharts4"));
      let options = {
        title: {
          text: "服务客户数（家）",
          textStyle: {
            color: "#818182",
          },
        },
        radar: {
          // shape: 'circle',
          indicator: echartData,
          splitArea: {
            show: false,
            areaStyle: {
              color: "rgba(255,0,0,0)", // 图表背景的颜色
            },
          },
          name: {
            formatter: "{value}",
            // 字体样式
            textStyle: {
              fontSize: 20,
              color: "#818182",
            },
          },
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: list2,
                name: "Allocated Budget",
                label: {
                  normal: {
                    show: true,
                    textStyle: {
                      //更改数值样式
                      color: "#818182",
                      fontSize: 16,
                    },
                    formatter: function (params) {
                      return params.value;
                    },
                  },
                },
                areaStyle: {
                  normal: {
                    color: "#4397d5", // 图表中各个图区域的颜色
                  },
                },
                lineStyle: {
                  color: "rgba(255,255,255,0)", // 图表中各个图区域的边框线颜色
                },
              },
            ],
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.setNatureInfo();
    this.setCharts2();
    this.page = this.serveReportInfo._info['目录']['服务客户'].page;
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  padding: 0;
  padding-bottom: 80px;
  // background: #f7f8fa;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .trend-box {
    position: relative;
    padding: 20px 80px;
    background: #f7f8fa;
    margin-top: 20px;
    .foot-p {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #111111;
      font-size: 16px;
      white-space: nowrap;
    }
  }
  .four-sides-box {
    padding: 50px 80px;
    .myCharts2 {
      margin-top: 50px;
    }
  }
}
</style>