<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <div class="head-box">
      <div class="img-box">
        <!-- <p>律所标志</p> -->
      </div>
      <div class="img-box">
        <!-- <p>客户标志</p> -->
      </div>
      <div class="val-box">
        <p>© 版权声明</p>
        <!-- <el-input
          class="textarea-box"
          type="textarea"
          :autosize="{ minRows: 2 }"
          placeholder="请输入内容"
          suffix-icon="el-icon-date"
          v-model="copyrightVal"
        >
        </el-input> -->
        <span>
          本报告样式由法法智办（上海）科技有限公司提供，报告内容属于{{serveReport.name}}（个人）和法法智办（上海）科技有限公司所有。未经{{serveReport.name}}（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合
        </span>
      </div>
    </div>
    <div class="name-box">
      <div class="name">
        <div class="input-box" v-if="nameStatus">
          <input v-model="serveReport.name" ref="nameIpt" @blur="inputonblur" />
        </div>
        <div v-else class="name-span-box">
          <span @click="clickName" >{{ serveReport.name }}</span>
        </div>
        <p>律师</p>
        <span>｜个人能力服务报告</span>
      </div>
      <p>Personal Service Report</p>
    </div>
    <!-- <div style="height: 1030px;"></div> -->
    <div class="footer">
      <div class="left-box">
        <div class="row-box">
          <span class="label">报告人：{{serveReport.name}}</span>
        </div>
        <div class="row-box">
          <span class="label">报告日期：</span>
          <div class="input-box" v-if="dateStatus">
            <input v-model="reportDate" ref="dateIpt" @blur="inputonblur2" />
          </div>
          <div v-else class="date-span-box">
            <span @click="clickDate" >{{ reportDate }}</span>
          </div>
        </div>
      </div>
      <div class="right-box">
        IN
        <div class="input-box" v-if="yearStatus">
            <input v-model="yearVal" ref="yearIpt" @blur="inputonblur3" />
          </div>
          <div v-else class="year-span-box">
            <span @click="clickYear" >{{ yearVal }}</span>
          </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入背景图片路径
// import defaultBgImg from "@/assets/serve-report/pages/page_bg@2x.png";
import defaultBgImg from "@/assets/serve-report/pages/page-1.png";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      copyrightVal:
        "本报告样式由法法智办（上海）科技有限公司提供，报告内容属于**（个人）和法法智办（上海）科技有限公司所有。未经**（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合",
      serveReport: {}, // 律师通用数据
      reportDate: this.$getDate(6),
      yearVal: new Date().getFullYear(),
      nameStatus: false, // 名称输入框状态
      dateStatus: false, // 日期输入框状态
      yearStatus: false, // 年份输入框状态
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  watch: {
    nameStatus() {
      if (this.nameStatus) {
        this.$nextTick(() => {
          this.$refs.nameIpt.focus();
        })
      }
    },
    dateStatus() {
      if (this.dateStatus) {
        this.$nextTick(() => {
          this.$refs.dateIpt.focus();
        })
      }
    },
    yearStatus() {
      if (this.yearStatus) {
        this.$nextTick(() => {
          this.$refs.yearIpt.focus();
        })
      }
    },
  },
  methods: {
    // 点击名称文本
    clickName() {
      this.nameStatus = true;
    },
    // 律师名称输入框失焦
    inputonblur() {
      this.nameStatus = false;
      let newObj = JSON.parse(JSON.stringify(this.serveReport))
      this.$store.dispatch("index/setServeReportInfo", newObj);
    },
    // 点击日期
    clickDate() {
      this.dateStatus = true;
    },
    // 日期输入框失焦
    inputonblur2() {
      console.log('日期');
      this.dateStatus = false;
    },
    // 点击年份
    clickYear() {
      this.yearStatus = true;
    },
    // 年份输入框失焦
    inputonblur3() {
      this.yearStatus = false;
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.$nextTick(()=> {
      this.serveReport = JSON.parse(JSON.stringify(this.serveReportInfo))
    })
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .head-box {
    display: flex;
    align-items: center;
    margin-top: 40px;
    .img-box {
      width: 200px;
      height: 72px;
      line-height: 72px;
      text-align: center;
      // background: #f7f8fa;
      // border: 1px solid #e0e0e0;
      margin-right: 20px;
      p {
        font-size: 12px;
        color: #c0c4cc;
      }
    }
    .val-box {
      width: 510px;
      margin-left: 80px;
      span {
        width: 510px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
  // 自适应输入框
  .name-box {
    margin-top: 50px;
    .name {
      display: flex;
      align-items: center;
      .input-box {
        height: 88px;
        span {
          font-size: 64px;
          font-weight: bold;
          color: #0069a6;
        }
        input {
          width: 300px;
          font-size: 64px;
          font-weight: bold;
          color: #0069a6;
        }
      }
      .name-span-box {
        height: 115px;
        max-width: 380px;
        span {
          display: inline-block;
          min-height: 40px;
          min-width: 100px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-all;
          font-size: 64px;
          font-weight: bold;
          color: #0069a6;
        }
      }
      p {
        font-size: 64px;
        font-weight: bold;
        color: #0069a6;
      }
      span {
        font-size: 64px;
        color: #0069a6;
        white-space: nowrap;
      }
    }
    p {
      font-size: 32px;
      color: #0069a6;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 80px;
    width: 100%;
    padding: 0 80px;
    .left-box {
      display: flex;
      flex-direction: column;
      .row-box {
        display: flex;
        .label {
          font-size: 24px;
          font-weight: bold;
          color: #0069a6;
        }
        .input-box {
          label {
            font-size: 24px;
            font-weight: bold;
          }
          input {
            font-size: 24px;
            font-weight: bold;
            color: #0069a6;
          }
        }
      .date-span-box {
        span {
          display: inline-block;
          min-height: 32px;
          min-width: 100px;
          font-size: 24px;
            font-weight: bold;
            color: #0069a6;
        }
      }
      }
    }
    .right-box {
      display: flex;
      font-size: 120px;
      font-weight: bold;
      font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
      color: #FFFFFF;
      .input-box {
        margin-left: 20px;
          // label {
          //   font-size: 120px;
          //   font-weight: bold;
          // }
          input {
            width: 400px;
            font-size: 120px;
            font-weight: bold;
            font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
            color: #FFFFFF;
            background-color: transparent;
          }
        }
        .year-span-box {
          span {
            display: inline-block;
            min-height: 40px;
            min-width: 100px;
            font-size: 120px;
            font-weight: bold;
          }
        }
    }
  }
  .let-img-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
  }
}
</style>