<template>
  <div class="page-wrapper">
    <myHeader :title="`${page}.服务客户数据信息`"></myHeader>
    <div class="trend-box">
      <p class="title">{{page}}.2服务客户量趋势可视化</p>
      <div class="myCharts" style="width: 1030px; height: 660px"></div>
      <p class="foot-p">
        截止本报稿生成时间，本律师服务客户的总量为
        {{ clientTotal }} 家。在过去几年的变化呈上图所示。
      </p>
    </div>
    <div class="map-box">
      <p class="title">{{page}}.3主要客户地域分布</p>
      <div class="echarts-box">
        <visualizationChart v-if="chinaMapShow" :chartOption="companyOption"></visualizationChart>
        <div class="myCharts2" style="width: 465px; height: 500px"></div>
      </div>
    </div>
    <myFooter pageNum="04"></myFooter>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
// 引入客户分布地区可视化
import visualizationChart from "./components/visualizationChart.vue";
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import VueDragResize from "vue-drag-resize"
var provincesText = [
  "上海",
  "河北",
  "山西",
  "内蒙古",
  "辽宁",
  "吉林",
  "黑龙江",
  "江苏",
  "浙江",
  "安徽",
  "福建",
  "江西",
  "山东",
  "河南",
  "湖北",
  "湖南",
  "广东",
  "广西",
  "海南",
  "四川",
  "贵州",
  "云南",
  "西藏",
  "陕西",
  "甘肃",
  "青海",
  "宁夏",
  "新疆",
  "北京",
  "天津",
  "重庆",
  "香港",
  "澳门",
  "台湾",
];
export default {
  components: {
    myHeader,
    myFooter,
    visualizationChart,
    VueDragResize,
  },
  data() {
    return {
      page: 0,
      myArr: [], // 客户量趋势数组
      clientTotal: 0, // 客户总数
      mapInfo: {}, // 地区客户可视化数据
      companyOption: {}, // 企业图表参数
      companyData: [], // 企业series数据
      chinaMapShow: true,
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      this.myArr = JSON.parse(JSON.stringify(this.serveReportInfo._info))[
        "客户量趋势"
      ];
      console.log(this.myArr,'客户量趋势');
      // return
      let echartDateList = []; // 图表x轴数据
      let echartSeriesList = []; // 图表数据
      this.myArr.forEach((item) => {
        echartDateList.push(item.time);
        echartSeriesList.push(item.value);
        this.clientTotal += item.value;
      });
      let myCharts = echarts.init(document.querySelector(".myCharts"));
      let options = {
        grid: {
          x: 50,
          y: 40,
          x2: 0,
          y2: 110,
        },
        tooltip: {
          axisPointer: {
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          splitLine: { show: false },
          type: "category",
          data: echartDateList,
          axisLabel: {
            textStyle: {
              fontSize: 16,
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: { show: false },
          axisLabel: {
            textStyle: {
              fontSize: 16,
            },
          },
        },
        series: [
          {
            data: echartSeriesList,
            type: "line",
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  textStyle: {
                    fontSize: 16,
                  },
                },
                lineStyle: {
                  width: 2,
                  color: "#fea059",
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    // 客户分布柱状图
    setCharts2() {
      // console.log(this.serveReportInfo._info,'客户分布');
      let charts2Info = JSON.parse(JSON.stringify(this.serveReportInfo._info))["服务客户"].provinceWithCount;
      let companyData = [];
      for (let key in charts2Info) {
        let _findIndex = provincesText.findIndex((item, index) => {
          return key.includes(item);
        });
        let newName = key;
        if (_findIndex !== -1) {
          newName = provincesText[_findIndex];
        }
        companyData.push({
          name: newName,
          value: charts2Info[key],
        });
      }
      companyData.sort((a,b) => {
        return a.value - b.value
      })
      companyData = companyData.slice(-10);
      // console.log(companyData);
      let echartDateList = []; // 图表x轴数据
      let echartSeriesList = []; // 图表数据
      companyData.forEach((item) => {
        echartDateList.push(item.name);
        echartSeriesList.push(item.value);
      });
      let myCharts = echarts.init(document.querySelector(".myCharts2"));
      let options = {
        grid: {
          show: false,
          x: 80,
          y: 130,
          x2: 20,
          y2: 100,
        },
        tooltip: {
          axisPointer: {
            label: {
              backgroundColor: "#6a7985",
            },
          },
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          splitLine: { show: false },
          type: "value",
          axisLabel: {
            textStyle: {
              fontSize: 16,
            },
          },
          axisTick: {
              show: false,
              // lineStyle: {
              //   type: 'solid', //y轴分割线类型
              //   color: 'rgba(102,102,102,0.9)',
              //   width: 2.5,
              // },
            },
        },
        yAxis: {
          type: "category",
          splitLine: { show: false },
          axisLabel: {
            textStyle: {
              fontSize: 16,
            },
          },
          axisTick: {show: false},
          data: echartDateList,
        },
        series: [
          {
            data: echartSeriesList,
            type: "bar",
            barWidth: "40%",
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  textStyle: {
                    fontSize: 16,
                  },
                  position: "right",
                },
                color: "#0999fa",
                barBorderRadius: [10,10,10,10],
                emphasis: {
                  focus: 'series'
                },
              },
            },
          },
        ],
      };
      myCharts.setOption(options, true);
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
    this.setCharts2();
    this.page = this.serveReportInfo._info['目录']['服务客户'].page;
    // this.companyView();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  padding: 0;
  padding-bottom: 80px;
  .trend-box {
    position: relative;
    padding: 20px 80px;
    background: #f7f8fa;
    margin-top: 20px;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }
    .foot-p {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #111111;
      font-size: 16px;
      white-space: nowrap;
    }
  }
  .map-box {
    padding: 80px;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }
    .echarts-box {
      display: flex;
      width: 100%;
    }
  }
}
</style>