<template>
  <div class="page-wrapper">
    <myHeader page="05"></myHeader>
    <div class="content-box">
      <p class="title-p">
        5. 案件结果分析
      </p>
      <el-input
        v-if="result.status"
        :ref="`ipt${result.id}`"
        @blur="inputonblur(result)"
        class="textarea-box"
        :id="`ipt${result.id}`"
        type="textarea"
        placeholder=""
        :autosize="{ minRows: 14 }"
        suffix-icon="el-icon-date"
        v-model="result.val"
      >
      </el-input>
      <span v-else @click="clickSpan(result)">{{result.val}}</span>
      <p class="title-p">
        6. 审理机构记录
      </p>
      <el-row class="head-row" type="flex">
        <el-col v-for="(item,index) in headRow" :key="index" :span="item.span">
          <p>{{item.name}}</p>
        </el-col>
      </el-row>
      <div class="content-row-box">
        <el-row v-for="(item2,index2) in myArr" :key="index2" type="flex">
          <el-col :span="5">{{item2.name}}</el-col>
          <el-col :span="10">{{item2.hearCaseOrganization}}</el-col>
          <el-col :span="9">
            <el-input
              v-if="item2['法官'].status"
               :ref="`ipt${item2['法官'].id}`"
              @blur="inputonblur2(item2,'法官',index2)"
              class="textarea-box"
              :id="`ipt${item2['法官'].id}`"
              type="textarea"
              placeholder=""
              :autosize="{ minRows: 1 }"
              suffix-icon="el-icon-date"
              v-model="item2['法官'].val"
            >
            </el-input>
            <span v-else @click="clickSpan2(item2,'法官',index2)" style="text-indent: 0 !important; color: #333333;">{{item2['法官'].val}}</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      result: {
        id: 1,
        val: "结果",
        status: false,
      },
      headRow: [
        {name: "案件程序", span: 5,},
        {name: "审理机构名称", span: 10,},
        {name: "主审法官", span: 9,},
      ],
      myArr: [{'法官':{status: false}}],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      let info = JSON.parse(JSON.stringify(this.serveReportInfo))._info;
      console.log(info);
      this.result.val = info.result;
      let list = info.results;
      list.map((item,index) => {
        return item['法官'] = {
          id: index,
          val: "",
          status: false,
        }
      })
      this.myArr = list;
      // this.myArr.push({content:'',hearCaseOrganization:'',name:'','法官2':{id: 2, val: '',status: false}})
      console.log(this.myArr,'审理机构');
    },
    // 输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 
    clickSpan(info) {
      info.status = true;
      this.$nextTick(() => {
        let re = `ipt${info.id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 输入框失焦
    inputonblur2(row,str,idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row)
    },
    // 
    clickSpan2(row,str,idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row)
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  .content-box {
    margin-top: 100px;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #195AAB;
      margin-bottom: 10px !important;
    }
    ::v-deep .textarea-box {
      .el-textarea__inner {
        font-size: 20px;
        color: #111111;
      }
    }
    span {
      display: block;
      min-height: 600px;
      font-size: 20px;
      color: #111111;
      text-indent: 2em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
    }
    .head-row {
      background: #EEEEEE;
      .el-col {
        p {
          margin: 16px !important;
        }
      }
    }
      .content-row-box {
        .el-row {
          background: #EBF4FF;
          ::v-deep .el-col {
            display: flex;
            align-items: center;
            color: #333;
            padding: 16px;
            box-sizing: border-box;
            span {
              width: 100%;
              min-height: 30px;
              color: #333 !important;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              word-break: break-all;
            }
            .textarea-box {
              .el-textarea__inner {
                background-color: transparent;
              }
            }
          }
        }
        & .el-row:nth-child(odd) {
          background: #fff;
        }
      }
  }
}
</style>