<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <div class="content-box">
      <div class="left-box">
        <div class="card-box" v-for="(item,index) in catalogList1" :key="index">
          <p>{{item.name}}</p>
          <div class="min-card" v-for="(item2,index2) in item.list" :key="index2">
            <p>{{item2}}</p>
          </div>
        </div>
      </div>
      <div class="right-box">
        <div class="card-box" v-for="(item,index) in catalogList2" :key="index">
          <p>{{item.name}}</p>
          <div class="min-card" v-for="(item2,index2) in item.list" :key="index2">
            <p>{{item2}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入背景图片路径
// import defaultBgImg from "@/assets/serve-report/pages/mulu@2x.png";
import defaultBgImg from "@/assets/serve-report/pages/page-2.png";
import VueDragResize from "vue-drag-resize"
import { mapGetters } from "vuex";
export default {
  components: {
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      catalogList1: [],
      catalogList2: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      // console.log(this.serveReportInfo);
      let data = JSON.parse(JSON.stringify(this.serveReportInfo))._info[
        "目录"
      ];
      let pageList = [];
      for (let key in data) {
        pageList.push(data[key])
      }
      pageList.sort((a,b) => {
        return a.page - b.page
      })
      pageList.forEach((item,index) => {
        if (index < 4) {
          this.catalogList1.push(item)
        } else {
          this.catalogList2.push(item)
        }
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }},
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    display: flex;
    justify-content: space-between;
    margin-top: 450px;
    .left-box,
    .right-box {
      width: 40%;
      .card-box {
        margin-bottom: 80px;
        p {
          font-size: 24px;
          color: #FFFFFF;
        }
        .min-card {
          margin-left: 20px;
          p {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
</style>