<template>
  <div class="select-wrapper">
    <span class="select-wrapper-title">数据图</span>
    <ul class="list-chart">
      <li class="item" v-for="item in 12" :key="item"></li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  &-title {
    display: block;
    font-size: 12px;
    color: #909399;
    padding: 20px 0;
  }
  .list-chart {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 90px;
      height: 90px;
      background-color: #f8f8fb;
      margin-right: 10px;
      margin-top: 10px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(-n + 3) {
        margin-top: 0;
      }
    }
  }
}
</style>