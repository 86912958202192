<template>
  <div class="page-wrapper">
    <myHeader page="03"></myHeader>
    <div class="content-box">
      <p class="title-p">
        客户权益与使用情况
      </p>
      <p class="min-title-p">根据贵我双方的服务协议约定，贵公司可享受的常年劳动法律顾问服务的系列权益和使用情况如下：</p>
      <el-row class="head-row" type="flex">
          <el-col v-for="(item,index) in headRow" :key="index" :span="item.span">
            <p>{{item.name}}</p>
          </el-col>
        </el-row>
        <div class="content-row-box">
          <el-row v-for="(item2,index2) in myArr" :key="index2">
            <el-col :span="12">
              <p>{{item2.assignmentTypeName}}</p>
            </el-col>
            <el-col :span="6">
              <div class="input-box" v-if="item2['总数'].status">
                <input v-model="item2['总数'].val" :id="`ipt${item2['总数'].id}`" :ref="`ipt${item2['总数'].id}`" @blur="inputonblur(item2,'总数',index2)" />
              </div>
              <div v-else class="name-span-box">
                <span @click="clickSpan(item2,'总数',index2)" >{{ item2['总数'].val }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="input-box" v-if="item2['已使用'].status">
                <input v-model="item2['已使用'].val" :id="`ipt${item2['已使用'].id}`" :ref="`ipt${item2['已使用'].id}`" @blur="inputonblur(item2,'已使用',index2)" />
              </div>
              <div v-else class="name-span-box">
                <span @click="clickSpan(item2,'已使用',index2)" >{{ item2['已使用'].val }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  props: {
    pageTitle: String,
  },
  data() {
    return {
      headRow: [
        {name: "客户权益类", span: 12},
        {name: "服务数量/次", span: 6},
        {name: "使用情况/次", span: 6},
      ],
      myArr: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      let data = JSON.parse(JSON.stringify(this.serveReportInfo))._info['套餐'].details;
      // this.introduction.val = data.content;
      console.log(data,'任务内容');
      let id = 0;
      data.map(item => {
        let info = {
          id: id++,
          val: item.amount,
          status: false,
        }
        let info2 = {
          id: id++,
          val: item.usedCount,
          status: false,
        }
        item['总数'] = info;
        item['已使用'] = info2;
        return item;
      })
      this.myArr = data;
    },
    // 输入框失焦
    inputonblur(row,str,idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row)
    },
    // 
    clickSpan(row,str,idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row)
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
  },
  updated() {},
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  .content-box {
    margin-top: 240px;
    .title-p {
      font-size: 56px;
      font-weight: 600;
      color: #196A73;
      margin-bottom: 16px !important;
    }
    .min-title-p {
      font-size: 20px;
      color: #111111;
      margin-bottom: 80px !important;
    }
    ::v-deep .textarea-box {
      .el-textarea__inner {
        font-size: 24px;
        color: #111111;
      }
    }
    .head-row {
      height: 56px;
      background: #196A73;
      .el-col {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 40px;
        p {
          font-size: 16px;
          color: #FFFFFF;
        }
      }
    }
      .content-row-box {
        .el-row {
          height: 80px;
          ::v-deep .el-col {
            display: flex;
            align-items: center;
            height: 100%;
            padding-left: 40px;
            font-size: 16px;
            color: #333333;
            span {
              display: block;
              min-width: 200px;
              min-height: 32px;
            }
          }
        }
      }
    // span {
    //   display: block;
    //   min-height: 400px;
    //   font-size: 24px;
    //   color: #111111;
    //   text-indent: 2em !important;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: normal;
    //   word-break: break-all;
    // }
  }
}
</style>