<template>
  <div class="control-wrapper">
    <!-- <ul class="step-control" v-if="controlType != 1">
      <li class="step-item" @click="clickUndo">
        <img src="@/assets/serve-report/backout@2x.png" alt="" />
      </li>
    </ul> -->
    <div class="reset" @click="clickHoming">
      <i class="el-icon-refresh-right"></i>
      <span>复位</span>
    </div>
    <ul class="zoom" :class="controlType == 1 ? 'zoom-preview' : 'zoom-edit'">
      <li @click="clickZoom(1)">
        <i class="el-icon-zoom-in"></i>
      </li>
      <li>
        <span>{{ scalePercent }}%</span>
      </li>
      <li @click="clickZoom(2)">
        <i class="el-icon-zoom-out"></i>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    scalePercent: {
      type: [String, Number],
      default: "",
    },
    controlType: {
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickHoming() {
      this.$emit("handleHoming");
    },
    clickZoom(e) {
      this.$emit("handleZoom", e);
    },
    clickUndo(){
      this.$emit("handleUnfo")
    }
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.control-wrapper {
  padding-top: 30px;
  width: 60px;
  height: calc(100vh - 300px);
  background-color: #fff;
  position: relative;
  z-index: 11;
  .step-control {
    .step-item {
      margin-bottom: 40px;
      text-align: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .reset {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 12px;
    }
  }
  .zoom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    &.zoom-preview {
      bottom: 120px;
    }
    &.zoom-edit {
      bottom: 30px;
    }
    li {
      width: 100%;
      text-align: center;
      i {
        color: #909399;
        cursor: pointer;
      }
      span {
        display: inline-block;
        margin: 25px 0;
      }
    }
  }
}
</style>