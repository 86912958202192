<template>
  <div class="page-wrapper">
    <myHeader :title="`${page}.客户总体评价`"></myHeader>
    <div class="content-box">
      <p class="title">{{page}}.1 客户满意度</p>
      <div class="form-box">
        <el-row class="head-row" type="flex">
          <el-col
            v-for="(item, index) in headRow"
            :key="index"
            :span="item.span"
          >
            <p>{{ item.name }}</p>
          </el-col>
        </el-row>
        <div class="content-row-box">
          <el-row v-for="(item2, index2) in myArr" :key="index2">
            <el-col :span="16">
              <el-input
                v-if="item2.name.status"
                :ref="`ipt${item2.name.id}`"
                @blur="inputonblur(item2, 'name', index2)"
                class="textarea-box"
                :id="`ipt${item2.name.id}`"
                type="textarea"
                placeholder="个人介绍"
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2.name.val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2, 'name', index2)">{{
                item2.name.val
              }}</span>
            </el-col>
            <el-col :span="8">
              <div class="input-box" v-if="item2.num.status">
                <input
                  v-model="item2.num.val"
                  :id="`ipt${item2.num.id}`"
                  :ref="`ipt${item2.num.id}`"
                  @blur="inputonblur(item2, 'num', index2)"
                />
              </div>
              <div v-else class="name-span-box">
                <span @click="clickSpan(item2, 'num', index2)">{{
                  item2.num.val
                }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="comment-box">
      <p class="title">{{page}}.2 客户评语</p>
      <div
        class="card-box"
        v-for="(item3, index3) in commentList"
        :key="index3"
      >
        <div class="name-box">
          <div class="left-box">
            <span>客户名称：</span>
            <div class="input-box" v-if="item3.name.status">
              <input
                v-model="item3.name.val"
                :id="`ipt${item3.name.id}`"
                :ref="`ipt${item3.name.id}`"
                @blur="inputonblur2(item3, 'name', index3)"
              />
            </div>
            <div v-else class="name-span-box">
              <span @click="clickSpan2(item3, 'name', index3)">{{
                item3.name.val
              }}</span>
            </div>
          </div>
          <div class="right-box">
            <span>业务类型：</span>
            <div class="input-box" v-if="item3.type.status">
              <input
                v-model="item3.type.val"
                :id="`ipt${item3.type.id}`"
                :ref="`ipt${item3.type.id}`"
                @blur="inputonblur2(item3, 'type', index3)"
              />
            </div>
            <div v-else class="name-span-box">
              <span @click="clickSpan2(item3, 'type', index3)">{{
                item3.type.val
              }}</span>
            </div>
          </div>
        </div>
        <div class="val-box">
          <p>客户评语：</p>
          <el-input
            v-if="item3.evaluate.status"
            :ref="`ipt${item3.evaluate.id}`"
            @blur="inputonblur2(item3, 'evaluate', index3)"
            class="textarea-box"
            :id="`ipt${item3.evaluate.id}`"
            type="textarea"
            placeholder="个人介绍"
            :autosize="{ minRows: 3 }"
            suffix-icon="el-icon-date"
            v-model="item3.evaluate.val"
          >
          </el-input>
          <span v-else @click="clickSpan2(item3, 'evaluate', index3)">{{
            item3.evaluate.val
          }}</span>
        </div>
      </div>
    </div>
    <myFooter pageNum="13"></myFooter>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
let id = 0;
export default {
  components: {
    myHeader,
    myFooter,
    VueDragResize,
  },
  data() {
    return {
      page: 0,
      headRow: [
        { name: "业务类型", span: 16 },
        { name: "服务客户数", span: 8 },
      ],
      myArr: [
        {
          name: {
            id: 1,
            val: "年度顾问",
            status: false,
          },
          num: {
            id: 2,
            val: "10",
            status: false,
          },
        },
        {
          name: {
            id: 3,
            val: "案件代理",
            status: false,
          },
          num: {
            id: 4,
            val: "10",
            status: false,
          },
        },
        {
          name: {
            id: 5,
            val: "专项服务",
            status: false,
          },
          num: {
            id: 6,
            val: "10",
            status: false,
          },
        },
      ], // 客户数据
      commentList: [
        {
          name: {
            id: id++,
            val: "输入客户名称",
            status: false,
          },
          type: {
            id: id++,
            val: "输入业务类型",
            status: false,
          },
          evaluate: {
            id: id++,
            val: "输入客户评语",
            status: false,
          },
        },
        {
          name: {
            id: id++,
            val: "输入客户名称",
            status: false,
          },
          type: {
            id: id++,
            val: "输入业务类型",
            status: false,
          },
          evaluate: {
            id: id++,
            val: "输入客户评语",
            status: false,
          },
        },
        {
          name: {
            id: id++,
            val: "输入客户名称",
            status: false,
          },
          type: {
            id: id++,
            val: "输入业务类型",
            status: false,
          },
          evaluate: {
            id: id++,
            val: "输入客户评语",
            status: false,
          },
        },
        {
          name: {
            id: id++,
            val: "输入客户名称",
            status: false,
          },
          type: {
            id: id++,
            val: "输入业务类型",
            status: false,
          },
          evaluate: {
            id: id++,
            val: "输入客户评语",
            status: false,
          },
        },
      ],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      // this.myArr = [];
      let info = {};
      let data = JSON.parse(JSON.stringify(this.serveReportInfo._info))[
        "客户表格"
      ];
      if (!data) return
      this.myArr = [];
      data.forEach((item, index) => {
        if (!item.businessType) return;
        if (info[item.businessType]) {
          info[item.businessType].num++;
        } else {
          info[item.businessType] = {
            name: item.businessType,
            num: 1,
          };
        }
      });
      // console.log(info);
      let tol = 0;
      for (let k in info) {
        tol++;
        this.myArr.push({
          name: {
            id: "业务类型" + tol,
            val: info[k].name,
            status: false,
          },
          num: {
            id: "服务客户数" + tol,
            val: info[k].num,
            status: false,
          },
        });
      }
      // console.log(this.myArr);
    },
    // 输入框失焦
    inputonblur(row, str, idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row);
    },
    //
    clickSpan(row, str, idx) {
      // console.log(row, str, idx);
      row[str].status = true;
      this.$set(this.myArr, idx, row);
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`;
        document.getElementById(`${re}`).focus();
      });
    },
    // 输入框失焦
    inputonblur2(row, str, idx) {
      row[str].status = false;
      this.$set(this.commentList, idx, row);
    },
    //
    clickSpan2(row, str, idx) {
      // console.log(row, str, idx);
      row[str].status = true;
      this.$set(this.commentList, idx, row);
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`;
        document.getElementById(`${re}`).focus();
      });
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
    this.page = this.serveReportInfo._info['目录']['客户满意度'].page;
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  padding: 0;
  padding-bottom: 80px;
  .title {
    font-size: 24px;
    font-weight: bold;
    font-family: HarmonyOS_Sans_SC_Bold;
    color: #333333;
  }
  .content-box {
    padding: 20px 80px;
    box-sizing: border-box;
    .form-box {
      margin-top: 20px;
      .content-row-box {
        // min-height: 300px;
        .el-row {
          ::v-deep .el-col {
            .textarea-box {
              .el-textarea__inner {
                background-color: transparent;
              }
            }
            span {
              display: block;
              min-height: 24px;
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .comment-box {
    margin-top: 20px;
    padding: 40px 80px 80px;
    box-sizing: border-box;
    .card-box {
      padding: 24px 40px;
      border-left: 6px solid #2b95d3;
      margin-bottom: 20px;
      background: #f7f8fa;
      box-sizing: border-box;
      .name-box {
        display: flex;
        justify-content: space-between;
        .left-box,
        .right-box {
          display: flex;
          font-size: 16px;
          font-weight: bold;
          color: #111111;
          .input-box {
            input {
              width: 200px;
              font-size: 16px;
              font-weight: bold;
              color: #111111;
            }
          }
        }
      }
      .val-box {
        p {
          font-size: 16px;
          font-weight: bold;
          color: #111111;
        }
        span {
          display: block;
          min-height: 70px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-all;
          font-size: 16px;
          color: #909399;
        }
      }
    }
    & .card-box:nth-child(odd) {
      border-left: 6px solid #acffd8;
    }
  }
}
</style>