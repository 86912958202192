<template>
  <div class="page-wrapper">
    <myHeader></myHeader>
    <div class="content-box">
      <p class="title-p">6. 项目总结</p>
      <p class="title-p2">客户特点</p>
      <el-input
          v-if="myInfo['客户特点'].status"
          :ref="`ipt${myInfo['客户特点'].id}`"
          @blur="inputonblur(myInfo['客户特点'])"
          class="textarea-box"
          :id="`ipt${myInfo['客户特点'].id}`"
          type="textarea"
          placeholder=""
          :autosize="{ minRows: 6 }"
          suffix-icon="el-icon-date"
          v-model="myInfo['客户特点'].val"
        >
        </el-input>
        <span v-else class="intro-span" @click="clickSpan(myInfo['客户特点'])">{{myInfo['客户特点'].val}}</span>
      <p class="title-p2">1.法律适用</p>
      <el-input
          v-if="myInfo['法律适用'].status"
          :ref="`ipt${myInfo['法律适用'].id}`"
          @blur="inputonblur(myInfo['法律适用'])"
          class="textarea-box"
          :id="`ipt${myInfo['法律适用'].id}`"
          type="textarea"
          placeholder=""
          :autosize="{ minRows: 6 }"
          suffix-icon="el-icon-date"
          v-model="myInfo['法律适用'].val"
        >
        </el-input>
        <span v-else class="intro-span" @click="clickSpan(myInfo['法律适用'])">{{myInfo['法律适用'].val}}</span>
      <p class="title-p2">2.客户沟通</p>
      <el-input
          v-if="myInfo['客户沟通'].status"
          :ref="`ipt${myInfo['客户沟通'].id}`"
          @blur="inputonblur(myInfo['客户沟通'])"
          class="textarea-box"
          :id="`ipt${myInfo['客户沟通'].id}`"
          type="textarea"
          placeholder=""
          :autosize="{ minRows: 6 }"
          suffix-icon="el-icon-date"
          v-model="myInfo['客户沟通'].val"
        >
        </el-input>
        <span v-else class="intro-span" @click="clickSpan(myInfo['客户沟通'])">{{myInfo['客户沟通'].val}}</span>
      <p class="title-p2">3.项目变数</p>
      <el-input
          v-if="myInfo['项目变数'].status"
          :ref="`ipt${myInfo['项目变数'].id}`"
          @blur="inputonblur(myInfo['项目变数'])"
          class="textarea-box"
          :id="`ipt${myInfo['项目变数'].id}`"
          type="textarea"
          placeholder=""
          :autosize="{ minRows: 6 }"
          suffix-icon="el-icon-date"
          v-model="myInfo['项目变数'].val"
        >
        </el-input>
        <span v-else class="intro-span" @click="clickSpan(myInfo['项目变数'])">{{myInfo['项目变数'].val}}</span>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
let id = 0;
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  props: {
    pageTitle: String,
  },
  data() {
    return {
      myArr: [],
      myInfo: {
        '客户特点': {
          id: id++,
          val: "客户特点输入",
          status: false,
        },
        '法律适用': {
          id: id++,
          val: "法律适用输入",
          status: false,
        },
        '客户沟通': {
          id: id++,
          val: "客户沟通输入",
          status: false,
        },
        '项目变数': {
          id: id++,
          val: "项目变数输入",
          status: false,
        },
      },
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
    },
    // 输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 
    clickSpan(info) {
      info.status = true;
      this.$nextTick(() => {
        this.$refs[`ipt${info.id}`].focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    margin-top: 28px;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #EFC102;
      margin-bottom: 20px !important;
    }
    .title-p2 {
      font-size: 20px;
      font-weight: 600;
      color: #EFC102;
      margin-bottom: 10px !important;
    }
    ::v-deep .textarea-box {
      margin-bottom: 60px;
      .el-textarea__inner {
        font-size: 24px;
        color: #111111;
      }
    }
    .intro-span {
      display: block;
      min-height: 150px;
      min-width: 400px;
      font-size: 24px;
      color: #111111;
      text-indent: 2em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      margin-bottom: 20px;
    }
  }
}
</style>