<template>
  <div class="header">
    <div class="left-icon"></div>
    <span class="header-title">{{ title }}</span>
  </div>
</template>
<script>
import { string } from "clipboard";
export default {
  components: {},
  props: {
    title: {
      type: string,
      default: "通用标题组件",
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  .left-icon {
    width: 40px;
    height: 40px;
    background: linear-gradient(to right, #e7feee,#2c95d3);
    margin-right: 40px;
  }
  .header-title {
    font-size: 32px;
    font-weight: bold;
    color: #195AAB;
  }
}
</style>