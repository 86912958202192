<template>
  <div class="header">
    <div class="left-logo">
      <img v-if="serveReportInfo._info.team.lawOfficeLogo" :src="`https://file-public.ilaas.cn/${serveReportInfo._info.team.lawOfficeLogo}`" alt="">
      <p v-else>{{serveReportInfo._info.team.lawOfficeName}}</p>
    </div>
    <div class="right-box">
      <img src="@/assets/serve-report/pages/icon/icon-2.png" alt="">
      <span>{{ serveReportInfo.companyName }}全年法律顾问</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { string } from "clipboard";
export default {
  components: {},
  props: {
    page: {
      type: string,
      default: "00",
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // position: relative;
  position: absolute;
  top: 70px;
  left: 60px;
  width: calc(100% - 60px);
  .left-logo {
    height: 100px;
    img {
      height: 100%;
    }
  }
  .right-box {
    position: relative;
    height: 40px;
    img {
      width: 582px;
    }
    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>