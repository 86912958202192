<template>
  <div class="personal-ability">
    <div v-show="!showReportPage">
    <div class="row-box">
      <span>选择数据</span>
      <div class="right-box">
        <div class="row-btns">
          <div
            :class="`btn1 ${infoType === 1 ? 'is-btn1' : ''}`"
            @click="setInfoType(1)"
          >
            <div class="dot"></div>
            <p>系统统计</p>
          </div>
          <div
            :class="`btn1 ${infoType === 2 ? 'is-btn1' : ''}`"
            @click="setInfoType(2)"
          >
            <div class="dot"></div>
            <p>个人上传</p>
          </div>
        </div>
        <infoPage1 ref="infoPage1" v-show="infoType === 1"></infoPage1>
        <infoPage2 ref="infoPage2" v-show="infoType === 2"></infoPage2>
      </div>
    </div>
    <div class="btns-box">
      <el-button @click="createReport">进入报告</el-button>
      <el-button class="btn1" @click="back">取 消</el-button>
    </div>
    </div>
    <reportPage v-if="showReportPage"></reportPage>
  </div>
</template>

<script>
import reportPage from "./reportPage.vue";
import infoPage1 from "./components/infoPage1.vue";
import infoPage2 from "./components/infoPage2.vue";
import { mapGetters } from 'vuex'
import { getUser } from "@/FackApi/api/index";
import { getAgent, getSpecialService, getYearAdvisor } from "@/FackApi/api/serveReport.js";
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';
export default {
  name: "personalAbility",
  components: {
    reportPage,
    infoPage1,
    infoPage2,
  },
  data() {
    return {
      infoType: 1, // 选择的数据方式
      showReportPage: false, // 是否显示生成报告页面
      serveReport: {}, // 报告数据集
    };
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
      userInfo: "index/userInfoState",
    }),
  },
  created() {
    this.serveReport = JSON.parse(JSON.stringify(this.serveReportInfo))
  },
  mounted() {},
  methods: {
    // 获取用户数据
    getUserInfo() {
      getUser().then((res) => {
        if (res.code === 0) {
          res.data.avatar2 = res.data.avatar;
          res.data.avatar = process.env.VUE_APP_BASE_API + res.data.avatar;
          let data = res.data;
          // vuex 存储
          this.$store.dispatch("index/setUserInfo", data);
        }
      });
    },
    // 选择的数据方式
    setInfoType(num) {
      this.infoType = num;
    },
    // 生成报告
    async createReport() {
      // console.log(this.serveReport,'this.serveReport');return
      let newObj = JSON.parse(JSON.stringify(this.serveReport));
      // 初始化用户展示数据的模块
      let newInfo = {
        '服务客户': '',
        '代理案件': '',
        '专项服务': '',
        '常年法律顾问': '',
      };
      if (this.infoType === 1) {
        // 选择系统整理的数据
        newInfo["服务客户"] = this.$refs.infoPage1.statisticalData();
        let res = await getAgent();
        if (res.code == 0) {
          newInfo["代理案件"] = res.item;
        }
        let res2 = await getSpecialService();
        if (res2.code == 0) {
          newInfo["专项服务"] = res2.item;
        }
        let res3 = await getYearAdvisor();
        if (res3.code == 0) {
          newInfo["常年法律顾问"] = res3.item;
        }
      } else {
        // 选择用户自己上传的数据
        newInfo = this.$refs.infoPage2.statisticalData();
        let isObjLen = 0;
        for (let y in newInfo) {
          if (!newInfo[y] || JSON.stringify(newInfo[y]) == 'null' || JSON.stringify(newInfo[y]) == '{}') {
            isObjLen ++
          }
        }
        if (isObjLen === 4) {
          this.$message.warning(`至少上传一组数据！`);
          return;
        }
      }
      if (newInfo["服务客户"].customerList) {
      let total = 0;
      // 对后面要显示的数据做统一处理
      // 客户数据表格展示数据
      let newClientArr = newInfo["服务客户"].customerList.filter((item) => {
        if (item.status) {
          // status为true就是用户选择的客户
          total++;
          item.no = total; // 添加序号
          item.area = `${item.province}—${item.city}`; // 拼接省市
          // item.nature = item.natureName; // 获取客户性质名称
          return item;
        }
        return item.status;
      });
      newInfo["客户表格"] = newClientArr;
      }
      if (newInfo["服务客户"].timeWithCount) {
      // 服务客户量趋势图数据
      let newClientCountArr = [];
      if (newInfo["服务客户"].timeWithCount.length === 1) {
        let info = newInfo["服务客户"].timeWithCount[0];
        info.childs.forEach((item2) => {
          newClientCountArr.push({
            time: `${info.time}-${item2.time}`,
            value: item2.value,
          });
        });
      } else if (newInfo["服务客户"].timeWithCount.length > 1) {
        newClientCountArr = newInfo["服务客户"].timeWithCount;
      }
      newInfo["客户量趋势"] = newClientCountArr;
      }
      let catalogInfo = {
        '律师个人介绍': {
          name: "1.律师个人介绍",
          page: 1,
        },
      }
      let id = 0;
      let pageList = [
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover1.png'),
        title: "首页",
        pageName: "page01",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover2.png'),
        title: "目录",
        pageName: "page02",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover3.png'),
        title: "律师个人介绍",
        pageName: "page03",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover4.png'),
        title: "服务客户信息",
        pageName: "page04",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover5.png'),
        title: "服务客户信息",
        pageName: "page05",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover6.png'),
        title: "服务客户信息",
        pageName: "page06",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover7.png'),
        title: "案件代理服务",
        pageName: "page07",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover8.png'),
        title: "案件代理服务",
        pageName: "page08",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover11.png'),
        title: "专项服务",
        pageName: "page11",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover9.png'),
        title: "常年法律顾问服务",
        pageName: "page09",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover10.png'),
        title: "常年法律顾问服务",
        pageName: "page10",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover12.png'),
        title: "客户满意度",
        pageName: "page12",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover13.png'),
        title: "",
        pageName: "page13",
      },
      ];
      // let pageList = this.serveReport._pageList;
      let newPageList = pageList.slice(0,3);
      // console.log(newPageList);return
      let comps = [ // 组件名集合
        "page01",
        "page02",
        "page03",
        "page04",
        "page05",
        "page06",
        "page07",
        "page08",
        "page11",
        "page09",
        "page10",
        "page12",
        "page13",
      ];
      let newComps = comps.slice(0,3);
      // 动态目录页码
      let page = 1;
      for (let key in newInfo) {
        let itemObj = newInfo[key];
        if (!itemObj || JSON.stringify(itemObj) === "{}") continue;
        if (key == "服务客户") {
          page ++;
          catalogInfo['服务客户'] = {
            name: `${page}.服务客户信息`,
            page: page,
            list: [
              `${page}.1 服务客户信息总表`,
              `${page}.2 服务客户数量趋势可视化`,
              `${page}.3 服务客户地域分布`,
              `${page}.4 服务客户性质结构可视化`,
              `${page}.5 服务客户类型数量可视化`,
            ]
          };
        } else if (key == "代理案件") {
          page ++;
          catalogInfo['代理案件'] = {
            name: `${page}.案件代理服务`,
            page: page,
            list: [
              `${page}.1 代理案件案由分布`,
              `${page}.2 代理案件程序分布`,
              `${page}.3 代理案件仲裁结果`,
            ]
          };
        } else if (key == "专项服务") {
          page ++;
          catalogInfo['专项服务'] = {
            name: `${page}.专项顾问服务`,
            page: page,
            list: [
              `${page}.1 专项服务总表`,
              `${page}.2 专项服务项目TOP10`,
            ]
          };
        } else if (key == "常年法律顾问") {
          page ++;
          catalogInfo['常年法律顾问'] = {
            name: `${page}.常年法律顾问服务`,
            page: page,
            list: [
              `${page}.1 常年法律顾问服务总表`,
              `${page}.2 常年顾问服务企业量结构`,
              `${page}.3 常年法律顾问企业常用权益`,
            ]
          };
        }
      }
      page ++;
      catalogInfo['客户满意度'] = {
        name: `${page}.客户满意度`,
        page: page,
            list: [
              `${page}.1 客户总体满意度评分`,
              `${page}.2 客户评语和意见`,
            ]
      };
      // 用户能够展示的页面
      newInfo['目录'] = catalogInfo;
      if (catalogInfo['服务客户']) {
        newPageList = newPageList.concat(pageList.slice(3,6));
        newComps = newComps.concat(comps.slice(3,6));
      }
      if (catalogInfo['代理案件']) {
        newPageList = newPageList.concat(pageList.slice(6,8));
        newComps = newComps.concat(comps.slice(6,8));
      }
      if (catalogInfo['专项服务']) {
        newPageList = newPageList.concat(pageList.slice(8,9));
        newComps = newComps.concat(comps.slice(8,9));
      }
      if (catalogInfo['常年法律顾问']) {
        newPageList = newPageList.concat(pageList.slice(9,11));
        newComps = newComps.concat(comps.slice(9,11));
      }
      newPageList = newPageList.concat(pageList.slice(-2));
      newComps = newComps.concat(comps.slice(-2));
      // console.log(newPageList,'111111111111111');return
      newObj._pageList = newPageList;
      newObj._comps = newComps;
      newObj._info = newInfo;
      // console.log(this.userInfo);return
      newObj.name = this.userInfo.nickName;
      this.initStorage();
      this.$store.dispatch("index/setServeReportInfo", newObj);
      this.showReport();
    },
    // 显示报告
    showReport() {
      this.showReportPage = true;
      this.$parent.showReportPage = true;
    },
    // 显示创建报告
    backAddPage() {
      this.showReportPage = false;
      this.$parent.showReportPage = false;
    },
    // 格式化内存数据
    initStorage() {
      sessionStorage.removeItem("clientSurplusInfo");
      sessionStorage.removeItem("clientPagingInfo");
      sessionStorage.removeItem("permanentSurplusInfo");
      sessionStorage.removeItem("permanentPagingInfo");
    },
    back() {
      this.$parent.back();
    },
  },
};
</script>

<style lang="less" scoped>
.personal-ability {
  width: 100%;
  // margin-left: 20px;
  .row-btns {
    display: flex;
    width: 90%;
    margin-bottom: 20px;
    .btn1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d4d4d4;
      margin-right: 20px;
      cursor: pointer;
      .dot {
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background: #ffffff;
        border: 1px solid #c0c4cc;
        margin-right: 10px;
        box-sizing: border-box;
      }
      p {
        font-size: 14px;
        color: #333;
      }
    }
    .is-btn1 {
      background: #f7fbff;
      border: 1px solid #6474c7;
      .dot {
        border: 5px solid #6474c7;
      }
      p {
        color: #6474c7;
      }
    }
  }
  .btns-box {
    margin: 60px 0 0 0;
    .el-button {
      width: 140px;
      height: 50px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border-radius: 25px;
    }
    .btn1 {
      background: #f1f2f1;
      color: #bbbbbb;
    }
  }
}
</style>

