import { render, staticRenderFns } from "./pageBlank.vue?vue&type=template&id=fe475928&scoped=true&"
import script from "./pageBlank.vue?vue&type=script&lang=js&"
export * from "./pageBlank.vue?vue&type=script&lang=js&"
import style0 from "./pageBlank.vue?vue&type=style&index=0&id=fe475928&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe475928",
  null
  
)

export default component.exports