<template>
  <div class="header">
    <div class="left-logo">
      <img v-if="serveReportInfo._info.team.lawOfficeLogo" :src="`https://file-public.ilaas.cn/${serveReportInfo._info.team.lawOfficeLogo}`" alt="">
      <p v-else>{{serveReportInfo._info.team.lawOfficeName}}</p>
    </div>
    <div class="right-box">
      <div class="text-box">
        <p>{{serveReportInfo.caseName}}</p>
        <p>代理服务报告</p>
      </div>
      <img src="@/assets/serve-report/pages/icon/icon-1.png" alt="">
      <p class="page-p">{{page}}</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { string } from "clipboard";
export default {
  components: {},
  props: {
    page: {
      type: string,
      default: "00",
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  justify-content: space-between;
  position: relative;
  width: 100%;
  .left-logo {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100px;
    height: 100px;
    img {
      // width: 100%;
      height: 100%;
      // max-height: 100px;
    }
  }
  .right-box {
    display: flex;
    align-items: center;
    .text-box {
      text-align: right;
      font-size: 16px;
      color: #909399;
      line-height: 20px;
    }
    img {
      width: 8px;
      margin: 0 12px;
    }
    .page-p {
      font-size: 20px;
      font-weight: bold;
      color: #195AAB;
    }
  }
}
</style>