<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <div class="content-box">
      <div class="name-box">
        <div class="name">
          <p>{{serveReportInfo.caseName}}</p>
          <p>代理服务报告</p>
        </div>
        <div class="name2">
          <span>报告人：{{serveReportInfo.name}}</span>
          <!-- <p>服务日期：{{reportDate}}</p> -->
          <div class="date-box">
            <span>报告日期：</span>
            <input v-if="dateStatus" id="ipt" v-model="reportDate" @blur="inputonblur2" />
            <span v-else @click="clickDate" >{{ reportDate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-box">
      <div class="left">
        <img v-if="serveReportInfo._info.team.lawOfficeLogo" :src="`https://file-public.ilaas.cn/${serveReportInfo._info.team.lawOfficeLogo}`" alt="">
        <p v-else>{{serveReportInfo._info.team.lawOfficeName}}</p>
      </div>
      <div class="right">
        <p>© 版权声明</p>
        <p>本报告样式由法法智办（上海）科技有限公司提供，报告内容属于{{serveReport.name}}（个人）和法法智办（上海）科技有限公司所有。未经{{serveReport.name}}（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合</p>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
import defaultBgImg from "@/assets/serve-report/pages/page-7.png";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
   components: {
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      headRow: [
        {name: "服务类型", span: 16},
        {name: "服务客户数", span: 8},
      ],
      myArr: [], // 客户数据
      serveReport: {}, // 律师通用数据
      reportDate: this.$getDate(6),
      yearVal: new Date().getFullYear(),
      dateStatus: false, // 日期输入框状态
      createDate: this.$getDate(6),
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    // 点击日期
    clickDate() {
      this.dateStatus = true;
      this.$nextTick(() => {
        document.getElementById(`ipt`).focus();
      })
    },
    // 日期输入框失焦
    inputonblur2() {
      this.dateStatus = false;
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted () {
    this.$nextTick(()=> {
      console.log(this.serveReportInfo);
    })
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  padding: 0;
  padding-bottom: 80px;
  .content-box {
    width: 100%;
    margin-top: 850px;
  .name-box {
    margin-top: 100px;
    text-align: center;
    .name,
    .name2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-size: 52px;
        font-weight: bold;
        color: #195AAB;
      }
    }
    .name2 {
      margin-top: 60px;
      input {
        font-size: 24px;
        color: #111111;
      }
      span {
        display: inline-block;
        min-height: 32px;
        min-width: 50px;
        font-size: 24px;
        color: #111111;
      }
    }
  }
  }
  .foot-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 80px;
    box-sizing: border-box;
    .left {
      display: flex;
      align-items: center;
      img {
        height: 100px;
      }
    }
    .right {
      width: 600px;
      p {
        font-size: 12px;
        color: #909399;
      }
    }
  }
}
</style>