<template>
  <div class="info-page1">
    <el-row class="head-row">
      <el-col
        :span="item.span"
        v-for="(item, index) in headRowList"
        :key="index"
        >{{ item.name }}</el-col
      >
    </el-row>
    <el-row class="row-box">
      <el-col :span="3">
        <p class="is-p" @click="setClient">{{statisticsInfo.customerCount}}</p>
      </el-col>
      <el-col :span="3">
        <p>{{statisticsInfo.natureCount}}</p>
      </el-col>
      <el-col :span="3">
        <p>{{statisticsInfo.regionCount}}</p>
      </el-col>
      <el-col :span="3">
        <p>{{statisticsInfo.assignmentTypeCount}}</p>
      </el-col>
      <el-col :span="4">
        <p>{{statisticsInfo.perennialLegalAdvisorYears}}</p>
      </el-col>
      <!-- <el-col :span="4">
        <p>{{statisticsInfo.agentCaseCount}}</p>
      </el-col> -->
      <el-col :span="4">
        <p>{{statisticsInfo.specialServicesCount}}</p>
      </el-col>
      <el-col :span="4">
        <p>{{statisticsInfo.agentCaseCount}}</p>
      </el-col>
    </el-row>
    <clientDialog v-if="showClientDialog" :list="myInfo.customerList" @selectClient="selectClient"></clientDialog>
  </div>
</template>

<script>
import clientDialog from "../Dialog/clientDialog.vue";
import { getPersonalStatistics, getPersonalClient } from "@/FackApi/api/serveReport.js";
export default {
  name: "infoPage1",
  components: {
    clientDialog,
  },
  data() {
    return {
      headRowList: [
        { name: "客户数量", span: 3 },
        { name: "客户性质", span: 3 },
        { name: "客户所在地区", span: 3 },
        { name: "业务类型", span: 3 },
        { name: "年度顾问服务总年", span: 4 },
        // { name: "计时顾问服务总时数", span: 4 },
        { name: "专项服务数量", span: 4 },
        { name: "代理案件数量", span: 4 },
      ],
      showClientDialog: false, // 选择客户弹框显示状态
      statisticsInfo: {}, // 统计数据
      myInfo: {}, // 客户数据集
    };
  },
  created() {
    this.getPersonalClientInfo();
    this.getStatisticsInfo();
    // this.getAgentInfo();
    // this.getSpecialServiceInfo();
    // this.getYearAdvisorInfo();
  },
  mounted() {},
  methods: {
    // 获取所有客户数据
    async getPersonalClientInfo() {
      let res = await getPersonalClient();
      // console.log(res, "客户数据");
      if (res.code == 0) {
        let data = res.item;
        data.customerList.map((item) => {
          return (item.status = true);
        });
        this.myInfo = data;
      }
    },
    // 获取统计数据
    async getStatisticsInfo() {
      let res = await getPersonalStatistics();
      // console.log(res,'统计数据');
      if (res.code == 0) {
        this.statisticsInfo = res.item;
      }
    },
    // 打开客户弹框
    setClient() {
      this.showClientDialog = true;
    },
    // 选择客户
    selectClient(arr) {
      // console.log(arr);
      let total = 0;
      arr.forEach(item => {
        if (item.status) total++;
      });
      this.statisticsInfo.customerCount = total;
      this.myInfo.customerList = arr;
    },
    // 处理统计数据
    statisticalData() {
      return this.myInfo;
    }
  },
};
</script>

<style lang="less" scoped>
.info-page1 {
  // width: 100%;
  .head-row {
    border: 1px solid #d4d4d4;
    border-bottom: 0;
    .el-col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      background: #f5f5f5;
    }
  }
  .row-box {
    border: 1px solid #d4d4d4;
    border-top: 0;
    .el-col {
      height: 50px;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      .is-p {
        color: #0094ff;
        cursor: pointer;
      }
    }
  }
}
</style>

