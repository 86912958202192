<template>
  <div class="header">
    <img src="@/assets/serve-report/pages/icon/icon-3.png" alt="">
    <p>{{ serveReportInfo.companyName }}｜专项法律服务报告</p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { string } from "clipboard";
export default {
  components: {},
  props: {
    page: {
      type: string,
      default: "00",
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
.header {
    img {
      width: 100%;
    }
    p {
      font-size: 16px;
      text-align: right;
      margin-top: 10px;
      color: #C0C4CC;
    }
}
</style>