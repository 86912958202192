<template>
  <div class="page-wrapper">
    <img class="head-img" src="@/assets/serve-report/pages/page-3.png" alt="">
    <div class="head-box">
      <p class="title">1.律师介绍</p>
      <div class="user-box">
        <div class="img-box">照片</div>
        <div class="right-box">
          <p class="name-p">{{serveReportInfo.name}}</p>
          <div class="rows-box">
            <div class="left-box">
              <div class="row">
                <span>学历：</span>
                <!-- <div class="input-box">
                  <input v-model="myInfo.education" />
                  <label>{{ myInfo.education }}</label>
                </div> -->
                <div class="input-box" v-if="myInfo['学历'].status">
                  <input v-model="myInfo['学历'].val" :ref="`ipt${myInfo['学历'].id}`" @blur="inputonblur(myInfo['学历'])" />
                </div>
                <div v-else class="name-span-box">
                  <span @click="clickSpan(myInfo['学历'])" >{{ myInfo['学历'].val }}</span>
                </div>
              </div>
              <div class="row">
                <span>执照号：</span>
                <!-- <div class="input-box">
                  <input v-model="myInfo.license" />
                  <label>{{ myInfo.license }}</label>
                </div> -->
                <div class="input-box" v-if="myInfo['执照号'].status">
                  <input v-model="myInfo['执照号'].val" :ref="`ipt${myInfo['执照号'].id}`" @blur="inputonblur(myInfo['执照号'])" />
                </div>
                <div v-else class="name-span-box">
                  <span @click="clickSpan(myInfo['执照号'])" >{{ myInfo['执照号'].val }}</span>
                </div>
              </div>
              <div class="row">
                <span>所在律所：</span>
                <!-- <div class="input-box">
                  <input v-model="myInfo.lawOffice" />
                  <label>{{ myInfo.lawOffice }}</label>
                </div> -->
                <div class="input-box" v-if="myInfo['律所'].status">
                  <input v-model="myInfo['律所'].val" :ref="`ipt${myInfo['律所'].id}`" @blur="inputonblur(myInfo['律所'])" />
                </div>
                <div v-else class="name-span-box">
                  <span @click="clickSpan(myInfo['律所'])" >{{ myInfo['律所'].val }}</span>
                </div>
              </div>
              <div class="row">
                <span>专长领域：</span>
                <!-- <div class="input-box">
                  <input v-model="myInfo.speciality" />
                  <label>{{ myInfo.speciality }}</label>
                </div> -->
                <div class="input-box" v-if="myInfo['专长领域'].status">
                  <input v-model="myInfo['专长领域'].val" :ref="`ipt${myInfo['专长领域'].id}`" @blur="inputonblur(myInfo['专长领域'])" />
                </div>
                <div v-else class="name-span-box">
                  <span @click="clickSpan(myInfo['专长领域'])" >{{ myInfo['专长领域'].val }}</span>
                </div>
              </div>
            </div>
            <div class="right-box">
              <div class="row">
                <span>所在地区：</span>
                <!-- <div class="input-box">
                  <input v-model="myInfo.area" />
                  <label>{{ myInfo.area }}</label>
                </div> -->
                <div class="input-box" v-if="myInfo['地区'].status">
                  <input v-model="myInfo['地区'].val" :ref="`ipt${myInfo['地区'].id}`" @blur="inputonblur(myInfo['地区'])" />
                </div>
                <div v-else class="name-span-box">
                  <span @click="clickSpan(myInfo['地区'])" >{{ myInfo['地区'].val }}</span>
                </div>
              </div>
              <div class="row">
                <span>电话：</span>
                <!-- <div class="input-box">
                  <input v-model="myInfo.phone" />
                  <label>{{ myInfo.phone }}</label>
                </div> -->
                <div class="input-box" v-if="myInfo['电话'].status">
                  <input v-model="myInfo['电话'].val" :ref="`ipt${myInfo['电话'].id}`" @blur="inputonblur(myInfo['电话'])" />
                </div>
                <div v-else class="name-span-box">
                  <span @click="clickSpan(myInfo['电话'])" >{{ myInfo['电话'].val }}</span>
                </div>
              </div>
              <div class="row">
                <span>邮箱：</span>
                <!-- <div class="input-box">
                  <input v-model="myInfo.mail" />
                  <label>{{ myInfo.mail }}</label>
                </div> -->
                <div class="input-box" v-if="myInfo['邮箱'].status">
                  <input v-model="myInfo['邮箱'].val" :ref="`ipt${myInfo['邮箱'].id}`" @blur="inputonblur(myInfo['邮箱'])" />
                </div>
                <div v-else class="name-span-box">
                  <span @click="clickSpan(myInfo['邮箱'])">{{ myInfo['邮箱'].val }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="text-box">
        <p>{{serveReportInfo.name}}律师：</p>
        <el-input
          v-if="myInfo['个人介绍'].status"
           :ref="`ipt${myInfo['个人介绍'].id}`"
          @blur="inputonblur(myInfo['个人介绍'])"
          class="textarea-box"
          type="textarea"
          placeholder="个人介绍"
          :autosize="{ minRows: 10 }"
          suffix-icon="el-icon-date"
          v-model="myInfo['个人介绍'].val"
        >
        </el-input>
        <span v-else @click="clickSpan(myInfo['个人介绍'])">{{myInfo['个人介绍'].val}}</span>
      </div>
      <div class="text-box">
        <p>{{myInfo['律所'].val}}：</p>
        <el-input
          v-if="myInfo['律所介绍'].status"
           :ref="`ipt${myInfo['律所介绍'].id}`"
          @blur="inputonblur(myInfo['律所介绍'])"
          class="textarea-box"
          type="textarea"
          placeholder="律所介绍"
          :autosize="{ minRows: 10 }"
          suffix-icon="el-icon-date"
          v-model="myInfo['律所介绍'].val"
        >
        </el-input>
        <span v-else @click="clickSpan(myInfo['律所介绍'])">{{myInfo['律所介绍'].val}}</span>
      </div>
    </div>
    <myFooter pageNum="02"></myFooter>
    <!-- <div class="public-foot">
      <div class="left">
        <span>{{serveReportInfo.name}}律师 | 个人服务报告</span>
      </div>
      <span>02</span>
    </div> -->
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入底部组件
import myFooter from "./components/myFooter.vue";
import { getUser } from "@/FackApi/api/index.js";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myFooter,
    VueDragResize,
  },
  data() {
    return {
        myInfo: {
          "学历": {
            id: 1,
            val: "学历",
            status: false,
          },
          "执照号": {
            id: 2,
            val: "执照号",
            status: false,
          },
          "律所": {
            id: 3,
            val: "律所",
            status: false,
          },
          "专长领域": {
            id: 4,
            val: "专长领域",
            status: false,
          },
          "地区": {
            id: 5,
            val: "地区",
            status: false,
          },
          "电话": {
            id: 6,
            val: "电话",
            status: false,
          },
          "邮箱": {
            id: 7,
            val: "邮箱",
            status: false,
          },
          "个人介绍": {
            id: 8,
            val: "个人介绍",
            status: false,
          },
          "律所介绍": {
            id: 8,
            val: "律所介绍",
            status: false,
          },
        },
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    async getUserInfo() {
      let res = await getUser();
      console.log(res,'用户数据');
      if (res.code == 0) {
        let data = res.data;
        this.myInfo = {
          "学历": {
            id: 1,
            val: "学历",
            status: false,
          },
          "执照号": {
            id: 2,
            val: "执照号",
            status: false,
          },
          "律所": {
            id: 3,
            val: data.enterprise || "律所",
            status: false,
          },
          "专长领域": {
            id: 4,
            val: "专长领域",
            status: false,
          },
          "地区": {
            id: 5,
            val: "地区",
            status: false,
          },
          "电话": {
            id: 6,
            val: data.phone || "电话",
            status: false,
          },
          "邮箱": {
            id: 7,
            val: data.email || "邮箱",
            status: false,
          },
          "个人介绍": {
            id: 8,
            val: "个人介绍",
            status: false,
          },
          "律所介绍": {
            id: 8,
            val: "律所介绍",
            status: false,
          },
        }
      }
    },
    // 输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 
    clickSpan(info) {
      info.status = true;
      this.$nextTick(() => {
        this.$refs[`ipt${info.id}`].focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  padding: 0;
  padding-bottom: 80px;
  .head-img {
    width: 100%;
    vertical-align: middle;
  }
  .head-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 80px;
    box-sizing: border-box;
    .title {
      font-family: HarmonyOS_Sans_SC_Bold;
      font-size: 32px;
      font-weight: 600;
      color: #195AAB;
    }
    .user-box {
      display: flex;
      align-items: center;
      height: 300px;
      margin-top: 70px;
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 300px;
        font-size: 30px;
        // background-color: #ccc;
        cursor:pointer;
      }
      .right-box {
        height: 100%;
        width: 70%;
        margin-left: 40px;
        .name-p {
          font-family: HarmonyOS_Sans_SC_Black;
          font-size: 40px;
          font-weight: bold;
          color: #333;
          margin-top: 20px;
        }
        .rows-box {
          display: flex;
          justify-content: space-between;
          margin-top: 50px;
          .left-box,
          .right-box {
            width: 50%;
            .row {
              display: flex;
              align-items: center;
              width: 100%;
              font-size: 16px;
              color: #909399;
              margin-bottom: 4px;
              .input-box {
                label {
                  font-size: 16px;
                }
                input {
                  font-size: 16px;
                  color: #909399;
                }
              }
              .name-span-box {
                span {
                  display: inline-block;
                  min-height: 24px;
                  min-width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
  .content-box {
    padding: 80px;
    margin-top: 200px;
    .text-box {
      min-height: 300px;
      margin-bottom: 50px;
      p {
        font-weight: bold;
        font-size: 20px;
        color: #195AAB;
      }
      .textarea-box {
        padding-left: 16px;
        font-size: 16px;
        color: #111111;
      }
      span {
        display: block;
        min-height: 200px;
        min-width: 400px;
        width: 1010px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
}
</style>