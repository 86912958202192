<template>
  <div class="page-wrapper">
    <myHeader page="04"></myHeader>
    <div class="content-box">
      <p class="title-p">5. 任务时间表</p>
      <div class="form-box">
        <el-row class="head-row" type="flex">
          <el-col
            v-for="(item, index) in headRow"
            :key="index"
            :span="item.span"
          >
            <p>{{ item.name }}</p>
          </el-col>
        </el-row>
        <div class="content-row-box" ref="rowBoxs">
          <el-row v-for="(item2, index2) in myArr" :key="index2">
            <el-col :span="2">
              <span>{{ item2["序号"].val }}</span>
            </el-col>
            <el-col :span="6">
              <el-input
                v-if="item2['名称'].status"
                :ref="`ipt${item2['名称'].id}`"
                @blur="inputonblur(item2, '名称', index2)"
                class="textarea-box"
                :id="`ipt${item2['名称'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['名称'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2, '名称', index2)">{{
                item2["名称"].val
              }}</span>
            </el-col>
            <el-col :span="8">
              <el-input
                v-if="item2['具体事项'].status"
                :ref="`ipt${item2['具体事项'].id}`"
                @blur="inputonblur(item2, '具体事项', index2)"
                class="textarea-box"
                :id="`ipt${item2['具体事项'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['具体事项'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2, '具体事项', index2)">{{
                item2["具体事项"].val
              }}</span>
            </el-col>
            <el-col :span="4">
              <el-input
                v-if="item2['执行人'].status"
                :ref="`ipt${item2['执行人'].id}`"
                @blur="inputonblur(item2, '执行人', index2)"
                class="textarea-box"
                :id="`ipt${item2['执行人'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['执行人'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2, '执行人', index2)">{{
                item2["执行人"].val
              }}</span>
            </el-col>
            <el-col :span="4">
              <el-input
                v-if="item2['工作时间'].status"
                :ref="`ipt${item2['工作时间'].id}`"
                @blur="inputonblur(item2, '工作时间', index2)"
                class="textarea-box"
                :id="`ipt${item2['工作时间'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item2['工作时间'].val"
              >
              </el-input>
              <span v-else @click="clickSpan(item2, '工作时间', index2)">{{
                item2["工作时间"].val
              }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item, index) in isLeftImgList" :key="index">
        <VueDragResize
          v-if="item && width > 0 && height > 0"
          :isActive="isActive"
          :w="width"
          :h="height"
          :aspectRatio="true"
          :sticks="['tl', 'tr', 'bl', 'br']"
          :minw="10"
          :minh="10"
          :x="left"
          :y="top"
          v-on:resizing="resize"
          v-on:dragging="resize"
          v-on:resizestop="stop"
          v-on:dragstop="stop"
        >
          <img
            :src="item"
            class="logoImg"
            crossorigin="Anonymous"
            style="width: 100%; height: 100%"
          />
        </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize";
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  props: {
    pageTitle: String,
  },
  data() {
    return {
      headRow: [
        { name: "序号", span: 2 },
        { name: "任务", span: 6 },
        { name: "具体事项", span: 8 },
        { name: "执行人", span: 4 },
        { name: "工作时间", span: 4 },
      ],
      myArr: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      if (sessionStorage.getItem("serveSurplusInfo")) {
        this.myArr = JSON.parse(sessionStorage.getItem("serveSurplusInfo")).list;
      } else if (
        sessionStorage.getItem("servePagingInfo") &&
        !sessionStorage.getItem("serveSurplusInfo")
      ) {
        let stoIsServePagingInfo = JSON.parse(
          sessionStorage.getItem("servePagingInfo")
        ).list;
        let titleIdx = this.pageTitle.split("）")[0];
        titleIdx = titleIdx.split("（");
        titleIdx = titleIdx[titleIdx.length - 1];
        if (titleIdx === "项目任务时间表") {
          this.myArr = stoIsServePagingInfo[0];
        } else {
          this.myArr = stoIsServePagingInfo[Number(titleIdx - 1)];
        }
        return;
      } else {
        // console.log(this.serveReportInfo,'---------');return
        let list = JSON.parse(JSON.stringify(this.serveReportInfo))._info
          .serviceRecords;
        // console.log(list);return
        let id = 0;
        list.map((item, index) => {
          item["序号"] = {
            id: id++,
            val: index + 1,
            status: false,
          };
          item["名称"] = {
            id: id++,
            val: item.name,
            status: false,
          };
          item["具体事项"] = {
            id: id++,
            val: item.content || "",
            status: false,
          };
          item["执行人"] = {
            id: id++,
            val: item.principal,
            status: false,
          };
          item["服务类型"] = {
            id: id++,
            val: item.assignmentName,
            status: false,
          };
          let theTime = item.duration;
          let middle = 0;
          let hour = 0;
          if (theTime > 59) {
            middle = parseInt(theTime / 60);
            theTime = parseInt(theTime % 60);
            if (middle > 59) {
              hour = parseInt(middle / 60);
              middle = parseInt(middle % 60);
            }
          }
          hour = hour < 10 ? "0" + hour : hour;
          middle = middle < 10 ? "0" + middle : middle;
          theTime = theTime < 10 ? "0" + theTime : theTime;
          item["工作时间"] = {
            id: id++,
            val: `${hour}:${middle}:${theTime}`,
            status: false,
          };
          return item;
        });
        this.myArr = list;
      }
      let totalHeight = 0; // 最大高度为1230
      let newList = this.myArr.concat([]);
      this.$nextTick(() => {
        let domList = this.$refs["rowBoxs"].children;
        let servePagingInfo = {
          list: [],
        };
        if (this.pageTitle != "项目任务时间表") {
          servePagingInfo = JSON.parse(sessionStorage.getItem("servePagingInfo"));
        }
        let _isList = [];
        let _i = "";
        for (let i = 0; i < domList.length; i++) {
          let dom = domList[i];
          totalHeight += dom.offsetHeight;
          if (totalHeight > 1230) {
            _i = i;
            this.myArr.splice(i, this.myArr.length - i + 1);
            _isList = this.myArr.concat([]);
            servePagingInfo.list.push(_isList);
            sessionStorage.setItem(
              "servePagingInfo",
              JSON.stringify(servePagingInfo)
            );
            newList.splice(0, i);
            if (newList.length !== 0) {
              let serveSurplusInfo = {
                list: newList,
              };
              sessionStorage.setItem(
                "serveSurplusInfo",
                JSON.stringify(serveSurplusInfo)
              );
              this.$emit("addPage");
            }
            break;
          }
        }
        if (_i === "") {
          servePagingInfo.list.push(this.myArr);
          sessionStorage.setItem(
            "servePagingInfo",
            JSON.stringify(servePagingInfo)
          );
          sessionStorage.removeItem("serveSurplusInfo");
          this.$emit("toOnePage");
        }
      });
    },
    // 输入框失焦
    inputonblur(row, str, idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row);
    },
    //
    clickSpan(row, str, idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row);
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`;
        document.getElementById(`${re}`).focus();
      });
    },
    // 更改背景色
    bindChangeColor(color, idx) {
      document.getElementsByClassName("page-wrapper")[
        idx
      ].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url, idx) {
      // console.log(url);
      document.getElementsByClassName("page-wrapper")[
        idx
      ].style.background = `url(${url}) center center no-repeat`;
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    margin-top: 28px;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #efc102;
      margin-bottom: 20px !important;
    }
    .form-box {
      .head-row {
        height: 56px;
        background: #eeeeee;
        .el-col {
          display: flex;
          align-items: center;
          min-height: 56px;
          padding: 10px 16px;
          box-sizing: border-box;
          p {
            font-size: 16px;
            color: #333333;
            text-align: center;
          }
        }
      }
      .content-row-box {
        .el-row {
          ::v-deep .el-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 72px;
            height: 100%;
            padding: 10px 16px;
            box-sizing: border-box;
            span {
              display: block;
              min-height: 34px;
              min-width: 100px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              word-break: break-all;
              color: #333333;
              font-size: 16px;
            }
            .textarea-box {
              .el-textarea__inner {
                background-color: transparent;
                color: #333333;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .card-box {
      display: flex;
      justify-content: space-between;
      p {
        width: 160px;
        height: 40px;
        line-break: 40px;
        font-size: 20px;
        color: #195aab;
        background: #ebf4ff;
        letter-spacing: 2px;
        padding-left: 18px;
      }
      .card {
        width: 830px;
        margin-bottom: 80px;
        ::v-deep .textarea-box {
          width: 100%;
          .el-textarea__inner {
            font-size: 24px;
            color: #111111;
          }
        }
        span {
          display: block;
          min-height: 400px;
          font-size: 24px;
          color: #111111;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-all;
        }
      }
    }
  }
}
</style>