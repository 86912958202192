<template>
  <div class="select-wrapper">
    <span class="select-wrapper-title">常规文本</span>
    <ul class="list-text">
      <li class="item" @click="clickAddTtile">
        <span>添加标题</span>
      </li>
      <li class="item" @click="clickAddSubTitle">
        <span>添加副标题</span>
      </li>
      <li class="item" @click="clickAddTextContent">
        <span>添加正文</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    clickAddTtile() {
      this.$emit("onAddTitle");
    },
    clickAddSubTitle() {
      this.$emit("onAddSubTitle");
    },
    clickAddTextContent() {
      this.$emit("onAddTextContent");
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  &-title {
    display: block;
    font-size: 12px;
    color: #909399;
    padding: 20px 0;
  }
  .list-text {
    .item {
      width: 100%;
      border: 1px #dee2e6 solid;
      margin-top: 20px;
      text-align: center;
      &:first-child {
        height: 70px;
        line-height: 70px;
        font-size: 32px;
        font-weight: bold;
        margin-top: 0;
      }
      &:nth-child(2) {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
      }
      &:last-child {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
      }
    }
  }
}
</style>