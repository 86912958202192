<template>
  <div class="page-wrapper">
    <myHeader page="02"></myHeader>
    <div class="content-box">
      <p class="title-p">1. 基本信息</p>
      <div class="basic-box">
        <div class="left">
          <input
            v-if="basicInfo['客户名称'].status"
            v-model="basicInfo['客户名称'].val"
            :id="`ipt${basicInfo['客户名称'].id}`"
            @blur="inputonblur(basicInfo['客户名称'])"
          />
          <span v-else @click="clickText(basicInfo['客户名称'])">{{ basicInfo['客户名称'].val }}</span>
          <input
            v-if="basicInfo['项目名称'].status"
            v-model="basicInfo['项目名称'].val"
            :id="`ipt${basicInfo['项目名称'].id}`"
            @blur="inputonblur(basicInfo['项目名称'])"
          />
          <span v-else @click="clickText(basicInfo['项目名称'])">{{ basicInfo['项目名称'].val }}</span>
        </div>
        <div class="center"></div>
        <div class="right">
          <input
            v-if="basicInfo['签约日期'].status"
            v-model="basicInfo['签约日期'].val"
            :id="`ipt${basicInfo['签约日期'].id}`"
            @blur="inputonblur(basicInfo['签约日期'])"
          />
          <span v-else @click="clickText(basicInfo['签约日期'])">{{ basicInfo['签约日期'].val }}</span>
          <input
            v-if="basicInfo['项目周期'].status"
            v-model="basicInfo['项目周期'].val"
            :id="`ipt${basicInfo['项目周期'].id}`"
            @blur="inputonblur(basicInfo['项目周期'])"
          />
          <span v-else @click="clickText(basicInfo['项目周期'])">{{ basicInfo['项目周期'].val }}</span>
        </div>
      </div>
      <p class="title-p">2. 项目背景</p>
      <el-input
        v-if="projectBack.status"
         :ref="`ipt${projectBack.id}`"
        @blur="inputonblur(projectBack)"
        class="textarea-box"
        :id="`ipt${projectBack.id}`"
        type="textarea"
        placeholder=""
        :autosize="{ minRows: 12 }"
        v-model="projectBack.val"
      >
      </el-input>
      <span class="rows-span" v-else @click="clickText(projectBack)">{{projectBack.val}}</span>
      <p class="title-p">3. 项目目标</p>
      <el-input
        v-if="target.status"
         :ref="`ipt${target.id}`"
        @blur="inputonblur(target)"
        class="textarea-box"
        :id="`ipt${target.id}`"
        type="textarea"
        placeholder=""
        :autosize="{ minRows: 12 }"
        v-model="target.val"
      >
      </el-input>
      <span class="rows-span" v-else @click="clickText(target)">{{target.val}}</span>
      <!-- <p class="title-p">4. 项目团队</p> -->
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
import myHeader from "./components/myHeader";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
let id = 0;
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      introduction: {
        id: 1,
        val: "前言输入",
        status: false,
      },
      basicInfo: {
        '客户名称': {
          id: id++,
          val: "",
          status: false,
        },
        '项目名称': {
          id: id++,
          val: "",
          status: false,
        },
        '签约日期': {
          id: id++,
          val: "",
          status: false,
        },
        '项目周期': {
          id: id++,
          val: "",
          status: false,
        },
      },
      projectBack: {
        id: 5,
        val: "项目背景输入",
        status: false,
      },
      target: {
        id: 6,
        val: "项目目标输入",
        status: false,
      },
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      console.log(this.serveReportInfo,'基本信息');
      this.basicInfo['客户名称'].val = this.serveReportInfo._info.customerName;
      this.basicInfo['项目名称'].val = this.serveReportInfo._info.name;
      this.basicInfo['签约日期'].val = this.serveReportInfo._info.createTime.substring(0,10);
      this.basicInfo['项目周期'].val = this.serveReportInfo._info.name;
      console.log(this.basicInfo);
    },
    // 输入框失焦
    inputonblur(info) {
      info.status = false;
    },
    // 
    clickText(info) {
      info.status = true;
      this.$nextTick(() => {
        let re = `ipt${info.id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    })
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    margin-top: 28px;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #EFC102;
      margin-bottom: 20px !important;
    }
    .basic-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px 80px;
      box-sizing: border-box;
      background: #FFFBED;
      margin-bottom: 100px;
      .left,
      .right {
        display: flex;
        flex-direction: column;
        input {
          width: 350px;
          font-size: 20px;
          color: #333333;
        }
        span {
          display: block;
          min-height: 34px;
          width: 350px;
          font-size: 20px;
          color: #333333;
        }
      }
      .center {
        width: 1px;
        height: 72px;
        border: 1px solid #EFC102;
      }
    }
    ::v-deep .textarea-box {
      .el-textarea__inner {
        font-size: 24px;
        color: #111111;
      }
    }
    .rows-span {
      display: block;
      min-width: 100px;
      min-height: 300px;
      font-size: 20px;
      color: #111111;
      text-indent: 2em !important;
      margin-bottom: 100px !important;
    }
  }
}
</style>