import { render, staticRenderFns } from "./page12.vue?vue&type=template&id=5b473b63&scoped=true&"
import script from "./page12.vue?vue&type=script&lang=js&"
export * from "./page12.vue?vue&type=script&lang=js&"
import style0 from "./page12.vue?vue&type=style&index=0&id=5b473b63&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b473b63",
  null
  
)

export default component.exports