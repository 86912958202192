<template>
  <div class="form-servereport">
    <tablePage v-if="showPage"></tablePage>
    <addPage v-else></addPage>
  </div>
</template>

<!--<template>
  <div class="developing">
    <div>
      <img src="@/assets/images/kfz.png" alt="">
      <div>
        <p>即将开放，敬请期待！</p>
      </div>
    </div>
  </div>
</template>-->

<script>
import { core } from '../../config/pluginInit'
import tablePage from './FormServereport/tablePage.vue'
import addPage from './FormServereport/addPage.vue'
export default {
  name: "FormServereport",
  components: {
    tablePage,
    addPage,
  },
  data() {
    return {
      showPage: true,
    }
  },
  created() {
  },
  mounted() {
    core.index()
  },
  methods: {
    addPeport() {
      this.showPage = false;
    },
  }
}
</script>

<style lang="less" scoped>
  .form-servereport {
    // height: 700px;
    // background-color: #fff;
    margin: 0 15px;
    margin-bottom: 30px;
    margin-top: 16px;
  }

  
  .developing {
    // width: 100%;
    // height: 700px;
    // background-color: #fff;
    margin: 0 15px;
    border-radius: 18px;
    margin-bottom: 30px;
    margin-top: 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 150px;
      img {
        width: 570px;
        margin-right: 100px;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding-top: 0;
        p {
          font-size: 44px;
          font-weight: bold;
          color: #6474C7;
        }
        span {
          font-size: 28px;
          font-weight: bold;
          color: #333;
        }
      }
    }
  }
</style>

