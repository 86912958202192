<template>
  <div
    class="page-wrapper"
    :style="{
      backgroundImage: 'url(' + pageData.bgImg + ')',
    }"
  >
    <div
      class="page-bg"
    >
      <slot></slot>
      <div class="page-title" v-if="pageData.titleStatus">
        <el-input
          placeholder="请输入标题"
          v-model="pageData.titleValue"
          autofocus
        >
          <el-button slot="append" type="primary" @click="onTitleComfirm"
            >确定</el-button
          >
          <el-button slot="append" type="warning" @click="onTitleDelete"
            >删除</el-button
          >
        </el-input>
      </div>

      <!-- <slot name="title"></slot> -->
      <div class="page-subtitle" v-if="pageData.subTitleStatus">
        <el-input
          placeholder="请输入副标题"
          v-model="pageData.subTitleValue"
          autofocus
        >
          <el-button slot="append" type="primary" @click="onSubTitleComfirm"
            >确定</el-button
          >
          <el-button slot="append" type="warning" @click="onSubTitleDelete"
            >删除</el-button
          >
        </el-input>
      </div>
      <!-- <slot name="subtitle"></slot> -->
      <div class="page-content" v-if="pageData.contentStatus">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
          placeholder="请输入内容"
          v-model="pageData.contentValue"
        >
        </el-input>
        <el-button
          type="primary"
          size="mini"
          plain
          @click="onTextContentComfirm"
          >确定</el-button
        >
        <el-button type="warning" size="mini" plain @click="onTextContentDelete"
          >删除</el-button
        >
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <!-- <img :src="item" alt=""> -->
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
// import myHeader from "@/views/Forms/FormServereport/pages/components/myHeader";
// 引入底部组件
import myFooter from "./components/myFooter.vue";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    // myHeader,
    myFooter,
    VueDragResize,
  },
  props: {
    pageStatus: {
      type: Object,
      default: () => {
        return {
          titleStatus: false,
          subTitleStatus: false,
          contentStatus: false,
          titleValue: "",
          subTitleValue: "",
          contentValue: "",
          colorBg: "",
          bgImg: "",
        };
      },
    },
  },
  data() {
    return {
      pageData: {
        titleStatus: false,
        subTitleStatus: false,
        contentStatus: false,
        titleValue: "",
        subTitleValue: "",
        contentValue: "",
        colorBg: "",
        bgImg: "",
      },
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    // 标题点击确定
    onTitleComfirm() {
      this.$emit("bindTitleConfirm", this.pageData.titleValue);
    },
    // 标题点击删除
    onTitleDelete() {
      this.$emit("bindTitleDelete");
    },
    // 子标题点击确定
    onSubTitleComfirm() {
      this.$emit("bindSubTitleConfirm", this.pageData.subTitleValue);
    },
    // 子标题点击删除
    onSubTitleDelete() {
      this.$emit("bindSubTitleDelete");
    },
    // 正文内容点击确定
    onTextContentComfirm() {
      this.$emit("bindTextContentConfirm", this.pageData.contentValue);
    },
    // 正文内容点击删除
    onTextContentDelete() {
      this.$emit("bindTextContentDelete");
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {},
  computed: {},
  watch: {
    pageStatus: {
      handler(newVal, oldVal) {
        this.pageData = newVal;
        
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  // position: relative;
  // height: 100%;
  // // background-image: url("~@/assets/serve-report/pages/page_bg@2x.png");
  // background-size: 100%;
  // background-color: #fff;
  padding: 0 80px 80px 80px;
  .page-bg {
    // padding: 40px;
    height: 100%;
  }
  .page-subtitle {
    margin-top: 30px;
  }
  .page-content {
    margin-top: 10px;
  }
  .content-title {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 800;
  }
  .content-value {
    margin: 10px 0;
    font-size: 16px;
  }
}
</style>