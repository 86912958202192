<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <div class="name-box">
      <p>法律顾问</p>
      <p>年度服务报告</p>
      <div class="name">
        <div class="input-box" v-if="caseNameStatus">
          <input
            v-model="serveReport.companyName"
            ref="caseNameIpt"
            @blur="inputonblur"
          />
        </div>
        <div v-else class="name-span-box">
          <span @click="clickCaseName">{{ serveReport.companyName }}</span>
        </div>
      </div>
      <div class="year-box">
        <input
          v-if="yearStatus"
          v-model="yearVal"
          ref="yearIpt"
          @blur="inputonblur3"
        />
        <span v-else @click="clickYear">{{ yearVal }}</span>
      </div>
    </div>
    <div class="footer">
      <div class="date-box">
        <div class="serve-date-box">
          <span>服务期限：</span>
          <input
            v-if="serveDateStatus"
            v-model="serveDate"
            ref="dateIpt"
            @blur="inputonblur4"
          />
          <span v-else @click="clickServeDate">{{ serveDate }}</span>
        </div>
        <div class="report-name-box">
          <span>报告人：</span>
          <input
            v-if="nameStatus"
            v-model="serveReport.name"
            ref="nameIpt"
            @blur="inputonblur5"
          />
          <span v-else @click="clickName">{{ serveReport.name }}</span>
        </div>
      </div>
      <div class="agreement-box">
        <p>© 版权声明</p>
        本报告样式由法法智办（上海）科技有限公司提供，报告内容属于{{serveReport.name}}（个人）和法法智办（上海）科技有限公司所有。未经{{serveReport.name}}（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item, index) in isLeftImgList" :key="index">
        <VueDragResize
          v-if="item && width > 0 && height > 0"
          :isActive="isActive"
          :w="width"
          :h="height"
          :aspectRatio="true"
          :sticks="['tl', 'tr', 'bl', 'br']"
          :minw="10"
          :minh="10"
          :x="left"
          :y="top"
          v-on:resizing="resize"
          v-on:dragging="resize"
          v-on:resizestop="stop"
          v-on:dragstop="stop"
        >
          <img
            :src="item"
            class="logoImg"
            crossorigin="Anonymous"
            style="width: 100%"
          />
        </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
import defaultBgImg from "@/assets/serve-report/pages/page-9.png";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize";
export default {
  components: {
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      copyrightVal:
        "本报告样式由法法智办（上海）科技有限公司提供，报告内容属于**（个人）和法法智办（上海）科技有限公司所有。未经**（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合",
      serveReport: {}, // 律师通用数据
      reportDate: this.$getDate(6),
      caseNameStatus: false, // 案件名称输入框状态
      nameStatus: false, // 报告人名称输入框状态
      dateStatus: false, // 日期输入框状态
      yearStatus: false, // 年份输入框状态
      yearVal: new Date().getFullYear(),
      serveDateStatus: false, // 服务期限输入框状态
      serveDate: "",
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  watch: {
    caseNameStatus() {
      if (this.caseNameStatus) {
        this.$nextTick(() => {
          this.$refs.caseNameIpt.focus();
        });
      }
    },
    nameStatus() {
      if (this.nameStatus) {
        this.$nextTick(() => {
          this.$refs.nameIpt.focus();
        });
      }
    },
    dateStatus() {
      if (this.dateStatus) {
        this.$nextTick(() => {
          this.$refs.dateIpt.focus();
        });
      }
    },
    yearStatus() {
      if (this.yearStatus) {
        this.$nextTick(() => {
          this.$refs.yearIpt.focus();
        });
      }
    },
  },
  methods: {
    // 点击案件名称文本
    clickCaseName() {
      this.caseNameStatus = true;
    },
    // 案件名称输入框失焦
    inputonblur() {
      this.caseNameStatus = false;
      let newObj = JSON.parse(JSON.stringify(this.serveReport));
      this.$store.dispatch("index/setServeReportInfo", newObj);
    },
    // 点击日期
    clickDate() {
      this.dateStatus = true;
    },
    // 日期输入框失焦
    inputonblur2() {
      console.log("日期");
      this.dateStatus = false;
    },
    // 点击报告人名称
    clickName() {
      this.nameStatus = true;
      this.$nextTick(() => {
        this.$refs.nameIpt.focus();
      });
    },
    inputonblur5() {
      this.nameStatus = false;
      let newObj = JSON.parse(JSON.stringify(this.serveReport));
      this.$store.dispatch("index/setServeReportInfo", newObj);
    },
    // 年份输入框失焦
    inputonblur3() {
      this.yearStatus = false;
    },
    // 点击年份
    clickYear() {
      this.yearStatus = true;
    },
    // 服务期限输入框失焦
    inputonblur4() {
      this.serveDateStatus = false;
    },
    clickServeDate() {
      this.serveDateStatus = true;
      this.$nextTick(() => {
        this.$refs.dateIpt.focus();
      });
    },
    // 更改背景色
    bindChangeColor(color, idx) {
      document.getElementsByClassName("page-wrapper")[
        idx
      ].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url, idx) {
      // console.log(url);
      document.getElementsByClassName("page-wrapper")[
        idx
      ].style.background = `url(${url}) center center no-repeat`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.serveReport = JSON.parse(JSON.stringify(this.serveReportInfo));
      console.log(this.serveReport);
      this.serveDate =
        this.serveReport._info["套餐"].startDate.substring(0, 10) +
        " 至 " +
        this.serveReport._info["套餐"].endDate.substring(0, 10);
    });
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  // 自适应输入框
  .name-box {
    margin: 186px 0 0 132px;
    .name {
      display: flex;
      align-items: center;
      .input-box {
        height: 40px;
        span {
          font-size: 28px;
          color: #fff;
        }
        input {
          width: 300px;
          font-size: 28px;
          // color: #fff;
        }
      }
      .name-span-box {
        max-width: 400px;
        span {
          display: block;
          min-height: 40px;
          min-width: 200px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-all;
          font-size: 28px;
          color: #fff;
        }
      }
    }
    p {
      font-size: 64px;
      font-weight: bold;
      color: #fff;
    }
    .year-box {
      margin-top: 240px;
      input {
        width: 300px;
        font-size: 64px;
        font-weight: normal;
        font-weight: bold;
        color: #97d2d8;
      }
      span {
        display: block;
        min-height: 40px;
        min-width: 200px;
        font-size: 64px;
        font-weight: normal;
        font-weight: bold;
        color: #97d2d8;
      }
    }
  }
  .footer {
    width: 100%;
    margin-top: 440px;
    .date-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: inline-block;
        min-height: 32px;
        min-width: 50px;
        font-size: 24px;
        color: #111111;
      }
      input {
        font-size: 24px;
        color: #111111;
      }
    }
    .agreement-box {
      margin-top: 80px;
      p {
        margin-bottom: 30px !important;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
  .let-img-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
  }
}
</style>