<template>
  <div class="page-wrapper">
    <myHeader page="04"></myHeader>
    <div class="content-box">
      <p class="title-p">
        4. 裁判结果
      </p>
      <div class="card-box" v-for="(item,index) in myArr" :key="index">
        <p>4.{{index+1}}.{{item.name}}</p>
        <div class="card">
          <el-input
            v-if="item['内容'].status"
            :ref="`ipt${item['内容'].id}`"
            @blur="inputonblur(item,'内容',index)"
            class="textarea-box"
            :id="`ipt${item['内容'].id}`"
            type="textarea"
            placeholder=""
            :autosize="{ minRows: 8 }"
            suffix-icon="el-icon-date"
            v-model="item['内容'].val"
          >
          </el-input>
          <span v-else @click="clickSpan(item,'内容',index)">{{
            item['内容'].val
          }}</span>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      myArr: [],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    init() {
      let list = JSON.parse(JSON.stringify(this.serveReportInfo))._info.results;
      list.map((item,index) => {
        return item['内容'] = {
          id: index,
          val: item.content,
          status: false,
        }
      })
      this.myArr = list
      console.log(this.myArr,'裁判结果');
    },
    // 输入框失焦
    inputonblur(row,str,idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row)
    },
    // 
    clickSpan(row,str,idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row)
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`
        document.getElementById(`${re}`).focus();
      })
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  .content-box {
    margin-top: 100px;
    .title-p {
      font-size: 32px;
      font-weight: 600;
      color: #195AAB;
      margin-bottom: 20px !important;
    }
    .card-box {
      display: flex;
      justify-content: space-between;
      p {
        width: 160px;
        height: 40px;
        line-break: 40px;
        font-size: 20px;
        color: #195AAB;
        background: #EBF4FF;
        letter-spacing: 2px;
        padding-left: 18px;
      }
      .card {
        width: 830px;
        margin-bottom: 80px;
        ::v-deep .textarea-box {
          width: 100%;
      .el-textarea__inner {
        font-size: 24px;
        color: #111111;
      }
    }
    span {
      display: block;
      min-height: 400px;
      min-width: 300px;
      font-size: 24px;
      color: #111111;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
    }
      }
    }
  }
}
</style>