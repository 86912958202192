<template>
  <div class="info-page2">
    <div class="module-box">
      <p>客户数据：</p>
      <el-button @click="downloadClient">客户列表导出</el-button>
      <el-upload
        ref="uploadplan"
        class="upload-demo"
        action=""
        accept=".xlsx, .xls"
        :on-remove="clientRemove"
        :http-request="uploadClient"
        :limit="1"
        :file-list="clientFileList"
      >
        <el-button type="primary" size="small">
          上传
        </el-button>
      </el-upload>
    </div>
    <div class="module-box">
      <p>案件代理数据：</p>
      <el-button @click="downloadAgent">案件代理列表导出</el-button>
      <el-upload
        ref="uploadplan"
        class="upload-demo"
        action=""
        accept=".xlsx, .xls"
        :on-remove="agentRemove"
        :http-request="uploadAgent"
        :limit="1"
        :file-list="agentFileList"
      >
        <el-button type="primary" size="small">
          上传
        </el-button>
      </el-upload>
    </div>
    <div class="module-box">
      <p>专项案件数据：</p>
      <el-button @click="downloadAdept">专项案件列表导出</el-button>
      <el-upload
        ref="uploadplan"
        class="upload-demo"
        action=""
        accept=".xlsx, .xls"
        :on-remove="adeptRemove"
        :http-request="uploadAdept"
        :limit="1"
        :file-list="adeptFileList"
      >
        <el-button type="primary" size="small">
          上传
        </el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import { exportClient, exportAgent, exportAdept, upClient, upAgent, upAdept } from "@/FackApi/api/serveReport.js";
export default {
  name: "infoPage2",
  components: {
  },
  data() {
    return {
      clientFileList: [],//上传的客户文件列表
      // clientLiat: [{name: '', url: ''}],
      agentFileList: [],//上传的案件代理文件列表
      adeptFileList: [],//上传的专项案件文件列表
      clientInfo: {}, // 返回的客户数据
      agentInfo: {}, // 返回的案件代理数据
      adeptInfo: {}, // 返回的专项案件数据
      myInfo: {}, // 客户数据集
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    // 上传客户列表
    async uploadClient(param) {
      let formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("file", param.file); //添加文件对象
      let res = await upClient(formData);
      if (res.code == 0) {
      // console.log(res,'上传后返回客户的数据');
        let data = res.item;
        data.customerList.map(item => {
          return item.status = true;
        })
        this.clientInfo = data;
      }
    },
    clientRemove(file, fileList) {
      this.clientInfo = {};
      this.$message.warning("文件已移除");
    },
    // 上传案件代理列表
    async uploadAgent(param) {
      let formData = new FormData();
      formData.append("file", param.file);
      let res = await upAgent(formData);
      console.log(res,'上传案件代理返回的数据');
      if (res.code == 0) {
        this.agentInfo = res.item;
      }
    },
    agentRemove(file, fileList) {
      this.agentInfo = {};
      this.$message.warning("文件已移除");
    },
    // 上传专项案件列表
    async uploadAdept(param) {
      let formData = new FormData();
      formData.append("file", param.file);
      let res = await upAdept(formData);
      console.log(res,'上传专项案件返回的数据');
      if (res.code == 0) {
        this.adeptInfo = res.item;
      }
    },
    adeptRemove(file, fileList) {
      this.adeptInfo = {};
      this.$message.warning("文件已移除");
    },
    // 下载客户列表
    async downloadClient() {
      let loadingInstance = Loading.service({ fullscreen: true })
      let res = await exportClient();
      // console.log(res,"下载客户列表");
      loadingInstance.close();
      let data = res;
      const fileName = `客户列表.xlsx`;
      const blob =
        data instanceof Blob
          ? data
          : new Blob([data], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            });
      if ('download' in document.createElement('a')) {
        // 非IE下载 
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    // 下载案件代理列表
    async downloadAgent() {
      let loadingInstance = Loading.service({ fullscreen: true })
      let res = await exportAgent();
      // console.log(res,"案件代理列表");
      loadingInstance.close();
      let data = res;
      const fileName = `案件代理.xlsx`;
      const blob =
        data instanceof Blob
          ? data
          : new Blob([data], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            });
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    // 下载专项案件列表
    async downloadAdept() {
      let loadingInstance = Loading.service({ fullscreen: true })
      let res = await exportAdept();
      // console.log(res,"下载专项案件列表");
      loadingInstance.close();
      let data = res;
      const fileName = `专项案件.xlsx`;
      const blob =
        data instanceof Blob
          ? data
          : new Blob([data], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            });
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    // 处理统计数据
    statisticalData() {
      let newObj = {
        "服务客户": this.clientInfo,
        "代理案件": this.agentInfo,
        "专项服务": this.adeptInfo,
        "常年法律顾问": {},
      }
      // console.log(this.clientInfo);
      let newArr = JSON.parse(JSON.stringify(this.clientInfo)).customerList || [];
      // console.log(newArr,'newArr');
      if (newArr.length != 0) {
        let customerList = [];
        let natureWithCount = {};
        newArr.forEach(item => {
          if (item.businessType == "常年法律顾问") {
            customerList.push(item);
            let k = this.$getNature(item.natureName);
            if (natureWithCount.hasOwnProperty(k)) {
              natureWithCount[k] ++;
            } else {
              natureWithCount[k] = 1;
            }
          }
        });
        newObj['常年法律顾问'] = {
          customerList: customerList,
          natureWithCount: natureWithCount,
          assignmentTypeWithCount: {
            '上门法律服务': null,
            '人事文件制度审核修订': null,
            '会员专属沙龙、论坛活动': null,
            '其他服务': null,
            '出具法律意见书或律师函': null,
            '劳动法律法规政策咨询': null,
            '季度服务报告': null,
            '月度会员电子月刊': null,
            '月度当地劳动法规速递': null,
            '精品公开课': null,
          },
        }
      }
      // this.myInfo = Object.assign(this.clientInfo, newObj);
      return newObj;
    }
  },
};
</script>

<style lang="less" scoped>
.info-page2 {
  .module-box {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #e9f4fe;
    margin-bottom: 30px;
    p {
      color: #333;
    }
    .el-button {
      margin-top: 10px;
    }
    /deep/ .upload-demo {
      width: 16%;
      .el-upload {
        margin-top: 10px;
      }
    }
  }
}
</style>

