<template>
  <div class="page-wrapper">
    <myHeader page="06"></myHeader>
    <div class="content-box">
      <p class="title-p">下一年度改善和行动建议</p>
      <p class="min-title-p">
        按前述贵司咨询中所反映的问题和存在的风险，以双方进一步加强年度顾问合作关系的基础上，建议公司在以下方面可按优先级选择采取改善行动方案，以进一步提升贵司的管理效率和防范风险：
      </p>
      <div class="card-boxs">
        <div :class="`card ${item.status ? 'is-card':''}`" v-for="(item, index) in myArr" :key="item.id" @click="setRow(item)">
          <div class="select-box">
            <i v-if="item.status" class="el-icon-circle-check"></i>
          </div>
          <div class="val-box">
            <div class="title">
              <input
                v-if="item['标题'].status"
                v-model="item['标题'].val"
                :id="`ipt${item['标题'].id}`"
                :ref="`ipt${item['标题'].id}`"
                @blur="inputonblur(item, '标题', index)"
              />
              <span v-else @click.stop="clickSpan(item, '标题', index)">{{
                item["标题"].val
              }}</span>
            </div>
            <div class="val">
              <el-input
                v-if="item['内容'].status"
                :ref="`ipt${item['内容'].id}`"
                @blur="inputonblur(item, '内容', index)"
                class="textarea-box"
                :id="`ipt${item['内容'].id}`"
                type="textarea"
                placeholder=""
                :autosize="{ minRows: 1 }"
                suffix-icon="el-icon-date"
                v-model="item['内容'].val"
              >
              </el-input>
              <span v-else @click.stop="clickSpan(item, '内容', index)">{{
                item["内容"].val
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item, index) in isLeftImgList" :key="index">
        <VueDragResize
          v-if="item && width > 0 && height > 0"
          :isActive="isActive"
          :w="width"
          :h="height"
          :aspectRatio="true"
          :sticks="['tl', 'tr', 'bl', 'br']"
          :minw="10"
          :minh="10"
          :x="left"
          :y="top"
          v-on:resizing="resize"
          v-on:dragging="resize"
          v-on:resizestop="stop"
          v-on:dragstop="stop"
        >
          <img
            :src="item"
            class="logoImg"
            crossorigin="Anonymous"
            style="width: 100%; height: 100%"
          />
        </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入头部组件
import myHeader from "./components/myHeader";
import { mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize";
let id = 0;
export default {
  components: {
    myHeader,
    VueDragResize,
  },
  data() {
    return {
      myArr: [
        {
          '标题': {
            id: id++,
            val: "年度人力资源顾问服务",
            status: false,
          },
          '内容': {
            id: id++,
            val: "通过上一年度的合作，双方建立了良好的信任关系，在下一年度的合作中，基于对贵司的行业及管理模式和问题点的了解，将更加发挥我方对贵司的咨询深度和取得更好的咨询服务效果。",
            status: false,
          },
          status: true,
        },
        {
          '标题': {
            id: id++,
            val: "人力资源体系建设与强化",
            status: false,
          },
          '内容': {
            id: id++,
            val: "适合于发展中的企业，实现企业人事管理到人力资源管理的转变，设计企业人力资源体系各模块的完整架构，从战略、组织、人员到各项流程，从开始全面一体化为帮助企业建立专业规范的人力资源体系。",
            status: false,
          },
          status: true,
        },
        {
          '标题': {
            id: id++,
            val: "专项方案设计与落地实施",
            status: false,
          },
          '内容': {
            id: id++,
            val: "针对企业人力资源某一模块或者功能的缺失进行咨询设计，也可以是针对突发性问题的设计解决方案，包括：转停并转中的人员安置方案、裁员方案、工会职代会设计方案、民主流程实施方案、集体争议处理方案等。",
            status: false,
          },
          status: true,
        },
        {
          '标题': {
            id: id++,
            val: "人力资源制度审核与修订",
            status: false,
          },
          '内容': {
            id: id++,
            val: "对人力资源制度中与劳动法规关联性强的规章制度，包括员工手册、劳动合同类和其他各项人事规章制度，按照国家和企业当地法规的要求进行合理性和合法性的审核修订，确保规章制度符合企业管理需要和法规的要求。",
            status: false,
          },
          status: true,
        },
        {
          '标题': {
            id: id++,
            val: "人力资源合规合规审计",
            status: false,
          },
          '内容': {
            id: id++,
            val: "按劳动法要求和企业人力资源合规指引，对企业的人力资源管理进行全面的审核和检查，排查各类风险点等，出具合规审核报告并出具改善建议和行动方案，确保企业的人力资源管理达到合规化要求。",
            status: false,
          },
          status: true,
        },
        {
          '标题': {
            id: id++,
            val: "人力资源合规体系设计与实施",
            status: false,
          },
          '内容': {
            id: id++,
            val: "按合规审计报告以及企业战略发展要求，整体设计企业的人力资源合规体系加构，功能模块，建立、完善和改善，建立企业的人力资源合规体系。",
            status: false,
          },
          status: true,
        },
      ],
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
      showAdd: false, // 新增显示
      maxId: 6, // 最大id
      delId: 0, // 删除的id
    };
  },
  methods: {
    // 输入框失焦
    inputonblur(row, str, idx) {
      row[str].status = false;
      this.$set(this.myArr, idx, row);
    },
    //
    clickSpan(row, str, idx) {
      row[str].status = true;
      this.$set(this.myArr, idx, row);
      this.$nextTick(() => {
        let re = `ipt${row[str].id}`;
        document.getElementById(`${re}`).focus();
      });
    },
    // 选择
    setRow(info) {
      info.status = !info.status;
    },
    // 更改背景色
    bindChangeColor(color, idx) {
      document.getElementsByClassName("page-wrapper")[
        idx
      ].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url, idx) {
      // console.log(url);
      document.getElementsByClassName("page-wrapper")[
        idx
      ].style.background = `url(${url}) center center no-repeat`;
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      serveReportInfo: "index/serveReportInfoState",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  .content-box {
    margin-top: 200px;
    .title-p {
      font-size: 56px;
      font-weight: 600;
      color: #196a73;
      margin-bottom: 16px !important;
    }
    .min-title-p {
      font-size: 20px;
      color: #111111;
      margin-bottom: 40px !important;
    }
    .card-boxs {
      .card {
        display: flex;
        flex-direction: initial;
        padding: 24px;
        box-sizing: border-box;
        margin-bottom: 24px;
        border-radius: 30px;
        cursor:pointer;
        .select-box {
          display: flex;
          align-items: center;
          width: 5%;
          i {
            font-size: 40px;
            color: #5eab0b;
          }
        }
        .val-box {
          width: 95%;
          .title {
            input {
              width: 800px;
              font-size: 20px;
              font-weight: bold;
              color: #111111;
            }
            span {
              display: block;
              min-height: 32px;
              min-width: 200px;
              font-size: 20px;
              font-weight: bold;
              color: #111111;
            }
          }
          .val {
            ::v-deep .textarea-box {
              .el-textarea__inner {
              font-size: 16px;
              color: #909399;
              }
            }
            span {
              display: block;
              min-height: 32px;
              min-width: 200px;
              font-size: 16px;
              color: #909399;
            }
          }
        }
      }
      .is-card {
        border: 1px solid #5eab0b;
      }
    }
  }
}
</style>