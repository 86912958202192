<template>
  <div class="report-page">
    <!-- <div class="head-box">智能工具 > 服务报告</div> -->
    <div class="top-btn">
      <span>案件代理能力服务报告</span>
      <div>
      <el-button
        type="primary"
        plain
        size="mini"
        class="edit"
        @click="handleMode"
        >{{ currentComponent === "preview" ? "编辑" : "保存" }}</el-button
      >
      <el-button v-if="fileUrl" type="primary" size="small" @click="exportReport">下载报告</el-button>
      <el-button type="primary" plain size="mini" @click="handleExit"
        >退出</el-button
      >
      </div>
    </div>
    <!-- <keep-alive> -->
      <component
        :is="currentComponent"
        :list="list"
        ref="contentmain"
      ></component>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
// 导入预览页面组件
import edit from "@/views/Forms/FormServereport/caseAgent/edit";
// 导入编辑页面组件
import preview from "@/views/Forms/FormServereport/caseAgent/preview";
import { mapGetters } from 'vuex'
import { generateReport } from "@/FackApi/api/serveReport.js";
import { getFileUrl } from '@/FackApi/api/index.js'
let id = 0;
export default {
  name: "reportPage",
  components: {
    edit,
    preview,
  },
  props: {
  },
  data() {
    return {
      list: [],
      currentComponent: "preview",
      fileUrl: '', //
      loading: null,
    };
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
  created() {
    // console.log(this.serveReportInfo,'this.serveReportInfo');
    this.list = JSON.parse(JSON.stringify(this.serveReportInfo))._pageList;
    sessionStorage.removeItem("fileUrl");
  },
  mounted() {},
  methods: {
    async handleMode() {
      let type = this.currentComponent == "preview" ? 0 : 1;
      let that = this;
      if (!type) {
        this.loading = this.$loading({
          lock: true,
          text: "加载数据中~",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let that = this
        setTimeout(() => {
          that.currentComponent = "edit";
          this.closeLoading();
        },300)
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "正在努力保存中~",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$refs.contentmain.transform.scale = 1;
        setTimeout(async()=> {
        let data = await that.$refs.contentmain.handleEditSave();
        // console.log(data);
        let params = {
          type: 3,
          files: data,
        }
        let res = await generateReport(params);
        // console.log(res,'res');
        if (res.code == 0) {
          let fileUrl = res.content;
          this.fileUrl = fileUrl;
          sessionStorage.setItem("fileUrl",fileUrl);
          this.loading.close();
          that.$message({
            type: "success",
            message: "保存完成了~",
          });
          // Object.assign(that._data, that.$options.data());
          that.currentComponent = "preview";
        } else {
          this.loading.close();
        }
        },300)
      }
      // console.log(that.fileUrl);
    },
    closeLoading() {
      this.loading.close();
    },
    // 下载
    async exportReport() {
      let fileUrl = sessionStorage.getItem("fileUrl");
      // console.log(fileUrl,'fileUrl');
      if (!fileUrl) return
      let params = {
        expireTime: 3600,
        objectName: fileUrl,
        // BucketName: 'ilaas-files',
        // EndPoint: 'https://oss-cn-shanghai.aliyuncs.com',
      };
      let ress = await getFileUrl(params);
      fetch(ress).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        document.body.appendChild(a)
        a.style.display = 'none'
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // 指定下载的文件名
        a.download = `案件代理服务报告.pdf`;
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    },
    // 退出
    handleExit() {
      if (this.currentComponent == "edit") {
        this.currentComponent = "preview";
      } else {
        // this.$parent.showReportPage = false;
        this.$parent.backAddPage();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.report-page {
  background-color: #fff;
  // padding: 20px;
  border-radius: 20px;
  .top-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    span {
      font-size: 14px;
      color: #0069a6;
    }
  }
}
</style>
 
