<template>
  <div class="client-dialog">
    <el-dialog
      title="选择客户"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="460px"
    >
      <div class="content-box">
        <el-row class="head-row">
          <el-col :span="12">客户名称</el-col>
          <el-col :span="12">选择</el-col>
        </el-row>
        <div class="row-box">
          <el-row v-for="item in myList" :key="item.id">
            <el-col :span="12">{{item.name}}</el-col>
            <el-col :span="12">
              <!-- <el-checkbox v-model="item.status"></el-checkbox> -->
              <div :class="`dot ${item.status ? 'is-dot':''}`" @click="item.status = !item.status"></div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="conserve">确 定</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "clientDialog",
  components: {},
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {
      dialogVisible: true,
      myList: null,
    };
  },
  created() {
    this.myList = this.list;
  },
  mounted() {},
  methods: {
    cancel() {
      this.$parent.showClientDialog = false;
    },
    // 确定
    conserve() {
      this.$emit("selectClient",this.myList);
      this.cancel();
    },
  },
};
</script>

<style lang="less" scoped>
.client-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .content-box {
          .head-row {
            border: 1px solid #d4d4d4;
            border-bottom: 0;
            .el-col {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 50px;
              background: #f5f5f5;
            }
          }
          .row-box {
            max-height: 600px;
            overflow-y: auto;
            border: 1px solid #d4d4d4;
            border-top: 0;
            .el-col {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 50px;
              padding: 10px;
              .dot {
                width: 16px;
                height: 16px;
                border-radius: 8px;
                background: #FFFFFF;
                border: 1px solid #C0C4CC;
                margin-right: 10px;
                box-sizing: border-box;
                cursor:pointer;
              }
              .is-dot {
                // border: 5px solid #3299d3;
                background: #3299d3;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
