<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <div class="content-box">
      <div class="name-box">
        <div class="name">
          <!-- <div class="input-box" v-if="nameStatus">
            <input v-model="serveReport.name" ref="nameIpt" @blur="inputonblur" />
          </div> -->
          <div class="name-span-box">
            <span >{{ serveReportInfo.name }}</span>
          </div>
          <p>律师</p>
          <span>｜个人能力服务报告</span>
        </div>
        <p>Personal Service Report</p>
      </div>
      <p class="report-name">报告人：{{ serveReportInfo.name }}</p>
      <div class="row-box">
          <span class="label">报告日期：</span>
          <div class="input-box" v-if="dateStatus">
            <input id="ipt" v-model="reportDate" @blur="inputonblur2" />
          </div>
          <div v-else class="date-span-box">
            <span @click="clickDate" >{{ reportDate }}</span>
          </div>
        </div>
    </div>
    <div class="foot-box">
      
        <span>
          本报告样式由法法智办（上海）科技有限公司提供，报告内容属于{{serveReport.name}}（个人）和法法智办（上海）科技有限公司所有。未经{{serveReport.name}}（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合
        </span>
      <!-- <p>本报告生成时间为：</p>
      <p>{{createDate}}，您所看的内容为截止时间点的数据快照</p><br/>
      <p> © 版权声明 </p>
      <p>本报告样式由法法智办（上海）科技有限公司提供，报告内容属于{{serveReport.name}}（个人）和法法智办（上海）科技有限公司所有。未经{{serveReport.name}}（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合</p><br/>
      <p>出具单位：法法智伴上海科技有限公司</p> -->
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;height: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
import defaultBgImg from "@/assets/serve-report/pages/page-4.png";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
   components: {
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      headRow: [
        {name: "服务类型", span: 16},
        {name: "服务客户数", span: 8},
      ],
      myArr: [], // 客户数据
      serveReport: {}, // 律师通用数据
      reportDate: this.$getDate(6),
      yearVal: new Date().getFullYear(),
      dateStatus: false, // 日期输入框状态
      createDate: this.$getDate(6),
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  methods: {
    // 点击日期
    clickDate() {
      this.dateStatus = true;
      this.$nextTick(() => {
        document.getElementById(`ipt`).focus();
      })
    },
    // 日期输入框失焦
    inputonblur2() {
      this.dateStatus = false;
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted () {

  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  position: relative;
  padding: 0;
  padding-bottom: 80px;
  .content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  // 自适应输入框
  .name-box {
    margin-top: 100px;
    text-align: center;
    .name {
      display: flex;
      align-items: center;
      justify-content: center;
      .name-span-box {
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-all;
          font-size: 64px;
          font-weight: bold;
          color: #fff;
        }
      }
      p {
        font-size: 64px;
        font-weight: bold;
        color: #fff;
      }
      span {
        font-size: 64px;
        color: #fff;
        white-space: nowrap;
      }
    }
    p {
      font-size: 32px;
      color: #fff;
    }
  }
  .report-name {
    font-size: 24px;
    font-weight: bold;
color: #FFFFFF;
margin-bottom: 16px;
margin-top: 20px;
  }
    .row-box {
        display: flex;
        .label {
          font-size: 24px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .input-box {
          label {
            font-size: 24px;
            font-weight: bold;
          }
          input {
            font-size: 24px;
            color: #333;
          }
        }
      .date-span-box {
        span {
          display: inline-block;
          min-height: 24px;
          min-width: 100px;
          font-size: 24px;
            font-weight: bold;
            color: #FFFFFF;
        }
      }
      }
  }
  .foot-box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 80px;
    box-sizing: border-box;
  }
}
</style>