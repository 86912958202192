<template>
  <div class="page-wrapper" :style="{ backgroundImage: 'url(' + imgSrc + ')' }">
    <div class="name-box">
      <div class="name">
        <div class="input-box" v-if="caseNameStatus">
          <input v-model="serveReport.caseName" ref="caseNameIpt" @blur="inputonblur" />
        </div>
        <div v-else class="name-span-box">
          <span @click="clickCaseName" >{{ serveReport.caseName }}</span>
        </div>
      </div>
      <p>代理服务报告</p>
    </div>
    <div class="footer">
      <div class="date-box">
        <div class="row-box">
          <span class="label">报告人：</span>
          <div class="input-box" v-if="nameStatus">
            <input v-model="serveReport.name" ref="nameIpt" @blur="inputonblur3" />
          </div>
          <div v-else class="date-span-box">
            <span @click="clickName" >{{ serveReport.name }}</span>
          </div>
        </div>
        <div class="row-box">
          <span class="label">服务日期：</span>
          <div class="input-box" v-if="dateStatus">
            <input v-model="reportDate" ref="dateIpt" @blur="inputonblur2" />
          </div>
          <div v-else class="date-span-box">
            <span @click="clickDate" >{{ reportDate }}</span>
          </div>
        </div>
      </div>
      <div class="agreement-box">
        <p>© 版权声明</p>
        <p>本报告样式由法法智办（上海）科技有限公司提供，报告内容属于{{serveReport.name}}（个人）和法法智办（上海）科技有限公司所有。未经{{serveReport.name}}（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合
        </p>
      </div>
    </div>
    <div class="let-img-box" v-if="isLeftImgList.length > 0">
      <div class="img-card" v-for="(item,index) in isLeftImgList" :key="index">
        <VueDragResize
            v-if="item && width>0 && height>0"
            :isActive="isActive"
            :w="width"
            :h="height"
            :aspectRatio="true"
            :sticks="['tl','tr','bl','br']"
            :minw="10"
            :minh="10"
            :x="left"
            :y="top"
            v-on:resizing="resize"
            v-on:dragging="resize"
            v-on:resizestop="stop"
            v-on:dragstop="stop"
          >
            <img :src="item" class="logoImg" crossorigin="Anonymous" style="width: 100%;">
          </VueDragResize>
      </div>
    </div>
  </div>
</template>
<script>
// 引入背景图片路径
// import defaultBgImg from "@/assets/serve-report/pages/page_bg@2x.png";
import defaultBgImg from "@/assets/serve-report/pages/page-5.png";
import { mapGetters } from 'vuex'
import VueDragResize from "vue-drag-resize"
export default {
  components: {
    VueDragResize,
  },
  data() {
    return {
      imgSrc: defaultBgImg,
      copyrightVal:
        "本报告样式由法法智办（上海）科技有限公司提供，报告内容属于**（个人）和法法智办（上海）科技有限公司所有。未经**（个人）和法法智办（上海）科技有限公司书面许可，任何其他个人或组织均不得以任何形式将本报告的全部内容转载、复制、编辑或发布用于其他任何和场合",
      serveReport: {}, // 律师通用数据
      reportDate: this.$getDate(6),
      caseNameStatus: false, // 案件名称输入框状态
      nameStatus: false, // 报告人名称输入框状态
      dateStatus: false, // 日期输入框状态
      yearStatus: false, // 年份输入框状态
      isLeftImgList: [],
      width: 200,
      height: 200,
      left: 200,
      top: 200,
      resize: 2,
      stop: 5,
      isActive: false,
    };
  },
  watch: {
    caseNameStatus() {
      if (this.caseNameStatus) {
        this.$nextTick(() => {
          this.$refs.caseNameIpt.focus();
        })
      }
    },
    nameStatus() {
      if (this.nameStatus) {
        this.$nextTick(() => {
          this.$refs.nameIpt.focus();
        })
      }
    },
    dateStatus() {
      if (this.dateStatus) {
        this.$nextTick(() => {
          this.$refs.dateIpt.focus();
        })
      }
    },
    yearStatus() {
      if (this.yearStatus) {
        this.$nextTick(() => {
          this.$refs.yearIpt.focus();
        })
      }
    },
  },
  methods: {
    // 点击案件名称文本
    clickCaseName() {
      this.caseNameStatus = true;
    },
    // 案件名称输入框失焦
    inputonblur() {
      this.caseNameStatus = false;
      let newObj = JSON.parse(JSON.stringify(this.serveReport))
      this.$store.dispatch("index/setServeReportInfo", newObj);
    },
    // 点击日期
    clickDate() {
      this.dateStatus = true;
    },
    // 日期输入框失焦
    inputonblur2() {
      // console.log('日期');
      this.dateStatus = false;
    },
    // 点击报告人名称
    clickName() {
      this.nameStatus = true;
    },
    inputonblur3() {
      this.nameStatus = false;
      let newObj = JSON.parse(JSON.stringify(this.serveReport))
      this.$store.dispatch("index/setServeReportInfo", newObj);
    },
    // 更改背景色
    bindChangeColor(color,idx) {
      document.getElementsByClassName('page-wrapper')[idx].style.backgroundColor = color;
    },
    // 选择图片
    bindImg(item) {
      // console.log(item);
      this.isLeftImgList.push(item);
    },
    // 更改背景图片
    bindChangeBgimg(url,idx) {
      // console.log(url);
      document.getElementsByClassName('page-wrapper')[idx].style.background = `url(${url}) center center no-repeat`;
    }
  },
  mounted() {
    this.$nextTick(()=> {
      this.serveReport = JSON.parse(JSON.stringify(this.serveReportInfo));
    })
  },
  computed: {
    ...mapGetters({
      serveReportInfo: 'index/serveReportInfoState',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.page-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  // 自适应输入框
  .name-box {
    margin: 140px 0 0 70px;
    .name {
      display: flex;
      align-items: center;
      .input-box {
        height: 88px;
        span {
          font-size: 64px;
          font-weight: bold;
          color: #0069a6;
        }
        input {
          width: 500px;
          font-size: 64px;
          font-weight: bold;
          color: #0069a6;
        }
      }
      .name-span-box {
        max-width: 90%;
        span {
          display: block;
          min-height: 50px;
          min-width: 200px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-all;
          font-size: 64px;
          font-weight: bold;
          color: #fff;
        }
      }
      span {
        font-size: 64px;
        color: #0069a6;
        white-space: nowrap;
      }
    }
    p {
      font-size: 64px;
      font-weight: bold;
      color: #fff;
    }
  }
  .footer {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // position: absolute;
    // left: 0;
    // bottom: 80px;
    width: 100%;
    // padding: 0 80px;
    margin-top: 600px;
    .date-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 180px;
      border-left: 8px solid #195AAB;
      padding-left: 18px;
      .row-box {
        .label {
          font-size: 24px;
          color: #111;
        }
        .input-box {
          label {
            font-size: 24px;
          }
          input {
            font-size: 24px;
            color: #111;
          }
        }
      .date-span-box {
        span {
          display: block;
          min-height: 34px;
          min-width: 200px;
          font-size: 24px;
            color: #111;
        }
      }
      }
    }
    .agreement-box {
      width: 510px;
      margin-top: 110px;
      p {
        margin-bottom: 30px !important;
      }
      span {
        width: 510px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
  .let-img-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
  }
}
</style>